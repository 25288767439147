import { Select } from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import { useGetForecasterIdsQuery } from "../../generated/graphql";
import styles from "./styles.module.css";

export const ForecasterSelect = (props: {
  onChange: (selectedForecaster: string) => void;
  selectedForecaster: string;
  operationalWeek: string;
}) => {
  const { data } = useGetForecasterIdsQuery({
    variables: { operationalWeekStartDate: props.operationalWeek },
  });

  const forecasterIds = data?.forecasters
    ? data.forecasters.reduce<number[]>((accum, forecaster) => {
        if (forecaster?.id) {
          accum.push(forecaster?.id);
        }
        return accum;
      }, [])
    : [];

  const selectHandler: ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const selectedForecaster = event.currentTarget.value;
    return props.onChange(selectedForecaster);
  };

  return (
    <section>
      <label htmlFor="select-forecaster" className={styles.forecasterSelect}>
        Forecaster:
      </label>
      <Select
        id="select-forecaster"
        variant="outline"
        placeholder="Select forecaster"
        onChange={selectHandler}
        value={props.selectedForecaster}
      >
        <option value="all">All</option>
        <option value="unassigned">Unassigned</option>
        {forecasterIds.map((forecasterId) => {
          return (
            <option key={forecasterId} value={forecasterId}>
              {forecasterId}
            </option>
          );
        })}
      </Select>
    </section>
  );
};
