import { GPUploadPopup } from "../../components/GPUploadPopup/GPUploadPopup";
import { useUploadAdjustedDriverHoursMutation } from "../../generated/graphql";
import { TEXT } from "../../helpers/constants/TEXT";

export interface UploadAdjustedDirverHoursProps {
  setForceRefresh?: (forceRefresh: boolean) => void;
  onSuccess?: () => void;
  selectedWeek?: string;
  testId?: string;
}

export const UploadAdjustedDriverHours: React.FC<
  UploadAdjustedDirverHoursProps
> = ({ setForceRefresh, selectedWeek, testId, onSuccess }) => {
  const mutationParameters = {
    selectedWeek,
  };

  const refetchQueries = ["GetMostRecentAdjustedHoursUploadDate"];

  return (
    <GPUploadPopup
      onSuccess={onSuccess}
      setUploadComplete={setForceRefresh}
      modelTitle={TEXT.UPLOAD_DRIVER_HOURS}
      modelHeader={TEXT.HEADER_UPLOAD_DRIVER_HOURS}
      modelBody={
        <p>
          Upload a csv file of adjusted Driver Hours per location per hour local
          time. Any existing Driver Hours for a location/time combination will
          be overwritten with the new values. The format must be{" "}
          <b>exact match</b> of the downloaded csv and only changes to Hours
          section will be updated. Date must be in the format:{" "}
          <b>YYYY-MM-DD. Beware of Excel date reformatting!</b>
        </p>
      }
      uploadMutation={useUploadAdjustedDriverHoursMutation}
      uploadMutationProps={mutationParameters}
      uploadMutationRefetchQueries={refetchQueries}
      allowDatesOutsideSelectedWeekCheckbox={false}
      uploadButtonWidth={350}
      allowTypeName
      testId={testId}
    ></GPUploadPopup>
  );
};
