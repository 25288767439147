export const constants = {
  time: {
    DATE_FNS_ENUM_VALUE_TO_DAY_OF_WEEK: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    },
    DATE_FNS_ENUM_DAY_OF_WEEK_TO_VALUE: {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    },
    DAYS_IN_A_WEEK: 7,
    DAYS_OF_THE_WEEK: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ] as Array<
      | "Monday"
      | "Tuesday"
      | "Wednesday"
      | "Thursday"
      | "Friday"
      | "Saturday"
      | "Sunday"
    >,
    DAYS_OF_THE_WEEK_ABBREVIATIONS: [
      "mon",
      "tue",
      "wed",
      "thu",
      "fri",
      "sat",
      "sun",
    ] as Array<"mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun">,
    MILLISECONDS_IN_A_MINUTE: 60000,
    MILLISECONDS_IN_A_SECOND: 1000,
    HOURS_IN_A_DAY: 24,
    LOCATION_START_HOUR: 5,
    DATE_FNS_DATE_ISO_FORMAT_SPECIFIER: "yyyy-MM-dd",
    GOPUFF_OPERATIONAL_START_DAY_OF_WEEK: "Monday",
    GOPUFF_HOURS_FOR_CHARTS: [
      5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      25, 26, 27, 28,
    ],
  },
  weeksOutToForecast: 8,
  commonErrorCodesToReadableStringsMap: {} as { [key: string]: string },
  csv: {
    validFileTypes: [".csv", "text/csv", "application/vnd.ms-excel"],
  },
  excel: {
    validFileTypes: [
      ".excel",
      "text/excel",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  },
  pagination: {
    MINIMUM_PAGE_COUNT: 1,
  },
  shifts: {
    UPLOAD_BATCH_SIZE: 1000,
  },
  errorCodes: {
    badUserInput: "BAD_USER_INPUT",
    tokenExpired: "TOKEN_EXPIRED",
  },
};
