import { Flex, IconButton, Select, Spinner } from "@chakra-ui/react";
import { toNumber, toString } from "lodash";
import { ChangeEventHandler, useState } from "react";
import { FaDownload } from "react-icons/fa";
import {
  GetAdjustedDriverHoursRunsForWeekQuery,
  useGetAdjustedDriverHourLocationsLazyQuery,
  useGetAdjustedDriverHoursRunsForWeekLazyQuery,
} from "../../generated/graphql";
import { exportCSVFile } from "../../helpers/csv/exportToCSV";
import { constants } from "../../helpers/general/constants";
import { formatLongDateString } from "../../helpers/general/date";
import { getNumbersBetween } from "../../helpers/general/getNumbersBetween";
import { getDHCSVFromAdjustedDHs } from "../../helpers/odhTransform/getODHCSVFromODHLocations";
import styles from "./styles.module.css";

export const AdjustedDriverHoursRunsSelect = (props: {
  onChange: (runId: number | undefined) => void;
  selectedWeek: string;
  triggerRefresh?: number;
  selectedAdjustedDHRunId?: number;
  testId?: string;
}) => {
  const [triggerRefreshNumber, setTriggerRefreshNumber] = useState<number>();

  const getAdjustedDriverHoursRuns = (
    data?: GetAdjustedDriverHoursRunsForWeekQuery | undefined
  ): Array<{ key: string; value: number }> => {
    if (!data) {
      data = adjustedDHRuns;
    }
    if (!data?.getAdjustedDriverHoursRunsForWeek) return [];

    return data.getAdjustedDriverHoursRunsForWeek.map((adjustedDHRun) => {
      return {
        key: `${adjustedDHRun.id ? toString(adjustedDHRun.id) + " - " : ""}${
          adjustedDHRun.name ? adjustedDHRun.name + " - " : ""
        }${adjustedDHRun.source ? adjustedDHRun.source + " - " : ""}${
          adjustedDHRun.odhRunsId
            ? adjustedDHRun.odhRunsId.toString() + " - "
            : ""
        }${
          adjustedDHRun.createdAt
            ? formatLongDateString(adjustedDHRun.createdAt)
            : ""
        }`,
        value: adjustedDHRun.id,
      };
    });
  };

  const [
    getAdjustedDriverHoursRunsForWeek,
    { data: adjustedDHRuns, loading: adjustedDHRunsLoading },
  ] = useGetAdjustedDriverHoursRunsForWeekLazyQuery({
    variables: {
      operationalWeekStartDate: props.selectedWeek,
    },
    onCompleted: (data) => {
      if (
        (!props.selectedAdjustedDHRunId || props.selectedAdjustedDHRunId < 0) &&
        data &&
        data.getAdjustedDriverHoursRunsForWeek
      ) {
        const options = getAdjustedDriverHoursRuns(data);
        props.onChange(options[0]?.value);
      } else if (!data?.getAdjustedDriverHoursRunsForWeek) {
        props.onChange(undefined);
      }
    },
  });

  if (triggerRefreshNumber !== props.triggerRefresh) {
    if (props.selectedWeek) {
      void getAdjustedDriverHoursRunsForWeek();
    }
    setTriggerRefreshNumber(props.triggerRefresh);
  }

  const [getAdjustedDHData, { loading: adjustedDHDataLoading }] =
    useGetAdjustedDriverHourLocationsLazyQuery({
      onCompleted: (data) => {
        if (data) {
          const headers = [
            "date",
            "id",
            "market",
            "dow",
            "mode",
            ...getNumbersBetween(0, constants.time.HOURS_IN_A_DAY - 1).map(
              (number) => `hours${number}`
            ),
            "hoursTotal",
          ];
          const odhTransformDataCSV = getDHCSVFromAdjustedDHs(data);
          exportCSVFile(odhTransformDataCSV, headers, csvFileName, false);
        }
      },
    });

  const [csvFileName, setCsvFileName] = useState<string>("");

  const selectHandler: ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const runId = toNumber(event.currentTarget.value);
    return props.onChange(runId);
  };

  return (
    <section className="adjuted-dh-runs-select">
      <label htmlFor="select-week" className={styles.adjutedDhRunsSelectLabel}>
        Existing Driver Partner Hours
      </label>
      {adjustedDHRunsLoading ? (
        <Spinner />
      ) : (
        <Flex>
          {!adjustedDHRuns?.getAdjustedDriverHoursRunsForWeek?.length ? (
            <p>No Driver Parter Hours, please run ODH Transform or Upload</p>
          ) : (
            <>
              <Select
                id="select-adjusted-dh-runs"
                variant="outline"
                onChange={selectHandler}
                value={props.selectedAdjustedDHRunId}
                data-test-id={props.testId ?? "select-adjusted-dh-runs"}
              >
                {getAdjustedDriverHoursRuns().map((item) => {
                  return (
                    <option key={item.key} value={item.value}>
                      {item.key}
                    </option>
                  );
                })}
              </Select>
              <IconButton
                style={{ marginLeft: 5 }}
                aria-label="Download selected driver partner hours"
                icon={<FaDownload />}
                isDisabled={
                  !props.selectedAdjustedDHRunId ||
                  props.selectedAdjustedDHRunId < 0
                }
                isLoading={adjustedDHDataLoading}
                onClick={() => {
                  if (
                    !props.selectedAdjustedDHRunId ||
                    props.selectedAdjustedDHRunId < 0
                  )
                    return;
                  const runs = getAdjustedDriverHoursRuns();
                  const run = runs.find(
                    (elm) => elm.value === props.selectedAdjustedDHRunId
                  );
                  setCsvFileName(run?.key ?? "AdjustedDHDocument");
                  void getAdjustedDHData({
                    variables: {
                      adjustedDriverHoursRunId: props.selectedAdjustedDHRunId,
                    },
                  });
                }}
                data-test-id="shiftqa-adjusted-driver-hours-runs-download"
              ></IconButton>
            </>
          )}
        </Flex>
      )}
    </section>
  );
};
