import {
  Center,
  ChakraProvider,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { Column, useSortBy, useTable } from "react-table";

export interface OvfQADailyTableRow {
  day: string;
  dopplerForecast: number;
  dopplerForecastDistribution: number;
  threeWeekOrderHistoryDistribution: number;
  seasonalityAdjustmentTotal: number;
  globalOvfTotal: number;
  dailyEventAdjustmentTotal: number;
}

export interface EventAdjustmentTotal {
  tag: string;
  total: number;
}

export interface OvfQADailyTableProps {
  tableData: OvfQADailyTableRow[];
  loading: boolean;
}

const columns: Array<Column<OvfQADailyTableRow>> = [
  {
    Header: "Day",
    accessor: "day",
    testId: "day",
  },
  {
    Header: "Doppler",
    accessor: (row) => {
      return row.dopplerForecast.toLocaleString("en-US");
    },
  },
  {
    Header: "Doppler Distr",
    accessor: (row) => {
      return (row.dopplerForecastDistribution * 100).toFixed(2) + "%";
    },
  },
  {
    Header: "Trailing 3 Wks Distr",
    accessor: (row) => {
      return (row.threeWeekOrderHistoryDistribution * 100).toFixed(2) + "%";
    },
  },
  {
    Header: "Seasonality Adjustments",
    accessor: (row) => {
      return Math.round(row.seasonalityAdjustmentTotal).toLocaleString("en-US");
    },
  },
  {
    Header: "Total Seasonally Adj Fcst",
    accessor: (row) => {
      return Math.round(
        row.dopplerForecast + row.seasonalityAdjustmentTotal
      ).toLocaleString("en-US");
    },
  },
  {
    Header: "Event Adjustments",
    accessor: (row) => {
      return Math.round(row.dailyEventAdjustmentTotal).toLocaleString("en-US");
    },
  },
  {
    Header: "FINAL OVF",
    accessor: (row) => {
      return Math.round(
        row.dopplerForecast +
          row.seasonalityAdjustmentTotal +
          row.dailyEventAdjustmentTotal
      ).toLocaleString("en-US");
    },
    testId: "final-ovf",
  },
  {
    Header: "OVF Distr",
    accessor: (row) => {
      return (
        (
          ((row.dopplerForecast +
            row.seasonalityAdjustmentTotal +
            row.dailyEventAdjustmentTotal) /
            row.globalOvfTotal) *
          100
        ).toFixed(2) + "%"
      );
    },
  },
];

export const OvfQADailyTable: React.FC<OvfQADailyTableProps> = ({
  tableData,
  loading,
}) => {
  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable(
    {
      columns: columns,
      data: tableData,
      getRowId: (row, _relativeIndex, _parent) => {
        return row.day.toString();
      },
    },
    useSortBy
  );

  return (
    <Container>
      <ChakraProvider>
        <Table size="sm" variant="striped" data-test-id="ovf-qa-daily-table">
          <Thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                key={"header group " + headerGroupIndex.toString()}
              >
                {headerGroup.headers.map((column, index) => (
                  <Th
                    userSelect="none"
                    {...column.getHeaderProps(
                      Object.assign(column.getSortByToggleProps(), {
                        style: { minWidth: column.minWidth },
                      })
                    )}
                    key={"column " + index.toString()}
                  >
                    <Flex alignItems="center">
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsChevronCompactDown />
                        ) : (
                          <BsChevronCompactUp />
                        )
                      ) : (
                        ""
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {loading ? (
            <Tbody>
              <Tr>
                <Td colSpan={10000}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={"row " + rowIndex.toString()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={rowIndex.toString() + "-" + cellIndex.toString()}
                          data-test-column={cell.column.testId}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </ChakraProvider>
    </Container>
  );
};
