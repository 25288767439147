import { ApolloLink } from "@apollo/client";
import { oktaAuth } from "../auth/oktaAuth";

export const authLink = new ApolloLink((operation, forward) => {
  const token = oktaAuth.getAccessToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});
