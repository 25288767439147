import { useOktaAuth } from "@okta/okta-react";
import { Dispatch, SetStateAction } from "react";
import { Navigate } from "react-router-dom";
import OktaSignInWidget from "./OktaSignInWidget";

export const Login = ({
  setCorsErrorModalOpen,
}: {
  setCorsErrorModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { authState } = useOktaAuth();

  if (!authState) {
    return null;
  }

  return authState.isAuthenticated ? (
    <Navigate to={{ pathname: "/dopplerQA" }} />
  ) : (
    <OktaSignInWidget {...{ setCorsErrorModalOpen }} />
  );
};
export default Login;
