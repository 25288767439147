import { Select } from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import "./dropdownSelect.css";

export const DropdownSelect = (props: {
  onChange: (selectedLayer: string) => void;
  id?: string;
  className?: string;
  label?: string;
  placeholder: string;
  selectOptions: string[];
  selection?: string;
  disabled?: boolean;
  testId?: string;
}) => {
  const selectHandler: ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const selectedLayer = event.currentTarget.value;
    return props.onChange(selectedLayer);
  };

  return (
    <section className={props.className}>
      {props.label && (
        <label htmlFor={props.id ?? ""} className="dropdownSelect">
          {props.label}
        </label>
      )}
      <Select
        id={props.id ?? ""}
        variant="outline"
        placeholder={props.placeholder}
        onChange={selectHandler}
        value={props.selection ?? props.placeholder}
        isDisabled={props.disabled}
        data-test-id={props.testId}
      >
        {props.selectOptions.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </Select>
    </section>
  );
};
