export const VALIDATION_ERRORS_TO_LOCATIONS_IN_COUNTRY = (
  locations: number[]
): string => {
  return "Invalid location(s) in country: " + locations.toString();
};

export const VALIDATION_ERRORS_TO_READABLE_STRINGS: Record<string, string> = {
  INVALID_LOCATION_LIST:
    "Invalid location list. Please input a comma-delimited list of " +
    "location ids or select 'ALL' locations.",
};
