import { ApolloClient, ApolloLink } from "@apollo/client";
import apolloLogger from "apollo-link-logger";
import { createUploadLink } from "apollo-upload-client";
import { cache } from "./apolloCache";
import { authLink } from "./authLink";
import { errorLink } from "./errorLink";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPH_URL,
  credentials: "same-origin",
  headers: {
    "apollo-require-preflight": true,
  },
});

const link = ApolloLink.from([errorLink, apolloLogger, authLink, httpLink]);

export const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
      partialRefetch: true,
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
