import { Code, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GPUploadPopup } from "../../../components/GPUploadPopup/GPUploadPopup";
import { useUploadAdjustedOvfMutation } from "../../../generated/graphql";
import { TEXT } from "../../../helpers/constants/TEXT";
import styles from "./styles.module.css";

interface UploadOvfModalProps {
  setForceRefresh?: (forceRefresh: boolean) => void;
  selectedWeek: string;
  testId?: string;
}

export const UploadOvfModal: React.FC<UploadOvfModalProps> = ({
  setForceRefresh,
  selectedWeek,
  testId,
}) => {
  const mutationParameters = {
    selectedWeek,
  };

  return (
    <GPUploadPopup
      setUploadComplete={setForceRefresh}
      modelTitle={TEXT.UPLOAD_ADJOVF}
      modelHeader={TEXT.HEADER_UPLOAD_ADJOVF}
      uploadMutationProps={mutationParameters}
      allowDatesOutsideSelectedWeekCheckbox
      testId={testId}
      modelBody={
        <>
          <p className={styles.uploadMessage}>
            Upload a csv file of adjusted OVF per location per hour local time.
            Any existing OVF for a location/time combination will be overwritten
            with the new values. One whole operational week of data per location
            is required. Required columns are: <Code>Created_At</Code>,{" "}
            <Code>Location_ID</Code> and <Code>OVF</Code>.
          </p>
          <span>For Example:</span>
          <Table size="sm" variant="striped" marginBottom="10px">
            <Thead>
              <Tr>
                <Th>Created_At</Th>
                <Th>Location_ID</Th>
                <Th>OVF</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>4/25/21 15:00:00</Td>
                <Td>175</Td>
                <Td>19</Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      }
      uploadMutation={useUploadAdjustedOvfMutation}
    ></GPUploadPopup>
  );
};
