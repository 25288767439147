/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Input } from "@chakra-ui/input";
import React from "react";
import { CellProps } from "react-table";

// Create an editable cell renderer
export function GPEditableCell<T extends Record<string, unknown>>({
  value: initialValue,
  updateCurrentData,
  ...props
}: CellProps<T>) {
  const [value, setValue] = React.useState(initialValue ? initialValue : "");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (updateCurrentData && value !== initialValue) {
      updateCurrentData({ column: props?.cell?.column?.id, value, props });
    }
  };

  React.useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  const cellClass = props?.column?.cellClass;
  return (
    <Input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={cellClass}
    />
  );
}
