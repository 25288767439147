import { Container } from "@chakra-ui/react";
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineMarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";

const formatActualsData = (actualsData: Array<number | null>) => {
  return actualsData.map((actual, index) => {
    return {
      x: index - 3,
      y: actual ?? 0,
    };
  });
};

const formatStandardWeeklyData = (weeklyData: Array<number | null>) => {
  return weeklyData.map((weeklyDataPoint, index) => {
    return {
      x: index + 1,
      y: weeklyDataPoint ?? 0,
    };
  });
};

const formatOvfPublishedWeeklyData = (
  weeklyData: Array<number | null>,
  historicalWeeksToInclude = 3
) => {
  return weeklyData.map((weeklyDataPoint, index) => {
    return {
      x:
        index < historicalWeeksToInclude
          ? index - 3
          : index - (historicalWeeksToInclude - 1),
      y: weeklyDataPoint ?? 0,
    };
  });
};

export const WeeklyChart = (props: {
  thisYearActuals: Array<number | null>;
  lastYearActuals: Array<number | null>;
  ovfPublishedByWeek: Array<number | null>;
  adjustmentsWeeklyTotals: Array<number | null>;
  activeDopplerWeeklyTotals: Array<number | null>;
}) => {
  const thisYearActualsDataPoints = formatActualsData(props.thisYearActuals);
  const lastYearActualsDataPoints = formatActualsData(props.lastYearActuals);
  const ovfPublishedByWeekDataPoints = formatOvfPublishedWeeklyData(
    props.ovfPublishedByWeek
  );
  const adjustmentsWeeklyTotalsDataPoints = formatStandardWeeklyData(
    props.adjustmentsWeeklyTotals
  );
  const activeDopplerWeeklyTotalsDataPoints = formatStandardWeeklyData(
    props.activeDopplerWeeklyTotals
  );

  return (
    <Container
      data-test-id="multiweek-forecast-chart"
      maxW="container.xl"
      alignContent="center"
      width="100%"
    >
      <FlexibleWidthXYPlot
        align="center"
        height={200}
        margin={{ left: 70, right: 70, top: 10, bottom: 25 }}
      >
        <VerticalGridLines tickTotal={7} />
        <HorizontalGridLines />
        <XAxis
          title="Week"
          tickFormat={(week: number) => `wk ${week}`}
          tickValues={[-3, -2, -1, 1, 2, 3, 4, 5, 6, 7, 8]}
        />
        <YAxis title="" />
        <LineMarkSeries
          key="this year actuals"
          data={thisYearActualsDataPoints}
          color="green"
        />
        <LineMarkSeries
          key="last year actuals"
          data={lastYearActualsDataPoints}
          color="green"
          strokeStyle="dashed"
        />
        <LineMarkSeries
          key="published ovf"
          data={ovfPublishedByWeekDataPoints}
          color="red"
          strokeStyle="dashed"
        />
        <LineMarkSeries
          key="new ovf"
          data={adjustmentsWeeklyTotalsDataPoints}
          color="red"
        />
        <LineMarkSeries
          key="doppler"
          data={activeDopplerWeeklyTotalsDataPoints}
          color="blue"
        />
      </FlexibleWidthXYPlot>
      <DiscreteColorLegend
        width={700}
        height={60}
        items={[
          { title: "This year actuals", color: "green" },
          { title: "Last year actuals", color: "green", strokeStyle: "dashed" },
          { title: "Published OVF", color: "red", strokeStyle: "dashed" },
          { title: "New OVF", color: "red" },
          { title: "Doppler", color: "blue" },
        ]}
        orientation="horizontal"
      />
    </Container>
  );
};
