import { GraphQLError } from "graphql";
import React from "react";
import { constants } from "../general/constants";
import { getContentAsReactElement } from "../react/getContentAsReactElement";

export const getGraphQLErrorsAsHumanReadableStrings = (
  errors: readonly GraphQLError[],
  expectedErrorCodesToReadableStringsMap: Record<
    string,
    string | React.ReactElement
  > = {}
): React.ReactElement => {
  return (
    <section>
      {errors.map((error, index) => (
        <section key={`element-${index}`}>
          {getContentAsReactElement(
            expectedErrorCodesToReadableStringsMap[error.message]
          ) ||
            getContentAsReactElement(
              constants.commonErrorCodesToReadableStringsMap[error.message]
            ) ||
            getContentAsReactElement(error.message)}
        </section>
      ))}
    </section>
  );
};
