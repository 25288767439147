import React from "react";
import styles from "../styles.module.css";

const DopplerRunDateNotFoundMessage = () => (
  <span id="doppler-run-date-not-found-message">
    The Doppler Run you have selected is not yet available in the replicated
    Snowflake tables. The replication generally happens by 4pm Eastern time. For
    more details on escalation procedures, see{" "}
    <a
      className={styles.popupLink}
      href={
        "https://gopuff.atlassian.net/wiki/spaces/FE/" +
        "pages/2555740360/Forecasting+Portal+Bugs+Escalation+Policy"
      }
    >
      our escalation policy
    </a>
    <span>.</span>
  </span>
);

DopplerRunDateNotFoundMessage.displayName = "DopplerRunDateNotFoundComponent";

export const GRAPHQL_ERRORS_TO_READABLE_STRINGS: Record<
  string,
  string | React.ReactElement
> = {
  DOPPLER_RUN_DATE_NOT_FOUND: <DopplerRunDateNotFoundMessage />,
};
