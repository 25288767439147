import { Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react";

export const Healthz = () => {
  const {
    REACT_APP_BUILD_HASH,
    REACT_APP_VERSION,
    REACT_APP_GRAPH_URL,
  } = process.env;

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box>
        <UnorderedList>
          <ListItem>build: {REACT_APP_BUILD_HASH}</ListItem>
          <ListItem>version: {REACT_APP_VERSION}</ListItem>
          <ListItem>backend: {REACT_APP_GRAPH_URL}</ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  );
};
