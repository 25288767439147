import { Box } from "@chakra-ui/react";
import { ChangeEventHandler, useEffect } from "react";
import { useGetForecastLocationsByMetroRegionsQuery } from "../../generated/graphql";
import { PageSpinner } from "../PageSpinner/PageSpinner";
import { SelectLocationsMenu } from "../SelectLocationsMenu/SelectLocationsMenu";

interface SelectForecastLocationsMenuProps {
  countryCode: string;
  operationalWeekStartDate: string;
  selectedLocation?: string;
  setSelectedLocation: (locationId: string) => void;
  setSelectedLocationIds?: (locationIds: number[]) => void;
  selectedMetroRegion?: string;
  setSelectedMetroRegion: (locationId: string) => void;
  weeksToInclude?: number;
}

export const SelectForecastLocationsMenu: React.FC<SelectForecastLocationsMenuProps> = ({
  countryCode,
  operationalWeekStartDate,
  selectedLocation,
  setSelectedLocation,
  setSelectedLocationIds,
  selectedMetroRegion,
  setSelectedMetroRegion,
  weeksToInclude,
}) => {
  const {
    data: metroRegionsResponse,
    loading: metroRegionsIsLoading,
  } = useGetForecastLocationsByMetroRegionsQuery({
    variables: {
      operationalWeekStartDate: operationalWeekStartDate,
      countryCode: countryCode,
      weeksToInclude,
    },
  });

  useEffect(() => {
    setSelectedMetroRegion("");
    setSelectedLocation("");
  }, [countryCode, setSelectedLocation, setSelectedMetroRegion]);

  const handleChangeSelectedMetroRegion: ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    event.preventDefault();
    const newMetroRegion = event.currentTarget.value;
    setSelectedMetroRegion(newMetroRegion);
    setSelectedLocation("");
    if (setSelectedLocationIds) {
      setSelectedLocationIds([]);
    }
  };

  const handleChangeSelectedLocation: ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    event.preventDefault();
    const newLocation = event.currentTarget.value;
    setSelectedLocation(newLocation);
    if (setSelectedLocationIds) {
      if (newLocation.toLowerCase() === "all") {
        if (selectedMetroRegion?.toLowerCase() === "all") {
          const locationIds = metroRegionsResponse?.forecastLocationsByMetroRegions.find(
            (metroRegion) => metroRegion.name.toLowerCase() === "all"
          )?.locationIds as number[];
          setSelectedLocationIds(locationIds);
        } else {
          const locationIds = metroRegionsResponse?.forecastLocationsByMetroRegions.find(
            (metroRegion) => metroRegion.name === selectedMetroRegion
          )?.locationIds as number[];
          setSelectedLocationIds(locationIds);
        }
      } else {
        setSelectedLocationIds([parseInt(newLocation, 10)]);
      }
    }
  };

  return (
    <Box>
      {metroRegionsIsLoading && <PageSpinner />}
      <SelectLocationsMenu
        id="forecast"
        metroRegionsIsLoading={metroRegionsIsLoading}
        metroRegions={
          metroRegionsResponse?.forecastLocationsByMetroRegions ?? []
        }
        selectedMetroRegion={selectedMetroRegion}
        selectedLocation={selectedLocation}
        allLocationsOptionIsEnabled
        showLabel
        handleChangeSelectedMetroRegion={handleChangeSelectedMetroRegion}
        handleChangeSelectedLocation={handleChangeSelectedLocation}
      />
    </Box>
  );
};
