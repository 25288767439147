import { Job } from "../../../types/Job";

const jobId = parseInt(
  process.env.REACT_APP_DATABRICKS_THE_SHIFTER_JOB_ID ?? "",
  10
);
export const theShifterJob: Job = {
  name: "The Shifter",
  id: Number.isFinite(jobId) ? jobId : 0,
  environment: "legacy",
  testId: "the-shifter-job",
};
