import React from "react";
import ReactDataSheet from "react-datasheet";
import { GridElement } from "./GPHourlyDataSheet";

export type CellStyler = (
  props: React.PropsWithChildren<ReactDataSheet.CellRendererProps<GridElement>>
) => React.CSSProperties;

export const getCellRenderer = (
  getDataCellStyles?: CellStyler
): ReactDataSheet.CellRenderer<GridElement> => {
  return React.memo(function CellRenderer(
    props: React.PropsWithChildren<
      ReactDataSheet.CellRendererProps<GridElement>
    >
  ) {
    const customStyles = getDataCellStyles ? getDataCellStyles(props) : {};

    return (
      <td
        style={{
          minWidth: "24px",
          textAlign: "center",
          fontSize: "10px",
          ...customStyles,
        }}
        onMouseDown={props.onMouseDown}
        onMouseOver={props.onMouseOver}
        onDoubleClick={props.onDoubleClick}
        className="cell"
      >
        {props.children}
      </td>
    );
  });
};
