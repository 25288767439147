import { useUploadIncrementalAdjustedDriverHoursMutation } from "../../generated/graphql";
import { GPUploadPopup } from "../GPUploadPopup/GPUploadPopup";
import { UploadAdjustedDirverHoursProps } from "../UploadAdjustedDriverHours/UploadAdjustedDriverHours";

export const UploadIncrementalAdjustedDriverHours: React.FC<
  UploadAdjustedDirverHoursProps
> = ({ setForceRefresh, selectedWeek, testId, onSuccess }) => {
  const mutationParameters = {
    selectedWeek,
  };

  const refetchQueries = ["GetMostRecentAdjustedHoursUploadDate"];

  return (
    <GPUploadPopup
      onSuccess={onSuccess}
      setUploadComplete={setForceRefresh}
      modelTitle="Upload Incremental Driver Hours"
      modelHeader="Upload Incremental Driver Hours File"
      modelBody={
        <p>
          Upload a csv file of Incremental Driver Hours per location per hour
          local time. Once uploaded, the hours will be combined with current
          hours in MUI. The format must be <b>exact match</b> of the downloaded
          csv and only changes to Hours section will be updated. Date must be in
          the format: <b>YYYY-MM-DD. Beware of Excel date reformatting!</b>
        </p>
      }
      uploadMutation={useUploadIncrementalAdjustedDriverHoursMutation}
      uploadMutationProps={mutationParameters}
      uploadMutationRefetchQueries={refetchQueries}
      allowDatesOutsideSelectedWeekCheckbox={false}
      uploadButtonWidth={350}
      testId={testId}
    ></GPUploadPopup>
  );
};
