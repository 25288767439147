import { GetTotalRowsAfterOverridesHandler } from "./getHourlyDataForWeekAfterOverrides";
export const getTotalRowsAfterOverrides: GetTotalRowsAfterOverridesHandler = function (
  hourlyDataForWeekAfterOverrides: Array<Array<number | null>>
) {
  const totals: number[] = new Array<number>(
    hourlyDataForWeekAfterOverrides[0].length
  ).fill(0);
  hourlyDataForWeekAfterOverrides.forEach((hourlyDataForDay) => {
    hourlyDataForDay.forEach((hourlyValue, col) => {
      totals[col] += hourlyValue ?? 0;
    });
  });
  return totals;
};
