import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ChangeEventHandler } from "react";
import { GPSimpleSortableTable } from "../../../components/GPSimpleSortableTable/GPSimpleSortableTable";
import { PageSpinner } from "../../../components/PageSpinner/PageSpinner";
import { SelectLocationsMenu } from "../../../components/SelectLocationsMenu/SelectLocationsMenu";
import {
  useGetDhFailedUploadLazyQuery,
  useGetShiftsQaLocationsByMetroRegionsQuery,
  useGetShiftsWeeklyQaQuery,
} from "../../../generated/graphql";
import { exportCSVFile } from "../../../helpers/csv/exportToCSV";
import { toEmptyOrRoundedString } from "../../../helpers/general/number";
import numericStringComparator from "../../../helpers/reactTable/numericStringComparator";
import styles from "../styles.module.css";

interface LocationsByWeekMenuProps {
  selectedCountryCode: string;
  operationalWeekStartDate: string;
  adjustedDHRunId: number;
}

export const LocationsByWeekMenu: React.FC<LocationsByWeekMenuProps> = ({
  selectedCountryCode,
  operationalWeekStartDate,
  adjustedDHRunId,
}) => {
  const [selectedMetroRegion, setSelectedMetroRegion] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [locationIds, setLocationIds] = useState<number[]>([]);

  const { data: metroRegionsResponse, loading: metroRegionsIsLoading } =
    useGetShiftsQaLocationsByMetroRegionsQuery({
      variables: {
        countryCode: selectedCountryCode,
        operationalWeekStartDate,
        adjustedDHRunId,
      },
    });

  const { data: shiftsWeeklyQaResponse, loading: shiftsWeeklyQaIsLoading } =
    useGetShiftsWeeklyQaQuery({
      variables: {
        locationIds: locationIds,
        flaggedOnly: selectedLocation === "flagged",
        operationalWeekStartDate,
        adjustedDHRunId,
      },
      skip: !selectedLocation,
    });

  const [
    getDHFailedUpload,
    { data: DHFailedUpload, loading: gettingDHFailedUpload },
  ] = useGetDhFailedUploadLazyQuery();

  useEffect(() => {
    setLocationIds(
      selectedMetroRegion && selectedLocation
        ? metroRegionsResponse?.shiftsQALocationsByMetroRegions
          ? selectedLocation.toLowerCase() === "all" ||
            selectedLocation.toLowerCase() === "flagged"
            ? metroRegionsResponse?.shiftsQALocationsByMetroRegions.find(
                (metroRegion) => metroRegion.name === selectedMetroRegion
              )?.locationIds ?? []
            : [parseInt(selectedLocation, 10)]
          : []
        : []
    );
  }, [selectedMetroRegion, selectedLocation, metroRegionsResponse]);

  useEffect(() => {
    if (DHFailedUpload && !gettingDHFailedUpload) {
      const headers = [
        "date",
        "locationId",
        "locationName",
        "weekday",
        "startTime",
        "endTime",
        "driverSlots",
        "length",
        "position",
        "deliveryMode",
        "createdTime",
        "updatedTime",
        "mustRequest",
        "submitStatus",
      ];

      exportCSVFile(DHFailedUpload.shifts, headers, "DHFailedUploads", true);
    }
  }, [DHFailedUpload, gettingDHFailedUpload]);

  const handleChangeSelectedMetroRegion: ChangeEventHandler<
    HTMLSelectElement
  > = (event) => {
    event.preventDefault();
    const newMetroRegion = event.currentTarget.value;
    setSelectedMetroRegion(newMetroRegion);
    setSelectedLocation("");
  };

  const handleChangeSelectedLocation: ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    event.preventDefault();
    const newLocation = event.currentTarget.value;
    setSelectedLocation(newLocation);
  };

  useEffect(() => {
    setSelectedMetroRegion("");
    setSelectedLocation("");
  }, [selectedCountryCode]);

  return (
    <section>
      {(metroRegionsIsLoading || shiftsWeeklyQaIsLoading) && <PageSpinner />}
      <label
        style={{
          fontWeight: 700,
          marginTop: 10,
          marginBottom: 10,
          display: "block",
        }}
      >
        Locations by Week
      </label>
      <SelectLocationsMenu
        id="locations-by-week"
        metroRegionsIsLoading={metroRegionsIsLoading}
        metroRegions={
          metroRegionsResponse?.shiftsQALocationsByMetroRegions ?? []
        }
        selectedMetroRegion={selectedMetroRegion}
        selectedLocation={selectedLocation}
        allLocationsOptionIsEnabled
        flaggedLocationsOptionIsEnabled
        handleChangeSelectedMetroRegion={handleChangeSelectedMetroRegion}
        handleChangeSelectedLocation={handleChangeSelectedLocation}
        className={styles.inlineSelectLocationsMenu}
        testId="shiftqa-select-location-menu-by-week"
      />
      <Button
        isDisabled={locationIds.length === 0}
        onClick={() =>
          void getDHFailedUpload({
            variables: {
              locationIds: locationIds,
              operationalWeekStartDate,
              adjustedDHRunId,
            },
          })
        }
        data-test-id="shiftqa-download-dh-failed-upload-by-week"
      >
        Download DH Failed Upload
      </Button>
      <GPSimpleSortableTable
        testId="shiftqa-weekly-qa-table"
        columns={[
          {
            Header: "Metro",
            accessor: "name",
          },
          {
            Header: "Loc",
            accessor: "locationId",
            sortType: numericStringComparator,
          },
          {
            Header: "OVF",
            accessor: "ovf",
            sortType: numericStringComparator,
          },
          {
            Header: "ODH Target Min",
            accessor: "odhTargetMin",
            sortType: numericStringComparator,
          },
          {
            Header: "ODH Target",
            accessor: "odhTarget",
            sortType: numericStringComparator,
          },
          {
            Header: "ODH Target Max",
            accessor: "odhTargetMax",
            sortType: numericStringComparator,
          },
          {
            Header: "ODH Plan",
            accessor: "odhPlan",
            sortType: numericStringComparator,
          },
          {
            Header: "ODH Plan Var",
            accessor: "odhPlanVar",
            sortType: numericStringComparator,
          },
          {
            Header: "ODH Sched.",
            accessor: "odhSched",
            sortType: numericStringComparator,
          },
          {
            Header: "ODH Sched. Var",
            accessor: "odhSchedVar",
            sortType: numericStringComparator,
          },
          {
            Header: "DH Modeled",
            accessor: "dhModeled",
            sortType: numericStringComparator,
          },
          {
            Header: "DH Adjusted",
            accessor: "dhAdjusted",
            sortType: numericStringComparator,
          },
          {
            Header: "APPLIED ABSENTEE ADJ %",
            accessor: "appliedAbsenteeismAdjustmentPercentage",
            sortType: numericStringComparator,
          },
          {
            Header: "APPLIED ABSENTEE ADJ HRS",
            accessor: "appliedAbsenteeismAdjustmentHours",
            sortType: numericStringComparator,
          },
          {
            Header: "APPLIED UNSCHED %",
            accessor: "appliedUnscheduledPercentage",
            sortType: numericStringComparator,
          },
          {
            Header: "APPLIED UNSCHED HRS",
            accessor: "appliedUnscheduledHours",
            sortType: numericStringComparator,
          },
          {
            Header: "NET ABSENTEE ADJUSTMENT",
            accessor: "netAbsenteeismAdjustmentHours",
            sortType: numericStringComparator,
          },
          {
            Header: "DH NET ABSENTEE ADJUSTED",
            accessor: "dhNetOfAbsenteeismAdjustments",
            sortType: numericStringComparator,
          },
          {
            Header: "MIN DP ADJ",
            accessor: "minimumDpAdjustment",
            sortType: numericStringComparator,
          },
          {
            Header: "DH PLAN",
            accessor: "dhPlanned",
            sortType: numericStringComparator,
          },
          {
            Header: "TAPER ADJ",
            accessor: "taperAdjustmentHours",
            sortType: numericStringComparator,
          },
          {
            Header: "DH PLAN TAPERED",
            accessor: "dhPlannedTapered",
            sortType: numericStringComparator,
          },
          {
            Header: "DH ASSIGNED IN MUI",
            accessor: "dhAssignedInitial",
            sortType: numericStringComparator,
          },

          {
            Header: "DH UNASSIGNED IN MUI",
            accessor: "dhUnassignedInitial",
            sortType: numericStringComparator,
          },
          {
            Header: "DH NET OF ASSIGNED",
            accessor: "dhNetOfAssigned",
            sortType: numericStringComparator,
          },
          {
            Header: "DH SCHED.",
            accessor: "dhScheduled",
            sortType: numericStringComparator,
          },
          {
            Header: "DH SCHED. MUST REQUEST",
            accessor: "dhScheduledMustRequest",
            sortType: numericStringComparator,
          },
          {
            Header: "DH TL EXPECTED",
            accessor: "dhTotalExpected",
            sortType: numericStringComparator,
          },
          {
            Header: "DH TL UPDATED",
            accessor: "dhTotalUpdated",
            sortType: numericStringComparator,
          },
          {
            Header: "DH TL DELTA",
            accessor: "dhTotalDelta",
            sortType: numericStringComparator,
          },
          {
            Header: "DH ASSIGNED DELTA",
            accessor: "dhAssignedDelta",
            sortType: numericStringComparator,
          },
          {
            Header: "DH FAILED UPLOAD",
            accessor: "dhFailedUpload",
            sortType: numericStringComparator,
          },
          {
            Header: "Flagged",
            accessor: "flagged",
          },
        ]}
        rows={[
          ...(shiftsWeeklyQaResponse
            ? shiftsWeeklyQaResponse.shiftsWeeklyQA
            : []
          ).map((shiftWeeklyQAForLocation) => ({
            name: metroRegionsResponse?.shiftsQALocationsByMetroRegions
              ? metroRegionsResponse?.shiftsQALocationsByMetroRegions.find(
                  (odhLocationByMetroRegion) =>
                    odhLocationByMetroRegion.name.toLowerCase() !== "all" &&
                    odhLocationByMetroRegion.locationIds.indexOf(
                      shiftWeeklyQAForLocation.locationId
                    ) >= 0
                )?.name ?? ""
              : "",
            locationId: shiftWeeklyQAForLocation.locationId ?? "",
            ovf: shiftWeeklyQAForLocation.ovf?.toLocaleString("en-US") ?? "",
            odhTargetMin:
              shiftWeeklyQAForLocation.odhTargetMin?.toLocaleString("en-US") ??
              "",
            odhTarget:
              shiftWeeklyQAForLocation.odhTarget?.toLocaleString("en-US") ?? "",
            odhTargetMax:
              shiftWeeklyQAForLocation.odhTargetMax?.toLocaleString("en-US") ??
              "",
            odhPlan: toEmptyOrRoundedString(shiftWeeklyQAForLocation.odhPlan),
            odhPlanVar: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.odhPlanVar
            ),
            odhSched: toEmptyOrRoundedString(shiftWeeklyQAForLocation.odhSched),
            odhSchedVar: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.odhSchedVar
            ),
            dhModeled:
              shiftWeeklyQAForLocation.dhModeled?.toLocaleString("en-US") ?? "",
            dhAdjusted:
              shiftWeeklyQAForLocation.dhAdjusted?.toLocaleString("en-US") ??
              "",
            appliedAbsenteeismAdjustmentPercentage: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.appliedAbsenteeismAdjustmentPercentage
            ),
            appliedAbsenteeismAdjustmentHours: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.appliedAbsenteeismAdjustmentHours
            ),
            appliedUnscheduledPercentage: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.appliedUnscheduledPercentage
            ),
            appliedUnscheduledHours: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.appliedUnscheduledHours
            ),
            netAbsenteeismAdjustmentHours: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.netAbsenteeismAdjustmentHours
            ),
            dhNetOfAbsenteeismAdjustments: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhNetOfAbsenteeismAdjustments
            ),
            minimumDpAdjustment: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.minimumDpAdjustment
            ),
            dhPlanned: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhPlanned
            ),
            taperAdjustmentHours: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.taperAdjustmentHours
            ),
            dhPlannedTapered: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhPlannedTapered
            ),
            dhAssignedInitial: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhAssignedInitial
            ),
            dhUnassignedInitial: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhUnassignedInitial
            ),
            dhNetOfAssigned: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhNetOfAssigned
            ),
            dhScheduled: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhScheduled
            ),
            dhScheduledMustRequest: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhScheduledMustRequest
            ),
            dhTotalExpected: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhTotalExpected
            ),
            dhTotalUpdated: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhTotalUpdated
            ),
            dhTotalDelta: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhTotalDelta
            ),
            dhAssignedDelta: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhAssignedDelta
            ),
            dhFailedUpload: toEmptyOrRoundedString(
              shiftWeeklyQAForLocation.dhFailedUpload
            ),
            flagged: shiftWeeklyQAForLocation.flagged ? "1" : "",
          })),
        ]}
      />
    </section>
  );
};
