/* eslint-disable sonarjs/no-duplicate-string */
import { Box, Button, Grid, Heading, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { DropdownSelect } from "../../components/DropdownSelect/DropdownSelect";
import { GPPopup } from "../../components/GPPopup/GPPopup";
import { GPSimpleTable } from "../../components/GPSimpleTable/GPSimpleTable";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import {
  DailyMissingLocation,
  HourlyMissingLocation,
  useGetCurrentlyActiveDopplerRunDateQuery,
  useGetDopplerQaLazyQuery,
  useGetDopplerRunTimestampsThisWeekQuery,
  useSetSelectedDopplerRunMutation,
  WeeklyMissingLocation,
  WeeklyQa,
} from "../../generated/graphql";
import { getGraphQLErrorsAsHumanReadableStrings } from "../../helpers/graphql/getGraphQLErrorsAsHumanReadableStrings";
import { QAOverviewTable } from "./QAOverviewTable";
import { GRAPHQL_ERRORS_TO_READABLE_STRINGS } from "./helpers/errorsToReadableStringsMap";
import { SUCCESS_MESSAGES } from "./helpers/successMessages";
import styles from "./styles.module.css";

export const DopplerQA = () => {
  const [popupHeader, setPopupHeader] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string | React.ReactElement>(
    ""
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDopplerRun, setSelectedDopplerRun] = useState<{
    dopplerRunId: number;
    dopplerRunDateTime: string;
  }>();
  const [selectedCountry, setSelectedCountry] = useState<string>("All");
  const [selectedWeek, setSelectedWeek] = useState<string>();
  const {
    data: currentlyActiveDopplerRunDate,
    loading: isGetCurrentlyActiveDopplerRunDateLoading,
  } = useGetCurrentlyActiveDopplerRunDateQuery();
  const {
    data: dopplerRunTimestampsResult,
    loading: isFetchTimestampsLoading,
  } = useGetDopplerRunTimestampsThisWeekQuery();
  const dopplerRuns =
    dopplerRunTimestampsResult?.dopplerRunTimestampsThisWeek ?? [];
  const [getDopplerQA, { data: dopplerQA, loading: isFetchQALoading }] =
    useGetDopplerQaLazyQuery({
      variables: {
        runId: selectedDopplerRun?.dopplerRunId ?? 0,
      },
    });

  const weeklyQA = dopplerQA?.dopplerQA?.weeklyQAByCountry?.find(
    (w) => w?.countryCode === selectedCountry
  )?.weeklyQA;
  const qaTimestamp = dopplerQA?.dopplerQA?.qaRunDateTime;
  const weeks =
    weeklyQA?.map((week) => week?.operationalWeekStartDate ?? "") ?? [];
  const selectedWeeklyQA = selectedWeek
    ? weeklyQA?.find((week) => week?.operationalWeekStartDate === selectedWeek)
    : null;
  const locationsInDoppler = selectedWeeklyQA
    ? (selectedWeeklyQA as WeeklyQa).locationsInDoppler
    : "";
  const locationsInBackOffice = selectedWeeklyQA
    ? (selectedWeeklyQA as WeeklyQa).locationsInBackOffice
    : "";
  const locationsNotInBothTables = selectedWeeklyQA
    ? Math.abs(
        (locationsInDoppler as number) - (locationsInBackOffice as number)
      )
    : "";

  const [loadSelectedDopplerRun, { loading: isLoadSelectedDopplerRunLoading }] =
    useSetSelectedDopplerRunMutation({
      refetchQueries: ["GetCurrentlyActiveDopplerRunDate"],
    });

  async function handelApproveAndReleaseClick() {
    const response = await loadSelectedDopplerRun({
      variables: {
        dopplerQARunsId: selectedDopplerRun!.dopplerRunId,
      },
      context: {
        skipDefaultErrorHandling: true,
      },
    });

    if (response.errors) {
      const error = getGraphQLErrorsAsHumanReadableStrings(
        response.errors,
        GRAPHQL_ERRORS_TO_READABLE_STRINGS
      );
      setPopupHeader("Error");
      setPopupMessage(error);
      onOpen();
    } else {
      setPopupHeader("Success");
      const message = response.data?.setSelectedDopplerRun.message;
      if (message !== "" && message) {
        setPopupMessage(
          SUCCESS_MESSAGES.ApproveAndReleaseSuccess + " " + message
        );
      } else {
        setPopupMessage(SUCCESS_MESSAGES.ApproveAndReleaseSuccess);
      }
      onOpen();
    }
  }

  return (
    <Box pl={8} pr={8}>
      <GPPopup
        isOpen={isOpen}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onClose}
      />
      {(isGetCurrentlyActiveDopplerRunDateLoading ||
        isFetchTimestampsLoading ||
        isFetchQALoading ||
        isLoadSelectedDopplerRunLoading) && <PageSpinner />}
      <Grid id="doppler-run-menu" templateColumns="2fr 5fr" gap={40}>
        <Box>
          <Heading>Doppler Run</Heading>
          <DropdownSelect
            id="select-doppler-run"
            onChange={(dopplerRun) => {
              setSelectedDopplerRun({
                dopplerRunId:
                  dopplerRuns.find(
                    (dopplerRunData) =>
                      dopplerRunData.dopplerRunDateTime === dopplerRun
                  )?.dopplerRunId ?? 0,
                dopplerRunDateTime: dopplerRun,
              });
              void getDopplerQA();
            }}
            placeholder="Select Doppler Run"
            selection={selectedDopplerRun?.dopplerRunDateTime}
            selectOptions={dopplerRuns.map(
              (dopplerRun) => dopplerRun.dopplerRunDateTime
            )}
          />
          {qaTimestamp && (
            <div className={styles.qaRunTimestampContainer}>
              <span>QA Run:</span>
              <span>{qaTimestamp}</span>
            </div>
          )}
          <DropdownSelect
            id="select-country"
            className={styles.selectCountry}
            onChange={(country) => {
              setSelectedCountry(country);
            }}
            placeholder="Select Country"
            selection={selectedCountry}
            selectOptions={
              dopplerQA?.dopplerQA?.weeklyQAByCountry?.map(
                (w) => w.countryCode
              ) ?? []
            }
          />
        </Box>
        <Box>
          <Heading>QA Overview</Heading>
          <QAOverviewTable
            weeklyQA={weeklyQA ? (weeklyQA as WeeklyQa[]) : []}
          />
        </Box>
      </Grid>
      <Grid id="qa-weekly-detail-menu" templateColumns="2fr 5fr" gap={40}>
        <Box>
          <Heading>QA Weekly Detail</Heading>
          <DropdownSelect
            id="select-week"
            onChange={(week) => {
              setSelectedWeek(week);
            }}
            placeholder="Select Week"
            selection={selectedWeek}
            selectOptions={weeks}
          />
        </Box>
        <Box>
          <Heading as="h3" size="md" mt="2">
            Location Count
          </Heading>
          <GPSimpleTable
            rows={[
              ["Backoffice", "Doppler", "Not in both Tables"],
              selectedWeek
                ? [
                    locationsInBackOffice.toLocaleString("en-US"),
                    locationsInDoppler.toLocaleString("en-US"),
                    locationsNotInBothTables.toLocaleString("en-US"),
                  ]
                : [],
            ]}
          />
          <Heading as="h3" size="md" mt="2">
            Missing Locations
          </Heading>
          <GPSimpleTable
            rows={[
              [
                "Location ID",
                "Forecaster ID",
                "Launch Date",
                "Backoffice",
                "Doppler",
              ],
              ...(selectedWeeklyQA
                ? ((selectedWeeklyQA as WeeklyQa)
                    .weeklyMissingLocations as WeeklyMissingLocation[])
                : ([] as WeeklyMissingLocation[])
              ).map((weeklyMissingLocation) => [
                weeklyMissingLocation.locationId,
                weeklyMissingLocation.forecasterId ?? "-",
                weeklyMissingLocation.launchDate,
                weeklyMissingLocation.missingInDoppler ? "open" : "closed",
                weeklyMissingLocation.missingInDoppler ? 0 : 1,
              ]),
            ]}
          />
          <Heading as="h3" size="md" mt="2">
            Missing Days
          </Heading>
          <GPSimpleTable
            rows={[
              ["Location ID", "Forecaster ID", "Day", "Backoffice", "Doppler"],
              ...(selectedWeeklyQA
                ? ((selectedWeeklyQA as WeeklyQa)
                    .dailyMissingLocations as DailyMissingLocation[])
                : ([] as DailyMissingLocation[])
              ).map((dailyMissingLocation) => [
                dailyMissingLocation.locationId,
                dailyMissingLocation.forecasterId ?? "-",
                dailyMissingLocation.date,
                dailyMissingLocation.missingInDoppler ? "open" : "closed",
                dailyMissingLocation.missingInDoppler ? 0 : 1,
              ]),
            ]}
          />
          <Heading as="h3" size="md" mt="2">
            Missing Hours
          </Heading>
          <GPSimpleTable
            rows={[
              ["Location ID", "Forecaster ID", "Time", "Backoffice", "Doppler"],
              ...(selectedWeeklyQA
                ? ((selectedWeeklyQA as WeeklyQa)
                    .hourlyMissingLocations as HourlyMissingLocation[])
                : ([] as HourlyMissingLocation[])
              ).map((hourlyMissingLocation) => [
                hourlyMissingLocation.locationId,
                hourlyMissingLocation.forecasterId ?? "-",
                hourlyMissingLocation.datetime,
                hourlyMissingLocation.missingInDoppler ? "open" : "closed",
                hourlyMissingLocation.missingInDoppler ? 0 : 1,
              ]),
            ]}
          />
        </Box>
      </Grid>
      <Button
        onClick={() => {
          void handelApproveAndReleaseClick();
        }}
        isDisabled={!selectedDopplerRun || isLoadSelectedDopplerRunLoading}
      >
        {isLoadSelectedDopplerRunLoading
          ? "Processing..."
          : "Approve & Release for All Countries"}
      </Button>
      <span
        id="currently-active-doppler-run-text"
        className={styles.currentlyActiveDopplerRunText}
      >
        Currently active Doppler Run:&nbsp;
        {currentlyActiveDopplerRunDate?.currentlyActiveDopplerRunDate ?? "None"}
      </span>
    </Box>
  );
};
