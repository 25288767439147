export const downloadUrl = function (url: string, filename?: string) {
  const link = document.createElement("a");

  link.setAttribute("href", url);

  // Need this for Cypress to work: https://github.com/cypress-io/cypress/issues/14857#issuecomment-1028094025
  link.setAttribute("target", "_blank");

  if (filename) {
    link.setAttribute("download", filename);
  }
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
