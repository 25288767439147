import { Box, Flex, FormControl, FormHelperText } from "@chakra-ui/react";
import { useState } from "react";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import { getISODateOfNextOperationalMonday } from "../../helpers/general/date";
import { UploadOvfModal } from "./Upload/UploadOvfModal";
import styles from "./styles.module.css";

export const UploadOVF = () => {
  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
  }

  return (
    <>
      <Flex justifyContent="flex-start">
        <Box className={styles.box}>
          <WeekSelect
            onChange={handleWeekSelect}
            selectedWeek={selectedWeek}
            showCurrentWeek
            testId="uploadOVF-week-select"
          />
        </Box>
      </Flex>
      <Flex justifyContent="flex-start">
        <Box className={styles.box}>
          <label className={styles.headerText}>Adjusted OVF:</label>
          <Flex justifyContent="flex-start">
            <UploadOvfModal selectedWeek={selectedWeek} />
          </Flex>
          <Flex justifyContent="flex-start">
            <FormControl>
              <FormHelperText>
                Please include all the locations in the adjusted OVF file.
              </FormHelperText>
            </FormControl>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
