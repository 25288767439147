import { useOktaAuth } from "@okta/okta-react";
import OktaSignIn, {
  OktaOAuthError,
  OktaSignInConfig,
} from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";

import config from "./config";

export const OktaSignInWidget = ({
  setCorsErrorModalOpen,
}: {
  setCorsErrorModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const { issuer, clientId, redirectUri, authParams }: OktaSignInConfig =
      config;
    const widget = new OktaSignIn({
      baseUrl: `${issuer as string}`.split("/oauth2")[0],
      clientId,
      issuer,
      redirectUri,
      i18n: {
        en: {
          "primaryauth.title": "Sign in with Okta",
        },
      },
      authParams: authParams,
      useInteractionCodeFlow: false, // Set to true, if your org is OIE enabled
    });

    void widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        oktaAuth.handleLoginRedirect(res.tokens).catch(() => {
          throw new Error("Okta Sign in Error");
        });
      },
      (err) => {
        throw err;
      }
    );

    const isCorsError = (err?: OktaOAuthError): boolean => {
      if (!err) {
        return false;
      }
      return err.name === "AuthApiError" && !err.statusCode;
    };
    widget.on("afterError", (_context, error): void => {
      if (isCorsError(error)) {
        setCorsErrorModalOpen(true);
      }
    });

    // eslint-disable-next-line consistent-return
    return () => widget.remove();
  }, [oktaAuth, setCorsErrorModalOpen]);

  return <div ref={widgetRef} />;
};
export default OktaSignInWidget;
