import { DAY_INDEX_TO_NAME } from "../GPHourlyDataSheet/GPHourlyDataSheet";

export const getMaxValuesInHourlyData = (
  hourlyDataForWeek: Array<Array<number | null>>
): {
  maxValueInDataRows: number;
  maxValueInTotalRow: number;
} => {
  let maxValueInDataRows = 0;
  let maxValueInTotalRow = 0;
  hourlyDataForWeek.forEach((hourlyDataForDay, index) => {
    hourlyDataForDay.forEach((valueForHour) => {
      if (typeof valueForHour === "number") {
        if (
          index < Object.keys(DAY_INDEX_TO_NAME).length - 1 &&
          valueForHour > maxValueInDataRows
        ) {
          maxValueInDataRows = valueForHour;
        } else if (valueForHour > maxValueInTotalRow) {
          maxValueInTotalRow = valueForHour;
        }
      }
    });
  });

  return {
    maxValueInDataRows,
    maxValueInTotalRow,
  };
};
