import { SeasonalityAdjustments } from "../../generated/graphql";
import { getArraySum } from "../../helpers/general/array";
import { OvfQATableRow } from "../../pages/OvfQA/OvfQATable/OvfQATable";
import {
  EventAdjustmentTotal,
  WeeklySummaryTableRow,
} from "../../pages/WeeklyAdjust/WeeklySummaryTable/WeeklySummaryTable";
import { LayerType } from "../constants/LAYER_TYPES";

export function getTagTotal(row: OvfQATableRow, layerType: LayerType) {
  const eventAdjustment = row.eventAdjustmentTotals.find(
    (eventAdjustmentData) => {
      return eventAdjustmentData.tag === layerType;
    }
  );

  return eventAdjustment ? eventAdjustment.total : 0;
}

export function getTagTotalByEvent(
  eventAdjustmentTotals: EventAdjustmentTotal[],
  layerType: LayerType
) {
  const eventAdjustment = eventAdjustmentTotals.find((eventAdjustmentData) => {
    return eventAdjustmentData.tag === layerType;
  });

  return eventAdjustment ? Math.round(eventAdjustment.total) : 0;
}

export function getWeeklySummaryTotals(
  row: WeeklySummaryTableRow
): WeeklySummaryTableRow {
  const totalSeasonalAdjustment =
    row.dopplerForecast +
    row.totalWeeklyAdjustment +
    row.totalDailyAdjustment +
    row.totalHourlyAdjustment;

  const ovfPure =
    totalSeasonalAdjustment +
    row.referralLayer +
    row.promosLayer +
    row.partnerLayer +
    row.otherLayer;

  row.totalSeasonalAdjustment = Math.round(totalSeasonalAdjustment);
  row.ovfPure = Math.round(ovfPure);
  row.finalOvf = Math.round(ovfPure + row.planningLayer);

  return row;
}

export function updateWeeklySummaryRowBySeasonalityAdjustment(
  seasonalityAdjustments: SeasonalityAdjustments,
  weeklySummaryRow: WeeklySummaryTableRow
): WeeklySummaryTableRow {
  weeklySummaryRow.weeklyOverride = seasonalityAdjustments.weeklyOverride;
  weeklySummaryRow.totalWeeklyAdjustment = Math.round(
    getArraySum(seasonalityAdjustments.weeklyLayer as number[][])
  );

  weeklySummaryRow.totalDailyAdjustment = Math.round(
    getArraySum(seasonalityAdjustments.dailyLayer as number[][])
  );
  weeklySummaryRow.totalHourlyAdjustment = Math.round(
    getArraySum(seasonalityAdjustments.hourlyLayer as number[][])
  );

  return getWeeklySummaryTotals(weeklySummaryRow);
}
