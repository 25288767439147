import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface GPPopupProps {
  isOpen: boolean;
  header: string;
  body: React.ReactElement;
  onClose: () => void;
  testId?: string;
}

export const GPPopup: React.FC<GPPopupProps> = (props) => {
  const { isOpen, header, body, onClose } = props;
  return (
    <Modal
      id="gp-popup"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      data-test-id={props.testId ?? "gp-popup"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          data-test-id={props.testId ? `${props.testId}-body` : "gp-popup-body"}
        >
          <div style={{ whiteSpace: "pre-line" }}>{body}</div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            data-test-id={
              props.testId ? `${props.testId}-close` : "gp-popup-close"
            }
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
