import { EventAdjustmentTotal, Maybe } from "../../generated/graphql";
import { LayerType } from "../constants/LAYER_TYPES";

export function getTagTotal(
  locations: Array<null | {
    eventAdjustmentTotals:
      | Maybe<
          Array<
            Maybe<
              {
                __typename: "EventAdjustmentTotal";
              } & Pick<EventAdjustmentTotal, "tag" | "total">
            >
          >
        >
      | undefined;
  }>,
  layerType: LayerType
) {
  return locations.reduce((tagTotal, location) => {
    return (
      tagTotal +
      (location?.eventAdjustmentTotals?.find(
        (eventTag) => eventTag?.tag === layerType
      )?.total ?? 0)
    );
  }, 0);
}
