import { Container } from "@chakra-ui/react";
import {
  DiscreteColorLegend,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { constants } from "../../../helpers/general/constants";

function formatWeeklyData(weeklyData: number[]) {
  return weeklyData.map((dailyData, index) => {
    return {
      x: index,
      y: dailyData,
    };
  });
}

export const DailyChart = (props: {
  threeWeekTrailingData: number[];
  adjustedForecastData: number[];
  rawDopplerData: number[];
}) => {
  const threeWeekTrailingDataInput = formatWeeklyData(
    props.threeWeekTrailingData
  );
  const adjustedForecastDataInput = formatWeeklyData(
    props.adjustedForecastData
  );
  const rawDopplerDataInput = formatWeeklyData(props.rawDopplerData);

  return (
    <Container minWidth="750px">
      <XYPlot
        width={700}
        height={200}
        margin={{ left: 35, right: 10, top: 10, bottom: 25 }}
      >
        <VerticalGridLines tickTotal={7} />
        <HorizontalGridLines />
        <XAxis
          title="Weekday"
          tickFormat={(weekday: number) =>
            `${constants.time.DAYS_OF_THE_WEEK_ABBREVIATIONS[weekday]}`
          }
          tickValues={[...Array(7).keys()]}
        />
        <YAxis title="Daily %" />
        <LineSeries
          key="ThreeWeekTrailingAvg"
          data={threeWeekTrailingDataInput}
          color="orange"
        />
        <LineSeries
          key="AdjustedForecast"
          data={adjustedForecastDataInput}
          color="blue"
        />
        <LineSeries
          key="RawDoppler"
          data={rawDopplerDataInput}
          color="violet"
        />
      </XYPlot>
      <DiscreteColorLegend
        width={500}
        height={60}
        items={[
          { title: "Three Week Trailing Avg", color: "orange" },
          { title: "Adjusted Forecast", color: "blue" },
          { title: "Raw Doppler", color: "violet" },
        ]}
        orientation="horizontal"
      />
    </Container>
  );
};
