import interpolate from "color-interpolate";
import {
  CellRenderer,
  DAY_INDEX_TO_NAME,
} from "../GPHourlyDataSheet/GPHourlyDataSheet";
const colorMap = interpolate(["green", "yellow", "red"]);
const getDataCellBackgroundColor = (
  cellRenderer: CellRenderer,
  maxValueInDataRows: number,
  maxValueInTotalRow: number
) => {
  const { row } = cellRenderer;
  const { value } = cellRenderer.cell;
  let backgroundColor = "rgb(255,255,255)";
  if (cellRenderer.cell.isHourlyDataCell) {
    if (value === 0) {
      return "green";
    }
    if (row < Object.keys(DAY_INDEX_TO_NAME).length) {
      backgroundColor =
        maxValueInDataRows !== 0
          ? colorMap((value as number) / maxValueInDataRows)
          : "green";
    } else {
      backgroundColor =
        maxValueInTotalRow !== 0
          ? colorMap((value as number) / maxValueInTotalRow)
          : "green";
    }
  }

  return backgroundColor;
};

const getDataCellBorder = (
  cellRenderer: CellRenderer,
  hourlyDataOverrides: Array<Array<number | null>> | null | undefined
) => {
  const { row, col } = cellRenderer;
  return cellRenderer.cell.isHourlyDataCell &&
    hourlyDataOverrides &&
    row - 1 < hourlyDataOverrides.length &&
    hourlyDataOverrides[row - 1][col - 2] !== null
    ? "2px solid blue"
    : null;
};

export const getDataCellStyler = (
  maxValueInDataRows: number,
  maxValueInTotalRow: number,
  hourlyDataOverrides: Array<Array<number | null>> | null | undefined,
  dataCellWidth: string | null | undefined
) => {
  return (cellRenderer: CellRenderer) => {
    const styles: React.CSSProperties = {};
    const backgroundColor = getDataCellBackgroundColor(
      cellRenderer,
      maxValueInDataRows,
      maxValueInTotalRow
    );
    const border = getDataCellBorder(cellRenderer, hourlyDataOverrides);

    if (backgroundColor) {
      styles.backgroundColor = backgroundColor;
    }
    if (border) {
      styles.border = border;
    }

    if (dataCellWidth) {
      styles.width = cellRenderer.cell.isHourlyDataCell ? dataCellWidth : "";
    }

    return styles;
  };
};
