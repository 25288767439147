import { Flex, IconButton, Select, Spinner } from "@chakra-ui/react";
import { toNumber, toString } from "lodash";
import { ChangeEventHandler, useState } from "react";
import { FaDownload } from "react-icons/fa";
import {
  GetAdjustedOaHoursRunsForWeekQuery,
  useGetAdjustedOaHoursLazyQuery,
  useGetAdjustedOaHoursRunsForWeekLazyQuery,
} from "../../generated/graphql";

import { formatLongDateString } from "../../helpers/general/date";
import styles from "./styles.module.css";

export const AdjustedOaHoursRunsSelect = (props: {
  onChange: (runId: number | undefined) => void;
  selectedWeek: string;
  triggerRefresh?: number;
  selectedAdjustedOaRunId: number;
  testId?: string;
}) => {
  const [triggerRefreshNumber, setTriggerRefreshNumber] = useState<number>();
  let selectedAdjustedOaRunId = props.selectedAdjustedOaRunId;
  const getAdjustedOaHoursRuns = (
    data?: GetAdjustedOaHoursRunsForWeekQuery | undefined
  ): Array<{ key: string; value: number }> => {
    if (!data) {
      data = adjustedOaRuns;
    }
    if (!data?.getAdjustedOaHoursRunsForWeek) return [];

    return data.getAdjustedOaHoursRunsForWeek.map((adjustedOaRun) => {
      return {
        key: `${adjustedOaRun.id ? toString(adjustedOaRun.id) + " - " : ""}${
          adjustedOaRun.name ? adjustedOaRun.name + " - " : ""
        }${adjustedOaRun.source ? adjustedOaRun.source + " - " : ""}${
          adjustedOaRun.ophRunsId
            ? adjustedOaRun.ophRunsId.toString() + " - "
            : ""
        }${
          adjustedOaRun.createdAt
            ? formatLongDateString(adjustedOaRun.createdAt)
            : ""
        }`,
        value: adjustedOaRun.id,
      };
    });
  };

  const [
    getAdjustedDriverHoursRunsForWeek,
    { data: adjustedOaRuns, loading: adjustedOaRunsLoading },
  ] = useGetAdjustedOaHoursRunsForWeekLazyQuery({
    variables: {
      operationalWeekStartDate: props.selectedWeek,
    },
    onCompleted: (data) => {
      if (data?.getAdjustedOaHoursRunsForWeek) {
        const options = getAdjustedOaHoursRuns(data);
        props.onChange(options[0]?.value);
      } else if (!data?.getAdjustedOaHoursRunsForWeek) {
        props.onChange(undefined);
      }
    },
  });

  if (triggerRefreshNumber !== props.triggerRefresh) {
    if (props.selectedWeek) {
      void getAdjustedDriverHoursRunsForWeek();
    }
    setTriggerRefreshNumber(props.triggerRefresh);
  }

  const [getAdjustedOaData, { loading: adjustedDHDataLoading }] =
    useGetAdjustedOaHoursLazyQuery({
      variables: {
        adjustedOaHoursRunId: selectedAdjustedOaRunId,
      },
      onCompleted: (data) => {
        if (data) {
          console.log(data);
        }
      },
    });

  const selectHandler: ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const runId = toNumber(event.currentTarget.value);
    selectedAdjustedOaRunId = runId;
    return props.onChange(runId);
  };

  return (
    <section className="adjuted-oa-runs-select">
      <label htmlFor="select-week" className={styles.adjutedDhRunsSelectLabel}>
        Existing OA Hours
      </label>
      {adjustedOaRunsLoading ? (
        <Spinner />
      ) : (
        <Flex>
          {!adjustedOaRuns?.getAdjustedOaHoursRunsForWeek?.length ? (
            <p>No OA Hours, please run GoSked or Upload</p>
          ) : (
            <>
              <Select
                id="select-adjusted-dh-runs"
                variant="outline"
                onChange={selectHandler}
                value={props.selectedAdjustedOaRunId}
                data-test-id={props.testId ?? "select-adjusted-dh-runs"}
              >
                {getAdjustedOaHoursRuns().map((item) => {
                  return (
                    <option key={item.key} value={item.value}>
                      {item.key}
                    </option>
                  );
                })}
              </Select>
              <IconButton
                style={{ marginLeft: 5, display: "none" }}
                aria-label="Download selected OA hours"
                icon={<FaDownload />}
                isDisabled={
                  !props.selectedAdjustedOaRunId ||
                  props.selectedAdjustedOaRunId < 0
                }
                isLoading={adjustedDHDataLoading}
                onClick={() => {
                  if (
                    !props.selectedAdjustedOaRunId ||
                    props.selectedAdjustedOaRunId < 0
                  )
                    return;
                  void getAdjustedOaData({
                    variables: {
                      adjustedOaHoursRunId: selectedAdjustedOaRunId,
                    },
                  });
                }}
                data-test-id="oaqa-adjusted-oa-hours-runs-download"
              ></IconButton>
            </>
          )}
        </Flex>
      )}
    </section>
  );
};
