import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import { getISODateOfNextOperationalMonday } from "../../helpers/general/date";
import { LocationODHTargetTable } from "./Table/LocationODHTargetTable";
import { UploadTargetsModal } from "./Upload/UploadTargetsModal";

export const LocationODHTarget = () => {
  const [forceRefresh, setForceRefresh] = useState(false);

  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
  }

  return (
    <>
      <Flex justifyContent="space-around" p={8}>
        <WeekSelect
          onChange={handleWeekSelect}
          selectedWeek={selectedWeek}
          key={selectedWeek}
          showCurrentWeek
        />
        <UploadTargetsModal
          setForceRefresh={setForceRefresh}
          selectedWeek={selectedWeek}
        />
      </Flex>
      <Flex>
        <LocationODHTargetTable
          selectedWeek={selectedWeek}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
        ></LocationODHTargetTable>
      </Flex>
    </>
  );
};
