import * as XLSX from "xlsx";

export interface ColumnSettingValue {
  type: string;
  format?: string;
}
export interface ColumnSetting {
  [key: string]: ColumnSettingValue;
}

export const EXCEL_DATE_FORMAT = "yyyy-mm-dd";

export const exportXlsxFile = (data: unknown[], fileName: string) => {
  const sheet = XLSX.utils.json_to_sheet(data);
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, "sheet1");
  /* generate XLSX file and send to client */
  XLSX.writeFileXLSX(book, `${fileName}.xlsx`);
};

export const exportXlsxFileWithStyle = (
  worksheets: Array<{
    data: unknown[];
    sheetName: string;
    columnsSetting: ColumnSetting;
  }>,
  fileName: string,
  rowStyleCount = 100
) => {
  const book = XLSX.utils.book_new();

  for (const worksheet of worksheets) {
    const { data, sheetName, columnsSetting } = worksheet;
    const sheet = XLSX.utils.json_to_sheet(data, {
      cellStyles: true,
      cellDates: true,
    });

    const colWidth = Object.keys(data[0] as object).map((key) => ({
      wch: Math.max(key.length, 12),
    }));
    sheet["!cols"] = colWidth;

    const rowLength = data.length;
    const colLength = data[0] ? Object.keys(data[0] as object).length : 0;
    const emptyArray = Array.from(
      { length: Math.max(data.length, rowStyleCount) },
      () => Array.from({ length: colLength }, () => [""])
    );
    XLSX.utils.sheet_add_aoa(sheet, emptyArray, {
      origin: { r: rowLength + 1, c: 0 },
      sheetStubs: true,
    });
    const range = {
      start: { r: 1, c: 0 },
      end: { r: Math.max(data.length, rowStyleCount), c: colLength - 1 },
    };

    for (let R = range.start.r; R <= range.end.r; ++R) {
      for (let C = range.start.c; C <= range.end.c; ++C) {
        let cell: XLSX.CellObject =
          sheet[XLSX.utils.encode_cell({ r: R, c: C })];
        const column = XLSX.utils.encode_col(C);
        const columnHeader =
          (sheet[`${column}1`] as XLSX.CellObject)?.v?.toString() ?? "";
        cell = configureCell(cell, columnsSetting[columnHeader]);
      }
    }

    XLSX.utils.book_append_sheet(book, sheet, sheetName);
  }
  /* generate XLSX file and send to client */
  XLSX.writeFileXLSX(book, `${fileName}.xlsx`, { cellStyles: true });
};

export const configureCell = (
  cell: XLSX.CellObject,
  columnSetting?: ColumnSettingValue
) => {
  const result = cell;
  if (!cell || !columnSetting) {
    return result;
  }
  if (columnSetting.type.toLowerCase() === "text") {
    result.t = "s";
    result.z = "@";
  } else if (columnSetting.type.toLowerCase() === "date") {
    XLSX.utils.cell_set_number_format(
      result,
      columnSetting.format ?? EXCEL_DATE_FORMAT
    );
  }
  return result;
};
