/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Input } from "@chakra-ui/input";
import React from "react";
import { CellProps } from "react-table";

// Create an editable cell renderer
export function WeeklySummaryTableEditableCell<T extends object>({
  value: initialValue,
  updateCurrentData,
  locationId,
  handleEnterWeeklyAdjustment,
  upsertSeasonalAdjustmentLoading,
  operationalWeekStartDate,
  ...props
}: CellProps<T>) {
  const [value, setValue] = React.useState(initialValue ? initialValue : "");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = parseFloat((event.target as HTMLInputElement).value);

    if (!upsertSeasonalAdjustmentLoading && currentValue !== initialValue) {
      void handleEnterWeeklyAdjustment({
        locationId,
        forecasterId: null,
        adjustmentType: "weekly",
        operationalWeekStartDate,
        dateString: operationalWeekStartDate,
        value: currentValue || null,
      });
    }

    if (updateCurrentData) {
      updateCurrentData({ column: props?.cell?.column?.id, value, props });
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = parseFloat((event.target as HTMLInputElement).value);
    event.persist();

    if (
      event.key === "Enter" &&
      !upsertSeasonalAdjustmentLoading &&
      currentValue !== initialValue
    ) {
      void handleEnterWeeklyAdjustment({
        locationId,
        forecasterId: null,
        adjustmentType: "weekly",
        operationalWeekStartDate,
        dateString: operationalWeekStartDate,
        value: currentValue || null,
      });
    }
  };

  React.useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  return (
    <Input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
    />
  );
}
