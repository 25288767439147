import { Container } from "@chakra-ui/react";
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineMarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import { constants } from "../../../helpers/general/constants";

function formatWeeklyData(weeklyData: number[]) {
  return weeklyData
    .map((dailyData, index) => {
      return {
        x: index,
        y: dailyData,
      };
    })
    .filter((dailyData) => dailyData.y);
}

export const DailyChart = (props: {
  actualsData: number[];
  ovfPublishedData: number[];
  ovfReforecastData: number[];
  dopplerRunActiveData: number[];
  dopplerRunPublishedData: number[];
}) => {
  const actualsDataInput = formatWeeklyData(props.actualsData);
  const ovfPublishedDataInput = formatWeeklyData(props.ovfPublishedData);
  const ovfReforecastDataInput = formatWeeklyData(props.ovfReforecastData);
  const dopplerRunActiveDateInput = formatWeeklyData(
    props.dopplerRunActiveData
  );
  const dopplerRunPublishedDataInput = formatWeeklyData(
    props.dopplerRunPublishedData
  );

  return (
    <Container maxW="container.xl" alignContent="center" width="100%">
      <FlexibleWidthXYPlot
        align="center"
        height={200}
        margin={{ left: 70, right: 10, top: 10, bottom: 25 }}
      >
        <VerticalGridLines tickTotal={7} />
        <HorizontalGridLines />
        <XAxis
          title="Weekday"
          tickFormat={(weekday: number) =>
            `${constants.time.DAYS_OF_THE_WEEK_ABBREVIATIONS[weekday]}`
          }
          tickValues={[...Array(7).keys()]}
        />
        <YAxis title="" />
        <LineMarkSeries key="Actuals" data={actualsDataInput} color="black" />
        <LineMarkSeries
          key="OvfPublished"
          data={ovfPublishedDataInput}
          color="red"
          strokeStyle="dashed"
        />
        <LineMarkSeries
          key="OvfReforecast"
          data={ovfReforecastDataInput}
          color="red"
        />
        <LineMarkSeries
          key="DopplerPublished"
          data={dopplerRunPublishedDataInput}
          color="blue"
          strokeStyle="dashed"
        />
        <LineMarkSeries
          key="DopplerActive"
          data={dopplerRunActiveDateInput}
          color="blue"
        />
      </FlexibleWidthXYPlot>
      <DiscreteColorLegend
        width={700}
        height={60}
        items={[
          { title: "Actuals", color: "black" },
          { title: "OVF Published", color: "red", strokeStyle: "dashed" },
          { title: "OVF Reforecast", color: "red" },
          {
            title: "Doppler Run Published",
            color: "blue",
            strokeStyle: "dashed",
          },
          { title: "Doppler Run Active", color: "blue" },
        ]}
        orientation="horizontal"
      />
    </Container>
  );
};
