import { GetEventAdjustmentsForTableQuery } from "../../../generated/graphql";
import { exportCSVFile } from "../../../helpers/csv/exportToCSV";
import { getNumbersBetween } from "../../../helpers/general/getNumbersBetween";
import { transformEventAdjustmentData } from "../../../helpers/integration/transformEventAdjustmentData";
import { getEventAdjustmentLayersDataRows } from "./getEventAdjustmentLayersDataRows";

export const exportEventAdjustmentsCsv = function (
  eventAdjustmentsForDownloadResult: GetEventAdjustmentsForTableQuery,
  selectedWeek: string,
  selectedDownloadLayerType: string | undefined,
  selectedLayer: string | undefined
) {
  const headers = [
    "layer",
    "date",
    "location_id",
    "location_name",
    "day",
    ...getNumbersBetween(5, 23).map((number) => {
      return number.toString();
    }),
    ...getNumbersBetween(0, 4).map((number) => {
      return number.toString();
    }),
    "total",
  ];

  const csvDataRows = transformEventAdjustmentData(
    getEventAdjustmentLayersDataRows(eventAdjustmentsForDownloadResult)
  );

  exportCSVFile(
    csvDataRows,
    headers,
    `${
      selectedDownloadLayerType ?? selectedLayer ?? ""
    }Adjustment${selectedWeek}`
  );
};
