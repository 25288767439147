/* eslint-disable @typescript-eslint/no-inferrable-types */
import { downloadUrl } from "../url/downloadUrl";
import { convertToCSV } from "./convertToCSV";

export function exportCSVFile(
  data: any,
  headers?: string[],
  fileTitle: string = "exportedCSV",
  displayHeaders: boolean = true
) {
  const csv = convertToCSV(data, headers, displayHeaders);

  const exportedFilename = fileTitle + ".csv";

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  const url = URL.createObjectURL(blob);
  downloadUrl(url, exportedFilename);
}
