import { OktaSignInConfig } from "@okta/okta-signin-widget";
import {
  DailyHourlyAdjust,
  DopplerQA,
  EventAdjustmentUpload,
  ForecastersUpdate,
  GlobalAdjust,
  Healthz,
  LocationODHTarget,
  MidweekReforecast,
  MultiweekForecast,
  OaQA,
  OaShiftLeaderShiftRequests,
  OvfQA,
  Profile,
  ShiftQA,
  UploadOVF,
  WeeklyAdjust,
} from "../pages";

const oktaDisabled = process.env.REACT_APP_DISABLE_OKTA === "true";

export interface GPRoute {
  path: string;
  component: React.ReactNode;
  insecure: boolean;
}

export interface OktaConfig {
  issuer: string;
  baseUrl?: string;
  clientId: string;
  redirectUri: string;
  scopes?: string[];
  pkce?: boolean;
  useInteractionCode?: boolean;
}

export const oktaAuthConfig: OktaSignInConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER ?? "",
  clientId: process.env.REACT_APP_OKTA_AUTH_CLIENT_ID ?? "",
  redirectUri: window.location.origin + "/login/callback",
  authParams: {
    scopes: ["openid", "profile", "email"],
    issuer: process.env.REACT_APP_OKTA_ISSUER ?? "",
  },
  baseUrl: "",
  i18n: undefined,
};

export const oktaSignInConfig: OktaSignInConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER ?? "",
  baseUrl: process.env.REACT_APP_OKTA_DOMAIN ?? "",
  clientId: process.env.REACT_APP_OKTA_AUTH_CLIENT_ID ?? "",
  redirectUri: window.location.origin + "/login/callback",
  authParams: {
    scopes: ["openid", "profile", "email"],
    issuer: process.env.REACT_APP_OKTA_ISSUER ?? "",
  },
  i18n: undefined,
};

const CURRENT_ROUTES: GPRoute[] = [
  { path: "/healthz", component: <Healthz />, insecure: true },
  { path: "/dopplerQA", component: <DopplerQA />, insecure: oktaDisabled },
  { path: "/shiftQA", component: <ShiftQA />, insecure: oktaDisabled },
  { path: "/oaQA", component: <OaQA />, insecure: oktaDisabled },
  {
    path: "/oaShiftLeaderShiftRequests",
    component: <OaShiftLeaderShiftRequests />,
    insecure: oktaDisabled,
  },
  {
    path: "/forecastersUpdate",
    component: <ForecastersUpdate />,
    insecure: oktaDisabled,
  },
  {
    path: "/weeklyAdjust",
    component: <WeeklyAdjust />,
    insecure: oktaDisabled,
  },
  {
    path: "/dailyHourlyAdjust",
    component: <DailyHourlyAdjust />,
    insecure: oktaDisabled,
  },
  {
    path: "/globalAdjust",
    component: <GlobalAdjust />,
    insecure: oktaDisabled,
  },
  {
    path: "/locationODHTarget",
    component: <LocationODHTarget />,
    insecure: oktaDisabled,
  },
  { path: "/OvfQA", component: <OvfQA />, insecure: oktaDisabled },
  { path: "/uploadOVF", component: <UploadOVF />, insecure: oktaDisabled },
  {
    path: "/eventAdjustmentUpload",
    component: <EventAdjustmentUpload />,
    insecure: oktaDisabled,
  },
  {
    path: "/midweekReforecast",
    component: <MidweekReforecast />,
    insecure: oktaDisabled,
  },
  {
    path: "/multiWeekForecast",
    component: <MultiweekForecast />,
    insecure: oktaDisabled,
  },
];

if (!oktaDisabled) {
  CURRENT_ROUTES.push({
    path: "/profile",
    component: <Profile />,
    insecure: oktaDisabled,
  });
}

export { CURRENT_ROUTES };

export default oktaSignInConfig;
