import { Button } from "@chakra-ui/react";
import { useGetShiftsHourlyQaCsvUrlLazyQuery } from "../../../generated/graphql";
import { downloadUrl } from "../../../helpers/url/downloadUrl";

interface LocationsByHourMenuProps {
  operationalWeekStartDate: string;
  adjustedDHRunId: number;
}

export const LocationsByHourMenu: React.FC<LocationsByHourMenuProps> = ({
  operationalWeekStartDate,
  adjustedDHRunId,
}) => {
  const [
    getShiftsHourlyCsvUrl,
    { loading: getShiftsHourlyQaCsvUrlLazyQueryLoading },
  ] = useGetShiftsHourlyQaCsvUrlLazyQuery({
    onCompleted: (data) => {
      downloadUrl(data.shiftsHourlyQaCsvUrl);
    },
  });
  return (
    <section>
      <label
        style={{
          fontWeight: 700,
          marginTop: 10,
          marginBottom: 10,
          display: "block",
        }}
      >
        Locations by Hour
      </label>
      <Button
        onClick={() => {
          void getShiftsHourlyCsvUrl({
            variables: {
              operationalWeekStartDate,
              adjustedDHRunId,
            },
          });
        }}
        isLoading={getShiftsHourlyQaCsvUrlLazyQueryLoading}
        loadingText="Downloading"
        data-test-id="shiftqa-download-dp-csv"
      >
        Download CSV
      </Button>
    </section>
  );
};
