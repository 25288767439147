/* eslint-disable sonarjs/no-duplicate-string */
import { GPSimpleTable } from "../../../components/GPSimpleTable/GPSimpleTable";
import { GetMultiweekForecastQuery } from "../../../generated/graphql";
import { getNumbersBetween } from "../../../helpers/general/getNumbersBetween";
import { formatFloatValues } from "./helpers/formatFloatValues";

export const WeeklyDataTable = (props: {
  multiweekForecast: GetMultiweekForecastQuery | undefined;
  historicalWeeksToLookBack: number;
  weeksToInclude: number;
  weeksOutToForecast: number;
}) => {
  const {
    historicalWeeksToLookBack,
    multiweekForecast,
    weeksOutToForecast,
    weeksToInclude,
  } = props;
  return (
    <GPSimpleTable
      testId="multiweek-forecast-table"
      rows={[
        [
          "",
          ...getNumbersBetween(historicalWeeksToLookBack * -1, -1).map(
            (week) => `wk ${week}`
          ),
          ...getNumbersBetween(0, weeksToInclude).map((week) => `wk ${week}`),
        ],
        [
          "Last year actuals",
          ...(multiweekForecast?.multiweekForecast?.lastYearActualsByWeek.map(
            (value) => value ?? ""
          ) ?? []),
        ],
        [
          "WoW growth rate",
          ...formatFloatValues(
            multiweekForecast?.multiweekForecast
              ?.lastYearActualsByWeekGrowthRates ?? []
          ),
        ],
        [
          "This year actuals",
          ...(multiweekForecast?.multiweekForecast?.thisYearActualsByWeek
            .map((value) => value ?? "")
            .concat(new Array(weeksOutToForecast + 1).map((_value) => "")) ??
            []),
        ],
        [
          "WoW growth rate",
          ...formatFloatValues(
            multiweekForecast?.multiweekForecast
              ?.thisYearActualsByWeekGrowthRates ?? []
          ),
        ],
        [
          "Published OVF",
          ...formatFloatValues(
            multiweekForecast?.multiweekForecast?.ovfPublishedByWeek.slice(
              0,
              historicalWeeksToLookBack
            ) ?? []
          ),
          "",
          ...formatFloatValues(
            multiweekForecast?.multiweekForecast?.ovfPublishedByWeek.slice(
              historicalWeeksToLookBack
            ) ?? []
          ),
        ],
        [
          "WoW growth rate",
          ...formatFloatValues(
            multiweekForecast?.multiweekForecast?.ovfPublishedByWeekGrowthRates.slice(
              0,
              historicalWeeksToLookBack
            ) ?? []
          ),
          "",
          "",
          ...formatFloatValues(
            multiweekForecast?.multiweekForecast?.ovfPublishedByWeekGrowthRates.slice(
              historicalWeeksToLookBack + 1
            ) ?? []
          ),
        ],
        [
          "New OVF",
          ...getNumbersBetween(historicalWeeksToLookBack * -1, 0).map(() => ""),
          ...(multiweekForecast?.multiweekForecast?.adjustmentsWeeklyTotals.map(
            (value) => value ?? ""
          ) ?? []),
        ],
        [
          "WoW growth rate",
          ...getNumbersBetween(historicalWeeksToLookBack * -1, 0).map(() => ""),
          ...formatFloatValues(
            multiweekForecast?.multiweekForecast
              ?.adjustmentsWeeklyTotalsGrowthRates ?? []
          ),
        ],
        [
          "Active Doppler",
          ...getNumbersBetween(historicalWeeksToLookBack * -1, 0).map(() => ""),
          ...(multiweekForecast?.multiweekForecast?.activeDopplerWeeklyTotals.map(
            (value) => value ?? ""
          ) ?? []),
        ],
      ]}
    />
  );
};
