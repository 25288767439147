import { useUploadOaHoursMutation } from "../../generated/graphql";
import { TEXT } from "../../helpers/constants/TEXT";
import {
  ColumnSetting,
  ColumnSettingValue,
  exportXlsxFileWithStyle,
} from "../../helpers/excel/exportXlsxFile";
import { GPUploadPopup } from "../GPUploadPopup/GPUploadPopup";

export interface UploadOaHoursProps {
  setForceRefresh?: (forceRefresh: boolean) => void;
  onSuccess?: () => void;
  selectedWeek?: string;
  testId?: string;
}

export const UploadOaHours: React.FC<UploadOaHoursProps> = ({
  setForceRefresh,
  selectedWeek,
  testId,
  onSuccess,
}) => {
  const mutationParameters = {
    selectedWeek,
  };

  const oaHoursColumns: {
    [key: string]: {
      header: string;
      example: string | number;
      setting: ColumnSettingValue;
    };
  } = {
    locationName: {
      header: "Location Name",
      example: "ATH_Athens_87",
      setting: {
        type: "Text",
      },
    },
    locationId: {
      header: "Location ID(*required)",
      example: 87,
      setting: {
        type: "Text",
      },
    },
    day: {
      header: "Day",
      example: "Monday",
      setting: {
        type: "Text",
      },
    },
    opsDate: {
      header: "Ops Date(*required)",
      example: "2023-10-04",
      setting: {
        type: "Date",
      },
    },
    hours: {
      header: "Hour(*required)",
      example: "01:00:00",
      setting: {
        type: "Text",
      },
    },
    driverReAssignments: {
      header: "Driver Re-Assignments",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    outboundMFC: {
      header: "Outbound MFC",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    inboundHours: {
      header: "Inbound Hours",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    trainingHours: {
      header: "Training Hours",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    hourlyRetail: {
      header: "Hourly Retail",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    phillyTwoStop: {
      header: "Philly Two Stop",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    totalHourlyRetailHours: {
      header: "Total Hourly Retail Hours",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    icqaHours: {
      header: "ICQA Hours",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    totalHoursNeeded: {
      header: "Total Hours Needed",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    attendance: {
      header: "Attendance(%)",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    totalHours: {
      header: "Total Labor Hours(*required)",
      example: 1,
      setting: {
        type: "Text",
      },
    },
  };

  const ophColumns: {
    [key: string]: {
      header: string;
      example: string | number;
      setting: ColumnSettingValue;
    };
  } = {
    locationName: {
      header: "Location Name",
      example: "ATH_Athens_87",
      setting: {
        type: "Text",
      },
    },
    locationId: {
      header: "Location ID(*required)",
      example: 87,
      setting: {
        type: "Text",
      },
    },
    totalHours: {
      header: "Total Labor Hours(*required)",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    newTargetOPH: {
      header: "New Target OPH(*required)",
      example: 1,
      setting: {
        type: "Text",
      },
    },
    slTotal: {
      header: "SL Total(*required)",
      example: 1,
      setting: {
        type: "Text",
      },
    },
  };

  const downloadTemplate = () => {
    const oaLaborHoursData: { [key: string]: string | number } = {};
    const oaLaborHoursColumnsSetting: ColumnSetting = {};
    const ophData: { [key: string]: string | number } = {};
    const ophColumnsSetting: ColumnSetting = {};
    for (const key in oaHoursColumns) {
      if (Object.prototype.hasOwnProperty.call(oaHoursColumns, key)) {
        const column = oaHoursColumns[key];
        oaLaborHoursData[column.header] = column.example;
        oaLaborHoursColumnsSetting[column.header] = column.setting;
      }
    }

    for (const key in ophColumns) {
      if (Object.prototype.hasOwnProperty.call(ophColumns, key)) {
        const column = ophColumns[key];
        ophData[column.header] = column.example;
        ophColumnsSetting[column.header] = column.setting;
      }
    }

    exportXlsxFileWithStyle(
      [
        {
          data: [oaLaborHoursData],
          sheetName: `Hourly Plan Calculation`,
          columnsSetting: oaLaborHoursColumnsSetting,
        },
        {
          data: [ophData],
          sheetName: `OPH`,
          columnsSetting: ophColumnsSetting,
        },
      ],
      "OA Labor Hours Template",
      1
    );
  };

  return (
    <GPUploadPopup
      onSuccess={onSuccess}
      setUploadComplete={setForceRefresh}
      modelTitle={TEXT.UPLOAD_OA_LABOR_HOURS}
      modelHeader={TEXT.HEADER_UPLOAD_OA_LABOR_HOURS}
      modelBody={
        <>
          <p>
            Upload an excel file of OA Labor Hours. Download a simple{" "}
            <span
              style={{ color: "rgb(25, 118, 210)", cursor: "pointer" }}
              onClick={downloadTemplate}
            >
              EXCEL TEMPLATE
            </span>{" "}
            to see an example of the format required. Fill in the template, and
            upload it to the app
          </p>
          <p>
            <b>Column Name is case sensitive</b>
          </p>
        </>
      }
      uploadMutation={useUploadOaHoursMutation}
      uploadMutationProps={mutationParameters}
      allowDatesOutsideSelectedWeekCheckbox={false}
      uploadButtonWidth={350}
      allowTypeName
      excel
      testId={testId}
    ></GPUploadPopup>
  );
};
