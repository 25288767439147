import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import fragmentMatcher from "../generated/fragment-matcher.json";
import { TypedTypePolicies } from "../generated/graphql";

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      locationTargets: relayStylePagination(),
    },
  },
};

export const cache = new InMemoryCache({
  typePolicies,
  possibleTypes: fragmentMatcher.possibleTypes,
});
