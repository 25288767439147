import {
  Box,
  Button,
  Container,
  Flex,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import * as react from "react";
import React from "react";
import { useState } from "react";
import { CountrySelect } from "../../components/CountrySelect/CountrySelect";
import { GPPopup } from "../../components/GPPopup/GPPopup";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { SelectForecastLocationsMenu } from "../../components/SelectForecastLocationsMenu/SelectForecastLocationsMenu";
import {
  useCreateOrEditReforecastLayerMutation,
  useGetMidWeekReforecastQuery,
  useGetMostRecentOvfSubmitDateQuery,
  useSubmitMidweekReforecastAdjustedOvfMutation,
} from "../../generated/graphql";
import { constants } from "../../helpers/general/constants";
import {
  formatLongDateString,
  getISODateOfCurrentOperationalMonday,
  getISODatesofCurrentOperationalWeek,
} from "../../helpers/general/date";
import { getGraphQLErrorsAsHumanReadableStrings } from "../../helpers/graphql/getGraphQLErrorsAsHumanReadableStrings";
import { DailyChart } from "./Charts/DailyChart";
import {
  MidweekReforecastSummaryTable,
  MidweekReforecastTableRow,
} from "./MidweekReforecastSummaryTable/MidweekReforecastTable";
import styles from "./styles.module.css";

export const MidweekReforecast = () => {
  const [popupHeader, setPopupHeader] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string | React.ReactElement>(
    ""
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedCountry, setSelectedCountry] = react.useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [selectedMetroRegion, setSelectedMetroRegion] =
    react.useState<string>();

  const operationalWeekStartDate = getISODateOfCurrentOperationalMonday();

  const {
    data: midweekReforecast,
    error: getMidweekReforecastError,
    loading: isGetMidweekReforecastLoading,
  } = useGetMidWeekReforecastQuery({
    variables: {
      locationId: parseInt(selectedLocation ?? "", 10),
      metroRegion: selectedMetroRegion === "All" ? "" : selectedMetroRegion,
      countryCode: selectedCountry,
      operationalWeekStartDate: operationalWeekStartDate,
    },
    context: {
      skipDefaultErrorHandling: true,
    },
    skip: !selectedCountry || !selectedMetroRegion || !selectedLocation,
  });

  const { data: lastSubmitData, loading: mostRecentLoading } =
    useGetMostRecentOvfSubmitDateQuery({
      variables: {
        selectedWeek: operationalWeekStartDate,
      },
    });

  const actuals = midweekReforecast?.midweekReforecast?.actuals
    ? (midweekReforecast?.midweekReforecast?.actuals as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const projection = midweekReforecast?.midweekReforecast?.projection
    ? (midweekReforecast?.midweekReforecast?.projection as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const ovfPublished = midweekReforecast?.midweekReforecast?.ovfPublished
    ? (midweekReforecast?.midweekReforecast?.ovfPublished as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const ovfPublishedVsActualsOrProjection = midweekReforecast?.midweekReforecast
    ?.ovfPublishedVsActualsOrProjection
    ? (midweekReforecast?.midweekReforecast
        ?.ovfPublishedVsActualsOrProjection as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const ovfReforecast = midweekReforecast?.midweekReforecast?.ovfReforecast
    ? (midweekReforecast?.midweekReforecast?.ovfReforecast as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const ovfReforecastVsProjection = midweekReforecast?.midweekReforecast
    ?.ovfReforecastVsProjection
    ? (midweekReforecast?.midweekReforecast
        ?.ovfReforecastVsProjection as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const seasonalAdjustments = midweekReforecast?.midweekReforecast
    ?.seasonalAdjustments
    ? (midweekReforecast?.midweekReforecast?.seasonalAdjustments as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const referrals = midweekReforecast?.midweekReforecast?.referrals
    ? (midweekReforecast?.midweekReforecast?.referrals as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const promos = midweekReforecast?.midweekReforecast?.promos
    ? (midweekReforecast?.midweekReforecast?.promos as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const partners = midweekReforecast?.midweekReforecast?.partners
    ? (midweekReforecast?.midweekReforecast?.partners as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const other = midweekReforecast?.midweekReforecast?.other
    ? (midweekReforecast?.midweekReforecast?.other as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const dailyReforecastAdjustments = midweekReforecast?.midweekReforecast
    ?.dailyReforecastAdjustment
    ? (midweekReforecast?.midweekReforecast
        ?.dailyReforecastAdjustment as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const totalAdjustments = midweekReforecast?.midweekReforecast
    ?.totalAdjustments
    ? (midweekReforecast?.midweekReforecast?.totalAdjustments as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const dopplerRunActive = midweekReforecast?.midweekReforecast
    ?.dopplerRunActive
    ? (midweekReforecast?.midweekReforecast?.dopplerRunActive as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const dopplerVsDoppler = midweekReforecast?.midweekReforecast
    ?.dopplerVsDoppler
    ? (midweekReforecast?.midweekReforecast?.dopplerVsDoppler as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const dopplerRunPublished = midweekReforecast?.midweekReforecast
    ?.dopplerRunPublished
    ? (midweekReforecast?.midweekReforecast.dopplerRunPublished as number[])
    : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  interface ReforecastRow {
    header: string;
    data: number[];
    displayAsPercentage: boolean;
    decimalPlaces: number;
    displayTotal: boolean;
    customTotal?: number[];
    isEditable: boolean;
  }

  const weeklyReforecastRows: ReforecastRow[] = [
    {
      header: "Actuals",
      data: actuals,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Projection",
      data: projection,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: true,
      customTotal: actuals.concat(projection),
      isEditable: false,
    },
    {
      header: "OVF Published",
      data: ovfPublished,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: true,
      isEditable: false,
    },
    {
      header: "OVF Published vs Actuals and Projection",
      data: ovfPublishedVsActualsOrProjection,
      displayAsPercentage: true,
      decimalPlaces: 1,
      displayTotal: true,
      isEditable: false,
    },
    {
      header: "OVF Reforecast (Active Doppler + Adjustments)",
      data: ovfReforecast,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: true,
      isEditable: false,
    },
    {
      header: "OVF Reforecast vs Projection",
      data: ovfReforecastVsProjection,
      displayAsPercentage: true,
      decimalPlaces: 1,
      displayTotal: true,
      isEditable: false,
    },
    {
      header: "Seasonal Adjustments",
      data: seasonalAdjustments,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Referrals",
      data: referrals,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Promos",
      data: promos,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Partners",
      data: partners,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Other",
      data: other,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Daily Reforecast Adjustments",
      data: dailyReforecastAdjustments,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: true,
    },
    {
      header: "Total Adjustments",
      data: totalAdjustments,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Doppler Run Active",
      data: dopplerRunActive,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Doppler Active vs Published ",
      data: dopplerVsDoppler,
      displayAsPercentage: true,
      decimalPlaces: 1,
      displayTotal: false,
      isEditable: false,
    },
    {
      header: "Doppler Run Published",
      data: dopplerRunPublished,
      displayAsPercentage: false,
      decimalPlaces: 0,
      displayTotal: true,
      isEditable: false,
    },
  ];

  const weeklyReforecastTableData: MidweekReforecastTableRow[] =
    weeklyReforecastRows.map((row, index) => {
      return {
        id: index,
        dataSet: row.header,
        displayAsPercentage: row.displayAsPercentage,
        decimalPlaces: row.decimalPlaces,
        displayTotal: row.displayTotal,
        isEditable: row.isEditable,
        Monday: row.data[0],
        Tuesday: row.data[1],
        Wednesday: row.data[2],
        Thursday: row.data[3],
        Friday: row.data[4],
        Saturday: row.data[5],
        Sunday: row.data[6],
        total: row.customTotal
          ? row.customTotal.flat().reduce((sum, current) => sum + current, 0)
          : row.data.reduce((sum, current) => sum + current, 0),
      };
    });

  const [
    createOrEditReforecastLayer,
    { loading: isCreateOrEditReforecastLayerLoading },
  ] = useCreateOrEditReforecastLayerMutation({
    refetchQueries: ["GetMidWeekReforecast"],
  });

  async function handleCreateOrEditReforecastLayer(createOrEditReforecastLayerVars: {
    locationIds?: [number];
    metroRegion?: string;
    countryCode?: string;
    date: string;
    orderAdjustment: number;
  }) {
    if (
      !createOrEditReforecastLayerVars.locationIds &&
      !createOrEditReforecastLayerVars.metroRegion &&
      !createOrEditReforecastLayerVars.countryCode
    )
      return;

    const orderAdjustment = createOrEditReforecastLayerVars.orderAdjustment;
    if (orderAdjustment !== null && isNaN(orderAdjustment)) {
      setPopupHeader("Error");
      setPopupMessage("Invalid order adjustment. It must be a number.");
      onOpen();
      return;
    }

    await createOrEditReforecastLayer({
      variables: createOrEditReforecastLayerVars,
    });
  }

  const [submitCurrentWeekOvf, { loading: loadingOvfSubmit }] =
    useSubmitMidweekReforecastAdjustedOvfMutation({
      refetchQueries: ["getMostRecentOvfSubmitDate"],
    });

  async function handleSubmitCurrentWeekOvf() {
    const response = await submitCurrentWeekOvf({
      variables: { operationalWeekStartDate: operationalWeekStartDate },
    });
    if (!response.errors) {
      handleSubmitCurrentWeekOvfSuccess();
    }
  }

  function handleSubmitCurrentWeekOvfSuccess() {
    setPopupHeader("Success");
    setPopupMessage("Adjusted OVF Submitted");
    onOpen();
  }

  const dates = getISODatesofCurrentOperationalWeek();

  React.useEffect(() => {
    if (
      getMidweekReforecastError &&
      midweekReforecast &&
      midweekReforecast?.midweekReforecast
    ) {
      const error = getGraphQLErrorsAsHumanReadableStrings(
        getMidweekReforecastError.graphQLErrors
      );
      setPopupHeader("Error");
      setPopupMessage(error);
      onOpen();
    }
  }, [getMidweekReforecastError, midweekReforecast, onOpen]);

  return (
    <Container maxW="container.xl" alignContent="center" width="100%">
      <Box pl={8} pr={8}>
        <Flex>
          <CountrySelect
            onChange={setSelectedCountry}
            selectedCountry={selectedCountry}
            allOptionDisabled
          />
          {selectedCountry && (
            <SelectForecastLocationsMenu
              countryCode={selectedCountry}
              operationalWeekStartDate={operationalWeekStartDate}
              selectedLocation={selectedLocation?.toString()}
              setSelectedLocation={setSelectedLocation}
              selectedMetroRegion={selectedMetroRegion}
              setSelectedMetroRegion={setSelectedMetroRegion}
            />
          )}
        </Flex>
      </Box>
      <GPPopup
        isOpen={isOpen}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onClose}
      />
      <Flex>
        <Box maxW="container.xl" alignContent="center" width="100%">
          <DailyChart
            actualsData={actuals}
            ovfPublishedData={ovfPublished}
            ovfReforecastData={ovfReforecast}
            dopplerRunActiveData={dopplerRunActive}
            dopplerRunPublishedData={dopplerRunPublished}
          />
        </Box>
      </Flex>
      {(isGetMidweekReforecastLoading ||
        isCreateOrEditReforecastLayerLoading) && <PageSpinner />}
      <Flex className={styles.dailyFlex}>
        <Box className={styles.dailyBox}>
          <label
            htmlFor="summary-table-label"
            className={styles.componentLabel}
          >
            Mid Week Reforecast Table
          </label>
          <MidweekReforecastSummaryTable
            id="weekly-reforecast-summary-table"
            dates={dates}
            locationId={parseInt(selectedLocation ?? "", 10)}
            metroRegion={selectedMetroRegion ?? ""}
            countryCode={selectedCountry ?? ""}
            operationalWeekStartDate={operationalWeekStartDate}
            handleEnterDailyAdjustment={handleCreateOrEditReforecastLayer}
            isCreateOrEditReforecastLayerLoading={
              isCreateOrEditReforecastLayerLoading
            }
            tableData={weeklyReforecastTableData}
            loading={isGetMidweekReforecastLoading}
          />
        </Box>
      </Flex>
      <Box margin="10px">
        <Flex justifyContent="center" flexDirection="column">
          <Box margin="10px">
            <Button
              id="midweek-submit-btn"
              onClick={() => {
                void handleSubmitCurrentWeekOvf();
              }}
              isDisabled={
                loadingOvfSubmit ||
                isGetMidweekReforecastLoading ||
                isCreateOrEditReforecastLayerLoading
              }
            >
              {loadingOvfSubmit
                ? "Processing..."
                : "Approve and Release Adjusted OVF for All Countries"}
            </Button>
          </Box>
          <Box margin="10px">
            <span
              id="midweek-most-recent-ovf-submit-text"
              className={styles.mostRecentOvfSubmitText}
            >
              {mostRecentLoading ? (
                <Spinner size="xl" />
              ) : lastSubmitData?.mostRecentOvfSubmit ? (
                `Last submitted ${formatLongDateString(
                  lastSubmitData?.mostRecentOvfSubmit
                )}`
              ) : (
                "No OVF Submitted This Week"
              )}
            </span>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};
