import { Parser } from "json2csv";

export function convertToCSV(
  dataObj: [],
  headers?: string[],
  displayHeaders = true
): string {
  try {
    headers = headers ?? Array.from(dataObj.keys()).map((k) => k.toString());

    const parser: Parser<JSON> = new Parser<JSON>({
      fields: headers,
      header: displayHeaders,
    });

    return parser.parse(dataObj);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error: Something went wrong converting to csv", err);

    return "";
  }
}
