export enum LAYER_TYPES {
  Promotion = "Promotion",
  Referral = "Referral",
  Partner = "Partner",
  Planning = "Planning",
  Other = "Other",
  Reforecast = "Reforecast",
}

export type LayerType = keyof typeof LAYER_TYPES;
