import { Location } from "../../generated/graphql";
import { getArraySum, getArrayWithZeroesForNulls } from "../general/array";

export function getGlobalTotals(
  data: Array<{
    forecasterId: number | null;
    locations:
      | Array<
          | Pick<
              Location,
              | "dopplerForecast"
              | "seasonalityAdjustments"
              | "eventAdjustmentTotals"
              | "threeWeekOrderHistory"
            >
          | undefined
          | null
        >
      | undefined
      | null;
  }>
) {
  let dopplerForecastTotal = 0;
  let threeWeekOrderHistoryTotal = 0;
  let ovfTotal = 0;

  if (data.length > 0) {
    data.forEach((forecastersLocations) => {
      if (forecastersLocations?.locations) {
        const forecastersDopplerForecast = forecastersLocations?.locations.reduce(
          (locationsDopplerTotal, location) => {
            return locationsDopplerTotal + (location?.dopplerForecast ?? 0);
          },
          0
        );

        const forecastersSeasonalityAdjustments = forecastersLocations.locations.reduce(
          (seasonalityTotal, location) => {
            return (
              seasonalityTotal +
              getArraySum(
                getArrayWithZeroesForNulls(
                  location?.seasonalityAdjustments?.hourlyLayer.flat() ?? [0]
                )
              ) +
              getArraySum(
                getArrayWithZeroesForNulls(
                  location?.seasonalityAdjustments?.dailyLayer.flat() ?? [0]
                )
              ) +
              getArraySum(
                getArrayWithZeroesForNulls(
                  location?.seasonalityAdjustments?.weeklyLayer.flat() ?? [0]
                )
              )
            );
          },
          0
        );

        const locationsCombinedLayerTotal = forecastersLocations.locations.reduce(
          (layerTotal, location) => {
            return (
              layerTotal +
              (location?.eventAdjustmentTotals
                ? location?.eventAdjustmentTotals.reduce(
                    (locationsLayerTotal, layer) => {
                      return locationsLayerTotal + (layer?.total ?? 0);
                    },
                    0
                  ) ?? 0
                : 0)
            );
          },
          0
        );

        dopplerForecastTotal += forecastersDopplerForecast;

        threeWeekOrderHistoryTotal += forecastersLocations?.locations.reduce(
          (locationsThreeWeekOrderHistoryTotal, location) => {
            return (
              locationsThreeWeekOrderHistoryTotal +
              (location?.threeWeekOrderHistory ?? 0)
            );
          },
          0
        );

        ovfTotal +=
          forecastersDopplerForecast +
          forecastersSeasonalityAdjustments +
          locationsCombinedLayerTotal;
      }
    });
  }

  return { dopplerForecastTotal, threeWeekOrderHistoryTotal, ovfTotal };
}
