import { Box, Container, Flex } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { ChangeEvent, useState } from "react";
import { CountrySelect } from "../../components/CountrySelect/CountrySelect";
import { DropdownSelect } from "../../components/DropdownSelect/DropdownSelect";
import { GPPopup } from "../../components/GPPopup/GPPopup";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import {
  useCreateGlobalAdjustmentLayerMutation,
  useGetGlobalAdjustmentLayerInfoLazyQuery,
  useGetLayerNamesByTagLazyQuery,
  useGetLocationsByCountryLazyQuery,
} from "../../generated/graphql";
import { useDeleteEventAdjustmentLayerMutation } from "../../generated/graphql";
import { LAYER_TYPES } from "../../helpers/constants/LAYER_TYPES";
import { getISODateOfNextOperationalMonday } from "../../helpers/general/date";
import { getGraphQLErrorsAsHumanReadableStrings } from "../../helpers/graphql/getGraphQLErrorsAsHumanReadableStrings";
import {
  VALIDATION_ERRORS_TO_LOCATIONS_IN_COUNTRY,
  VALIDATION_ERRORS_TO_READABLE_STRINGS,
} from "./helpers/errorsToReadableStringsMap";
import { SUCCESS_MESSAGES } from "./helpers/successMessages";
import styles from "./styles.module.css";

export const GlobalAdjust = () => {
  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );

  const [selectedCountry, setSelectedCountry] =
    useState<string>("Select Country");

  const [selectedCreateLayerType, setSelectedCreateLayerType] =
    useState<string>();

  const [selectedDeleteLayerType, setSelectedDeleteLayerType] =
    useState<string>();

  const [popupHeader, setPopupHeader] = useState<string>("");

  const [popupMessage, setPopupMessage] = useState<string | React.ReactElement>(
    ""
  );

  const [selectedLayer, setSelectedLayer] = useState<string>();

  const [layerNameInput, setlayerNameInput] = useState<string>("");

  const [createLayerOrderTotal, setCreateLayerOrderTotal] = useState<number>(0);

  const [createLocationMode, setCreateLocationMode] = useState<string>("");

  const [createLocationArray, setCreateLocationArray] = useState<string>("");

  const [
    getLocationsByCountry,
    { data: locationsByCountry, loading: locationsByCountryLoading },
  ] = useGetLocationsByCountryLazyQuery({
    variables: {
      country: selectedCountry ?? "All",
    },
  });

  const [
    createGlobalAdjustmentLayer,
    { loading: createGlobalAdjustmentLayerLoading },
  ] = useCreateGlobalAdjustmentLayerMutation();

  const [deleteLayer] = useDeleteEventAdjustmentLayerMutation({
    variables: {
      operationalWeekStartDate: selectedWeek,
      name: selectedLayer ?? "",
    },
    context: {
      skipDefaultErrorHandling: true,
    },
  });

  const [
    getLayerNamesByTag,
    { data: layerNamesByTag, loading: layerNamesByTagLoading },
  ] = useGetLayerNamesByTagLazyQuery({
    variables: {
      operationalWeekStartDate: selectedWeek,
      tag: selectedDeleteLayerType,
    },
  });

  const [
    getGlobalAdjustmentLayerInfo,
    {
      data: globalAdjustmentLayerInfo,
      loading: globalAdjustmentLayerInfoLoading,
    },
  ] = useGetGlobalAdjustmentLayerInfoLazyQuery({
    variables: {
      operationalWeekStartDate: selectedWeek,
      layerName: selectedLayer ?? "",
    },
  });

  const layerNames = layerNamesByTag?.eventAdjustments
    ? layerNamesByTag.eventAdjustments.reduce<string[]>(
        (layerNameList, layer) => {
          if (layer?.name) {
            layerNameList.push(layer?.name);
          }
          return layerNameList;
        },
        []
      )
    : [];

  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
  }

  function handleCountrySelect(country: string) {
    setSelectedCountry(country);
    void getLocationsByCountry();
    setCreateLocationMode("All");
  }

  function handleCreateLayerTypeSelect(layerType: string) {
    setSelectedCreateLayerType(layerType);
  }

  function handleDeleteLayerTypeSelect(layerType: string) {
    setSelectedLayer(undefined);
    setSelectedDeleteLayerType(layerType);
    void getLayerNamesByTag();
  }

  function handleLayerSelect(layerName: string) {
    setSelectedLayer(layerName);
    void getGlobalAdjustmentLayerInfo();
  }

  function handleLayerNameInput(event: ChangeEvent<HTMLInputElement>) {
    setlayerNameInput(event.target.value);
  }

  function handleCreateLayerOrderTotalInput(
    _orderTotalAsString: string,
    orderTotal: number
  ) {
    setCreateLayerOrderTotal(orderTotal);
  }

  function handleCreateLayerLocationInput(
    event: ChangeEvent<HTMLInputElement>
  ) {
    setCreateLocationArray(event.target.value);
  }
  function handleValidateLocationInput(event: ChangeEvent<HTMLInputElement>) {
    validateLocations(event.target.value);
  }

  function parseLocations(locations: string) {
    return createLocationMode === "All"
      ? undefined
      : locations
          .replace(/\s+/g, "")
          .split(",")
          .map((locationId) => parseInt(locationId, 10))
          .filter((id) => !isNaN(id));
  }

  function validateLocations(locations: string) {
    const locationsInCountry = new Set(
      locationsByCountry?.locations?.map((location) => {
        return parseInt(location?.id ?? "-1", 10);
      }) ?? []
    );
    const invalidLocations: number[] = [];

    const locationsArray = parseLocations(locations);
    if (!locationsArray) {
      return;
    }
    locationsArray.forEach((location) => {
      if (!locationsInCountry.has(location)) {
        invalidLocations.push(location);
      }
    });
    if (invalidLocations.length > 0) {
      setPopupHeader("Error");
      setPopupMessage(
        VALIDATION_ERRORS_TO_LOCATIONS_IN_COUNTRY(invalidLocations)
      );
      onOpen();
    }
  }

  function handleCreateLayerMutationSuccess() {
    handleDeleteLayerTypeSelect("");
    setPopupHeader("Success");
    setPopupMessage(SUCCESS_MESSAGES.CreateLayerSuccess);
    onOpen();
  }

  function getCreateActionValidationError(): string | null {
    const listOfIntegersRegex = /^((\d+)(,)?)*\d$/;
    if (
      createLocationMode !== "All" &&
      !listOfIntegersRegex.exec(createLocationArray.replace(/\s+/g, ""))
    ) {
      return "INVALID_LOCATION_LIST";
    }
    return null;
  }

  async function handleCreateLayerSubmit() {
    const validationError = getCreateActionValidationError();
    if (validationError !== null) {
      setPopupHeader("Error");
      setPopupMessage(
        VALIDATION_ERRORS_TO_READABLE_STRINGS[validationError] ||
          validationError
      );
      onOpen();
      return;
    }

    const response = await createGlobalAdjustmentLayer({
      variables: {
        tag: selectedCreateLayerType,
        layerName: layerNameInput,
        locationIds: parseLocations(createLocationArray),
        orderAdjustment: createLayerOrderTotal,
        forecasterId: null,
        operationalWeekStartDate: selectedWeek,
        countryCode: selectedCountry,
      },
    });

    if (!response.errors) {
      handleCreateLayerMutationSuccess();
    }
  }

  async function handleDeleteLayerSubmit() {
    const response = await deleteLayer();
    if (response.errors) {
      setPopupHeader("Error");
      setPopupMessage(getGraphQLErrorsAsHumanReadableStrings(response.errors));
      onOpen();
    } else {
      handleDeleteLayerTypeSelect("");
      setPopupHeader("Success");
      setPopupMessage(SUCCESS_MESSAGES.DeleteLayerSuccess);
      onOpen();
    }
  }

  return (
    <Container>
      <GPPopup
        isOpen={isOpen}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onClose}
      />
      {(createGlobalAdjustmentLayerLoading ||
        layerNamesByTagLoading ||
        globalAdjustmentLayerInfoLoading ||
        locationsByCountryLoading) && <PageSpinner />}
      <Flex>
        <Container id="select-country-week">
          <Box margin="10px" maxWidth="200px">
            <CountrySelect
              allOptionDisabled
              onChange={handleCountrySelect}
              selectedCountry={selectedCountry}
            />
          </Box>
        </Container>
        <Container>
          <Box margin="10px" maxWidth="200px">
            <WeekSelect
              onChange={handleWeekSelect}
              selectedWeek={selectedWeek}
              key={selectedWeek}
            />
          </Box>
        </Container>
      </Flex>
      <Flex>
        <Container id="create-layers-menu">
          <Box margin="10px">Create Layers</Box>
          <Box margin="10px">
            <DropdownSelect
              id="create-layers-select-layer-type"
              testId="global-adjust-create-layers-select-layer-type"
              selectOptions={Object.keys(LAYER_TYPES).filter(
                (selectOption) => selectOption !== LAYER_TYPES.Reforecast
              )}
              placeholder="Select layer type"
              label="Layer Type:"
              onChange={handleCreateLayerTypeSelect}
              selection={selectedCreateLayerType}
              key={selectedCreateLayerType}
            />
          </Box>
          <Box margin="10px">
            <Input
              id="layer-name-input"
              data-test-id="global-adjust-layer-name-input"
              name="layer-name-input"
              placeholder="Enter layer name"
              value={layerNameInput}
              onChange={handleLayerNameInput}
              isDisabled={selectedCreateLayerType ? false : true}
            />
          </Box>
          <Box margin="10px">
            <FormControl as="fieldset">
              <FormLabel as="legend">
                <label className={styles.componentLabel}>Locations:</label>
              </FormLabel>
              <RadioGroup
                defaultValue=""
                onChange={(value) => {
                  setCreateLocationMode(value);
                  if (value === "All") {
                    setCreateLocationArray("");
                  }
                }}
                value={createLocationMode}
              >
                <HStack spacing="24px">
                  <Radio value="All">All</Radio>
                  <Radio value="Selected">Selected:</Radio>
                </HStack>
              </RadioGroup>
              <Input
                id="locations-list-input"
                placeholder="1, 8, 357, ..."
                value={createLocationArray}
                onBlur={handleValidateLocationInput}
                onChange={handleCreateLayerLocationInput}
                isDisabled={
                  createLocationMode === "All" ||
                  selectedCountry === "Select Country"
                }
              />
            </FormControl>
          </Box>
          <Box margin="10px">
            <label className={styles.componentLabel}>Order Adjustment:</label>
            <NumberInput
              id="create-layers-order-adjustment-input"
              value={createLayerOrderTotal ?? 0}
              onChange={handleCreateLayerOrderTotalInput}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box margin="10px">
            <DropdownSelect
              id="create-layers-select-seasonality-distribution"
              selectOptions={["Seasonality Distribution"]}
              placeholder="Select distribution"
              label="Distribution:"
              onChange={() => false}
              selection="Seasonality Distribution"
              key="Select distribution"
            />
          </Box>
          <Box margin="10px">
            <Button
              data-test-id="global-adjust-create-layer"
              onClick={() => {
                void handleCreateLayerSubmit();
              }}
              isDisabled={
                !(
                  selectedCreateLayerType &&
                  createLayerOrderTotal &&
                  layerNameInput
                ) || createGlobalAdjustmentLayerLoading
              }
            >
              {createGlobalAdjustmentLayerLoading
                ? "Processing..."
                : "Create Layer"}
            </Button>
          </Box>
        </Container>

        <Container id="view-or-delete-layers-menu">
          <Box margin="10px">View/Delete Layers</Box>
          <Box margin="10px">
            <DropdownSelect
              id="view-or-delete-layers-select-layer-type"
              testId="global-adjust-view-or-delete-layers-select-layer-type"
              selectOptions={Object.keys(LAYER_TYPES).filter(
                (selectOption) => selectOption !== LAYER_TYPES.Reforecast
              )}
              placeholder="Select layer type"
              label="Layer Type:"
              onChange={handleDeleteLayerTypeSelect}
              selection={selectedDeleteLayerType}
              key={selectedDeleteLayerType}
            />
          </Box>
          <Box margin="10px">
            <DropdownSelect
              id="view-or-delete-layers-select-layer-name"
              testId="global-adjust-view-or-delete-layers-select-layer-name"
              selectOptions={layerNames}
              placeholder="Select layer"
              label="Layer:"
              onChange={handleLayerSelect}
              selection={selectedLayer}
              key={selectedLayer}
            />
          </Box>
          <Box margin="10px">
            <FormControl as="fieldset">
              <FormLabel as="legend">
                <label className={styles.componentLabel}>Locations:</label>
              </FormLabel>
              <Input
                id="view-or-delete-layers-locations-list"
                data-test-id="global-adjust-view-or-delete-layers-locations-list"
                readOnly
                placeholder="1, 8, 357, ..."
                value={
                  selectedLayer &&
                  globalAdjustmentLayerInfo?.eventAdjustments &&
                  globalAdjustmentLayerInfo?.eventAdjustments[0]
                    ? globalAdjustmentLayerInfo?.eventAdjustments[0].locationIds?.join(
                        ","
                      )
                    : ""
                }
              />
            </FormControl>
          </Box>
          <Box margin="10px">
            <label className={styles.componentLabel}>Order Adjustment:</label>
            <NumberInput
              id="view-or-delete-layers-order-adjustment-field"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
              value={
                selectedLayer &&
                globalAdjustmentLayerInfo?.eventAdjustments &&
                globalAdjustmentLayerInfo?.eventAdjustments[0]
                  ? globalAdjustmentLayerInfo?.eventAdjustments[0].orderSum ?? 0
                  : 0
              }
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box margin="10px">
            <Button
              data-test-id="global-adjust-delete-layer"
              onClick={() => {
                void handleDeleteLayerSubmit();
              }}
              isDisabled={!(selectedDeleteLayerType && selectedLayer)}
            >
              Delete Layer
            </Button>
          </Box>
        </Container>
      </Flex>
    </Container>
  );
};
