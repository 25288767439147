import {
  Center,
  ChakraProvider,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { Column, useSortBy, useTable } from "react-table";
import { LAYER_TYPES } from "../../../helpers/constants/LAYER_TYPES";
import { getTagTotal } from "../../../helpers/weeklyAdjust/getRowTotals";

export interface OvfQATableRow {
  analystId: number | string;
  dopplerForecast: number;
  dopplerForecastDistribution: number;
  threeWeekOrderHistoryDistribution: number;
  seasonalityAdjustmentTotal: number;
  eventAdjustmentTotals: EventAdjustmentTotal[];
  globalOvfTotal: number;
}

export interface EventAdjustmentTotal {
  tag: string;
  total: number;
}

export interface OvfQATableProps {
  tableData: OvfQATableRow[];
  loading: boolean;
}

const columns: Array<Column<OvfQATableRow>> = [
  {
    Header: "Analyst Id",
    accessor: "analystId",
  },
  {
    Header: "Doppler",
    accessor: (row) => {
      return row.dopplerForecast.toLocaleString("en-US");
    },
  },
  {
    Header: "Doppler Distr",
    accessor: (row) => {
      return (row.dopplerForecastDistribution * 100).toFixed(2) + "%";
    },
  },
  {
    Header: "Trailing 3 Wks Distr",
    accessor: (row) => {
      return (row.threeWeekOrderHistoryDistribution * 100).toFixed(2) + "%";
    },
  },
  {
    Header: "Seasonality Adjustments",
    accessor: (row) => {
      return Math.round(row.seasonalityAdjustmentTotal).toLocaleString("en-US");
    },
  },
  {
    Header: "Total Seasonally Adj Fcst",
    accessor: (row) => {
      return Math.round(
        row.dopplerForecast + row.seasonalityAdjustmentTotal
      ).toLocaleString("en-US");
    },
  },
  {
    Header: "Referrals",
    accessor: (row) => {
      return Math.round(getTagTotal(row, LAYER_TYPES.Referral)).toLocaleString(
        "en-US"
      );
    },
  },
  {
    Header: "Promos",
    accessor: (row) => {
      return Math.round(getTagTotal(row, LAYER_TYPES.Promotion)).toLocaleString(
        "en-US"
      );
    },
  },
  {
    Header: "Partners",
    accessor: (row) => {
      return Math.round(getTagTotal(row, LAYER_TYPES.Partner)).toLocaleString(
        "en-US"
      );
    },
  },
  {
    Header: "Other",
    accessor: (row) => {
      return Math.round(getTagTotal(row, LAYER_TYPES.Other)).toLocaleString(
        "en-US"
      );
    },
  },
  {
    Header: "OVF Pure",
    accessor: (row) => {
      return Math.round(
        row.dopplerForecast +
          getTagTotal(row, LAYER_TYPES.Referral) +
          getTagTotal(row, LAYER_TYPES.Promotion) +
          getTagTotal(row, LAYER_TYPES.Partner) +
          getTagTotal(row, LAYER_TYPES.Other) +
          row.seasonalityAdjustmentTotal
      ).toLocaleString("en-US");
    },
  },
  {
    Header: "Planning",
    accessor: (row) => {
      return Math.round(getTagTotal(row, LAYER_TYPES.Planning)).toLocaleString(
        "en-US"
      );
    },
  },
  {
    Header: "FINAL OVF",
    accessor: (row) => {
      return Math.round(
        row.dopplerForecast +
          getTagTotal(row, LAYER_TYPES.Referral) +
          getTagTotal(row, LAYER_TYPES.Promotion) +
          getTagTotal(row, LAYER_TYPES.Partner) +
          getTagTotal(row, LAYER_TYPES.Other) +
          getTagTotal(row, LAYER_TYPES.Planning) +
          row.seasonalityAdjustmentTotal
      ).toLocaleString("en-US");
    },
  },
  {
    Header: "OVF Distr",
    accessor: (row) => {
      return (
        (
          ((row.dopplerForecast +
            getTagTotal(row, LAYER_TYPES.Referral) +
            getTagTotal(row, LAYER_TYPES.Promotion) +
            getTagTotal(row, LAYER_TYPES.Partner) +
            getTagTotal(row, LAYER_TYPES.Other) +
            getTagTotal(row, LAYER_TYPES.Planning) +
            row.seasonalityAdjustmentTotal) /
            row.globalOvfTotal) *
          100
        ).toFixed(2) + "%"
      );
    },
  },
];

export const OvfQATable: React.FC<OvfQATableProps> = ({
  tableData,
  loading,
}) => {
  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable(
    {
      columns: columns,
      data: tableData,
      getRowId: (row, _relativeIndex, _parent) => {
        return row.analystId.toString();
      },
    },
    useSortBy
  );

  return (
    <Container>
      <ChakraProvider>
        <Table size="sm" variant="striped">
          <Thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                key={"header group " + headerGroupIndex.toString()}
              >
                {headerGroup.headers.map((column, index) => (
                  <Th
                    userSelect="none"
                    {...column.getHeaderProps(
                      Object.assign(column.getSortByToggleProps(), {
                        style: { minWidth: column.minWidth },
                      })
                    )}
                    key={"column " + index.toString()}
                  >
                    <Flex alignItems="center">
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsChevronCompactDown />
                        ) : (
                          <BsChevronCompactUp />
                        )
                      ) : (
                        ""
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {loading ? (
            <Tbody>
              <Tr>
                <Td colSpan={10000}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={"row " + rowIndex.toString()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={rowIndex.toString() + "-" + cellIndex.toString()}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </ChakraProvider>
    </Container>
  );
};
