import { Box, Button, Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { CountrySelect } from "../../components/CountrySelect/CountrySelect";
import { GPPopup } from "../../components/GPPopup/GPPopup";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import {
  Location,
  useGetEventAdjustmentTotalsByDayQuery,
  useGetMostRecentOvfSubmitDateQuery,
  useGetOvfQaQuery,
  useSubmitOvfMutation,
} from "../../generated/graphql";
import { LAYER_TYPES } from "../../helpers/constants/LAYER_TYPES";
import {
  getArraySum,
  getArrayWithZeroesForNulls,
} from "../../helpers/general/array";
import { constants } from "../../helpers/general/constants";
import {
  formatLongDateString,
  getISODateOfNextOperationalMonday,
} from "../../helpers/general/date";
import { getGlobalTotals } from "../../helpers/ovfQa/getGlobalTotals";
import { getTagTotal } from "../../helpers/ovfQa/getTagTotal";
import {
  OvfQADailyTable,
  OvfQADailyTableRow,
} from "./OvfQADailyTable/OvfQADailyTable";
import { OvfQATable, OvfQATableRow } from "./OvfQATable/OvfQATable";
import { SUCCESS_MESSAGES } from "./helpers/successMessages";
import styles from "./styles.module.css";

export const OvfQA = () => {
  const [popupHeader, setPopupHeader] = useState<string>("");

  const [popupMessage, setPopupMessage] = useState<string>("");

  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );

  const [selectedCountry, setSelectedCountry] = useState<string>("");

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
  }

  function handleCountrySelect(country: string) {
    setSelectedCountry(country);
  }

  const { data: dataByLocation, loading: ovfQAloading } = useGetOvfQaQuery({
    skip: !selectedWeek || !selectedCountry,
    variables: {
      country: selectedCountry,
      operationalWeekStartDate: selectedWeek,
    },
  });

  const {
    data: eventAdjustmentTotalsByDay,
    loading: eventAdjustmentTotalsByDayLoading,
  } = useGetEventAdjustmentTotalsByDayQuery({
    skip: !selectedWeek || !selectedCountry,
    variables: {
      country: selectedCountry,
      operationalWeekStartDate: selectedWeek,
    },
  });

  const [submitOvf, { loading: loadingOvfSubmit }] = useSubmitOvfMutation({
    refetchQueries: ["getMostRecentOvfSubmitDate"],
  });

  const { data: lastSubmitData, loading: mostRecentLoading } =
    useGetMostRecentOvfSubmitDateQuery({
      skip: !selectedWeek,
      variables: {
        selectedWeek: selectedWeek,
      },
    });

  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleSubmitOvfSuccess() {
    setPopupHeader("Success");
    setPopupMessage(SUCCESS_MESSAGES.SubmitOVFSuccess);
    onOpen();
  }

  async function handleSubmitOvf() {
    const response = await submitOvf({
      variables: { operationalWeekStartDate: selectedWeek },
    });

    if (!response.errors) {
      handleSubmitOvfSuccess();
    }
  }

  const tableData: OvfQATableRow[] = useMemo(() => {
    const allForecasterIds = Array.from(
      new Set(
        dataByLocation?.ovfQaLocations?.map(
          (location) => location?.forecasterId ?? null
        ) ?? []
      )
    );

    const dataByForecaster =
      allForecasterIds.map((forecasterId) => {
        return {
          forecasterId,
          locations: dataByLocation?.ovfQaLocations?.filter(
            (location) => location?.forecasterId === forecasterId
          ) as Location[],
        };
      }) ?? [];

    const { dopplerForecastTotal, threeWeekOrderHistoryTotal, ovfTotal } =
      dataByLocation
        ? getGlobalTotals(dataByForecaster)
        : {
            dopplerForecastTotal: 0,
            threeWeekOrderHistoryTotal: 0,
            ovfTotal: 0,
          };

    const dataRows =
      dataByForecaster.reduce<OvfQATableRow[]>(
        (accum, forecastersLocations) => {
          if (forecastersLocations.locations) {
            const forecastersDopplerForecast =
              forecastersLocations.locations.reduce(
                (dopplerTotal, location) => {
                  return dopplerTotal + (location?.dopplerForecast ?? 0);
                },
                0
              );

            accum.push({
              analystId: forecastersLocations.forecasterId ?? "Unassigned",
              dopplerForecast: forecastersDopplerForecast,
              dopplerForecastDistribution:
                forecastersDopplerForecast / dopplerForecastTotal ?? 0,
              threeWeekOrderHistoryDistribution:
                forecastersLocations?.locations.reduce(
                  (locationsThreeWeekOrderHistoryTotal, location) => {
                    return (
                      locationsThreeWeekOrderHistoryTotal +
                      (location?.threeWeekOrderHistory ?? 0)
                    );
                  },
                  0
                ) / threeWeekOrderHistoryTotal ?? 0,
              globalOvfTotal: ovfTotal,
              seasonalityAdjustmentTotal: forecastersLocations.locations.reduce(
                (seasonalityTotal, location) => {
                  return (
                    seasonalityTotal +
                    getArraySum(
                      getArrayWithZeroesForNulls(
                        location?.seasonalityAdjustments?.hourlyLayer.flat() ?? [
                          0,
                        ]
                      )
                    ) +
                    getArraySum(
                      getArrayWithZeroesForNulls(
                        location?.seasonalityAdjustments?.dailyLayer.flat() ?? [
                          0,
                        ]
                      )
                    ) +
                    getArraySum(
                      getArrayWithZeroesForNulls(
                        location?.seasonalityAdjustments?.weeklyLayer.flat() ?? [
                          0,
                        ]
                      )
                    )
                  );
                },
                0
              ),
              eventAdjustmentTotals: [
                {
                  tag: LAYER_TYPES.Referral,
                  total: getTagTotal(
                    forecastersLocations.locations,
                    LAYER_TYPES.Referral
                  ),
                },
                {
                  tag: LAYER_TYPES.Promotion,
                  total: getTagTotal(
                    forecastersLocations.locations,
                    LAYER_TYPES.Promotion
                  ),
                },
                {
                  tag: LAYER_TYPES.Partner,
                  total: getTagTotal(
                    forecastersLocations.locations,
                    LAYER_TYPES.Partner
                  ),
                },
                {
                  tag: LAYER_TYPES.Other,
                  total: getTagTotal(
                    forecastersLocations.locations,
                    LAYER_TYPES.Other
                  ),
                },
                {
                  tag: LAYER_TYPES.Planning,
                  total: getTagTotal(
                    forecastersLocations.locations,
                    LAYER_TYPES.Planning
                  ),
                },
              ],
            });
          }
          return accum;
        },
        []
      ) ?? [];

    if (dataRows.length) {
      dataRows.push({
        analystId: "Total",
        dopplerForecast: dopplerForecastTotal,
        dopplerForecastDistribution: 1,
        threeWeekOrderHistoryDistribution: 1,
        seasonalityAdjustmentTotal: dataByLocation?.ovfQaLocations
          ? dataByLocation?.ovfQaLocations.reduce(
              (seasonalityTotal, location) => {
                return (
                  seasonalityTotal +
                  getArraySum(
                    getArrayWithZeroesForNulls(
                      location?.seasonalityAdjustments?.hourlyLayer.flat() ?? [
                        0,
                      ]
                    )
                  ) +
                  getArraySum(
                    getArrayWithZeroesForNulls(
                      location?.seasonalityAdjustments?.dailyLayer.flat() ?? [0]
                    )
                  ) +
                  getArraySum(
                    getArrayWithZeroesForNulls(
                      location?.seasonalityAdjustments?.weeklyLayer.flat() ?? [
                        0,
                      ]
                    )
                  )
                );
              },
              0
            )
          : 0,
        eventAdjustmentTotals: [
          {
            tag: LAYER_TYPES.Referral,
            total: getTagTotal(
              dataByLocation?.ovfQaLocations ?? [],
              LAYER_TYPES.Referral
            ),
          },
          {
            tag: LAYER_TYPES.Promotion,
            total: getTagTotal(
              dataByLocation?.ovfQaLocations ?? [],
              LAYER_TYPES.Promotion
            ),
          },
          {
            tag: LAYER_TYPES.Partner,
            total: getTagTotal(
              dataByLocation?.ovfQaLocations ?? [],
              LAYER_TYPES.Partner
            ),
          },
          {
            tag: LAYER_TYPES.Other,
            total: getTagTotal(
              dataByLocation?.ovfQaLocations ?? [],
              LAYER_TYPES.Other
            ),
          },
          {
            tag: LAYER_TYPES.Planning,
            total: getTagTotal(
              dataByLocation?.ovfQaLocations ?? [],
              LAYER_TYPES.Planning
            ),
          },
        ],
        globalOvfTotal: ovfTotal,
      });
    }

    return dataRows;
  }, [dataByLocation]);

  const tableDataByDay: OvfQADailyTableRow[] = useMemo(() => {
    const allForecasterIds = Array.from(
      new Set(
        dataByLocation?.ovfQaLocations?.map(
          (location) => location?.forecasterId ?? null
        ) ?? []
      )
    );

    const dataByForecaster =
      allForecasterIds.map((forecasterId) => ({
        forecasterId,
        locations: dataByLocation?.ovfQaLocations?.filter(
          (location) => location?.forecasterId === forecasterId
        ) as Location[],
      })) ?? [];

    const { dopplerForecastTotal, threeWeekOrderHistoryTotal, ovfTotal } =
      dataByLocation
        ? getGlobalTotals(dataByForecaster)
        : {
            dopplerForecastTotal: 0,
            threeWeekOrderHistoryTotal: 0,
            ovfTotal: 0,
          };

    const dataRows =
      dataByLocation?.ovfQaLocations?.reduce<OvfQADailyTableRow[]>(
        (accum, location) => {
          if (location) {
            for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
              const todaysDopplerForecast =
                dataByLocation?.ovfQaLocations?.reduce(
                  (todaysDopplerForecastTotal, loc) =>
                    todaysDopplerForecastTotal +
                    getArraySum(
                      getArrayWithZeroesForNulls(
                        loc?.seasonalityAdjustments?.dopplerForecast[
                          dayIndex
                        ] ?? [0]
                      )
                    ),
                  0
                ) ?? 0;

              const todaysThreeWeekOrderHistory =
                dataByLocation?.ovfQaLocations?.reduce(
                  (locationsThreeWeekOrderHistoryTotal, loc) =>
                    locationsThreeWeekOrderHistoryTotal +
                    (loc?.dailyThreeWeekTrailingAverage
                      ? loc?.dailyThreeWeekTrailingAverage[dayIndex] ?? 0
                      : 0),

                  0
                ) ?? 0;

              const todaysSeasonalityAdjustmentTotal =
                dataByLocation?.ovfQaLocations?.reduce(
                  (seasonalityTotal, loc) =>
                    seasonalityTotal +
                    getArraySum(
                      getArrayWithZeroesForNulls(
                        loc?.seasonalityAdjustments?.hourlyLayer[dayIndex] ?? [
                          0,
                        ]
                      )
                    ) +
                    getArraySum(
                      getArrayWithZeroesForNulls(
                        loc?.seasonalityAdjustments?.dailyLayer[dayIndex] ?? [0]
                      )
                    ) +
                    getArraySum(
                      getArrayWithZeroesForNulls(
                        loc?.seasonalityAdjustments?.weeklyLayer[dayIndex] ?? [
                          0,
                        ]
                      )
                    ),
                  0
                ) ?? 0;

              accum.push({
                day: constants.time.DAYS_OF_THE_WEEK[dayIndex],
                dopplerForecast: todaysDopplerForecast,
                dopplerForecastDistribution:
                  todaysDopplerForecast / dopplerForecastTotal ?? 0,
                threeWeekOrderHistoryDistribution:
                  todaysThreeWeekOrderHistory / threeWeekOrderHistoryTotal ?? 0,
                globalOvfTotal: ovfTotal,
                seasonalityAdjustmentTotal: todaysSeasonalityAdjustmentTotal,
                dailyEventAdjustmentTotal:
                  eventAdjustmentTotalsByDay?.eventAdjustmentTotalsByDay
                    ? eventAdjustmentTotalsByDay?.eventAdjustmentTotalsByDay[
                        dayIndex
                      ]
                    : 0,
              });
            }
          }
          return accum;
        },
        []
      ) ?? [];

    if (dataRows.length) {
      dataRows.push({
        day: "Total",
        dopplerForecast: dopplerForecastTotal,
        dopplerForecastDistribution: 1,
        threeWeekOrderHistoryDistribution: 1,
        seasonalityAdjustmentTotal: dataByLocation?.ovfQaLocations
          ? dataByLocation?.ovfQaLocations.reduce(
              (seasonalityTotal, location) =>
                seasonalityTotal +
                getArraySum(
                  getArrayWithZeroesForNulls(
                    location?.seasonalityAdjustments?.hourlyLayer.flat() ?? [0]
                  )
                ) +
                getArraySum(
                  getArrayWithZeroesForNulls(
                    location?.seasonalityAdjustments?.dailyLayer.flat() ?? [0]
                  )
                ) +
                getArraySum(
                  getArrayWithZeroesForNulls(
                    location?.seasonalityAdjustments?.weeklyLayer.flat() ?? [0]
                  )
                ),
              0
            )
          : 0,
        globalOvfTotal: ovfTotal,
        dailyEventAdjustmentTotal: getArraySum(
          eventAdjustmentTotalsByDay?.eventAdjustmentTotalsByDay ?? [0]
        ),
      });
    }

    return dataRows;
  }, [dataByLocation, eventAdjustmentTotalsByDay?.eventAdjustmentTotalsByDay]);

  return (
    <div id="ovf-qa-page">
      <GPPopup
        isOpen={isOpen}
        body={<span>{popupMessage}</span>}
        header={popupHeader}
        onClose={onClose}
      />
      <Flex justifyContent="center">
        <Box marginLeft="10px" marginTop="10px" marginBottom="10px">
          <WeekSelect
            onChange={handleWeekSelect}
            selectedWeek={selectedWeek}
            key={selectedWeek}
          />
        </Box>
        <Box marginLeft="10px" marginTop="10px" marginBottom="10px">
          <CountrySelect
            onChange={handleCountrySelect}
            selectedCountry={selectedCountry}
          />
        </Box>
      </Flex>
      <Flex>
        <Box marginTop="10px" marginBottom="10px" marginLeft="10px">
          <label htmlFor="ovf-qa-table-label" className={styles.headerText}>
            Weekly Consolidated Forecast
          </label>
          <OvfQATable loading={ovfQAloading} tableData={tableData} />
        </Box>
      </Flex>
      <Flex>
        <Box marginTop="10px" marginBottom="10px" marginLeft="10px">
          <label htmlFor="ovf-qa-table-label" className={styles.headerText}>
            Daily Consolidated Forecast
          </label>
          <OvfQADailyTable
            loading={ovfQAloading || eventAdjustmentTotalsByDayLoading}
            tableData={tableDataByDay}
          />
        </Box>
      </Flex>
      <Box margin="10px">
        <Flex justifyContent="center" flexDirection="column">
          <Box margin="10px">
            <Button
              onClick={() => {
                void handleSubmitOvf();
              }}
              isDisabled={loadingOvfSubmit || ovfQAloading}
            >
              {loadingOvfSubmit ? "Processing..." : "Submit OVF"}
            </Button>
          </Box>
          <Box margin="10px">
            <span
              id="most-recent-ovf-submit-text"
              className={styles.mostRecentOvfSubmitText}
            >
              {mostRecentLoading ? (
                <Spinner size="xl" />
              ) : lastSubmitData?.mostRecentOvfSubmit ? (
                `Last submitted ${formatLongDateString(
                  lastSubmitData?.mostRecentOvfSubmit
                )}`
              ) : (
                "No OVF Submitted This Week"
              )}
            </span>
          </Box>
        </Flex>
      </Box>
    </div>
  );
};
