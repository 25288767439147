import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  Link,
  PopoverTrigger as OrigPopoverTrigger,
  Popover,
  PopoverContent,
  Stack,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { AuthState } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import { ISplitTreatmentsChildProps } from "@splitsoftware/splitio-react/types/types.d";
import { nextMonday, subWeeks } from "date-fns";
import { Dispatch, MouseEventHandler, SetStateAction, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { useGetCurrentlyActiveDopplerRunDateQuery } from "../../generated/graphql";
import { MENU_ITEM, MENU_ITEMS } from "../../helpers/constants/MENU_ITEM";
import { getDateInLocalTime } from "../../helpers/general/date";
import { isTestRunning } from "../../helpers/test/isTestRunning";
import { Home } from "./Home";
import { Logo } from "./Logo";
import styles from "./styles.module.css";

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

export const NavBar = ({
  props,
  setCorsErrorModalOpen,
}: {
  props?: FlexProps;
  setCorsErrorModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { authState } = useOktaAuth();
  const oktaDisabled = process.env.REACT_APP_DISABLE_OKTA === "true";

  const { data: currentlyActiveDopplerRunDate } =
    useGetCurrentlyActiveDopplerRunDateQuery({
      skip: !oktaDisabled && !authState?.isAuthenticated,
    });

  const previousMondaysDate = subWeeks(nextMonday(new Date()), 1);
  previousMondaysDate.setHours(0, 0, 0, 0);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...{ props, setCorsErrorModalOpen }}>
      <Logo w="min-content" />

      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <Menu
        isOpen={isOpen}
        authState={authState ?? {}}
        oktaDisabled={oktaDisabled}
      />
      {currentlyActiveDopplerRunDate?.currentlyActiveDopplerRunDate &&
      getDateInLocalTime(
        new Date(currentlyActiveDopplerRunDate?.currentlyActiveDopplerRunDate)
      ) < previousMondaysDate ? (
        <SelectedRunDisplay
          activeDopplerRun={
            currentlyActiveDopplerRunDate?.currentlyActiveDopplerRunDate
          }
        />
      ) : null}
    </NavBarContainer>
  );
};

interface SelectedRunDisplayProps {
  activeDopplerRun: string | null | undefined;
}

const SelectedRunDisplay = ({ activeDopplerRun }: SelectedRunDisplayProps) => {
  return (
    <span
      id="currently-active-doppler-run-text"
      className={styles.currentlyActiveReforecastDopplerRunText}
    >
      Currently active Doppler Run:&nbsp;
      {activeDopplerRun ?? "None"}
    </span>
  );
};

interface ToggleProps {
  isOpen: boolean;
  toggle: MouseEventHandler<HTMLDivElement>;
}

const MenuToggle = ({ toggle, isOpen }: ToggleProps) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      <IconButton
        size="md"
        fontSize="lg"
        variant="ghost"
        aria-label={`${isOpen ? "Close" : "Open"} menu`}
        icon={isOpen ? <FaTimes /> : <FaBars />}
      />
    </Box>
  );
};

interface MenuLinkProps extends TextProps, Pick<RouterLinkProps, "to"> {}

const MenuLink = ({ children, to = "/", ...rest }: MenuLinkProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Link as={RouterLink} to={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

interface MenuParentProps extends TextProps {
  menuItem: MENU_ITEM[];
}

const MenuParent = ({ children, menuItem = [], ...rest }: MenuParentProps) => {
  return (
    <Box flexBasis={{ base: "100%", md: "auto" }}>
      <Stack>
        <Box>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Text {...rest}>{children}</Text>
            </PopoverTrigger>
            <PopoverContent>
              {menuItem.map((item) => ProcessMenuItem(item))}
            </PopoverContent>
          </Popover>
        </Box>
      </Stack>
    </Box>
  );
};

const MenuPlaceHolder = ({ children, ...rest }: TextProps) => {
  return (
    <Box>
      <Text {...rest}>{children}</Text>
    </Box>
  );
};

const Menu = ({
  isOpen,
  authState,
  oktaDisabled,
}: {
  isOpen: boolean;
  authState: AuthState;
  oktaDisabled: boolean;
}) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      {" "}
      <SplitTreatments names={["oaLaborEnabled"]}>
        {({ treatments, isReady }: ISplitTreatmentsChildProps) => {
          return (
            <Stack
              spacing={8}
              justify={["space-between", "flex-end", "flex-end"]}
              direction={["column", "row", "row", "row"]}
              pt={[4, 4, 0, 0]}
            >
              {" "}
              {(authState?.isAuthenticated || oktaDisabled) &&
                MENU_ITEMS.map((item) =>
                  (isReady &&
                    item?.featureFlag &&
                    item?.featureFlag in treatments &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    treatments[item?.featureFlag].treatment === "on") ||
                  !item?.featureFlag ||
                  isTestRunning()
                    ? ProcessMenuItem(item)
                    : null
                )}
            </Stack>
          );
        }}
      </SplitTreatments>
    </Box>
  );
};

const ProcessMenuItem = (menuItem: MENU_ITEM) =>
  menuItem?.path ? (
    <MenuLink key={menuItem.key} to={menuItem.path}>
      {menuItem.label}
    </MenuLink>
  ) : menuItem.children ? (
    <MenuParent key={menuItem.key} menuItem={menuItem.children}>
      {menuItem.label}
    </MenuParent>
  ) : (
    <MenuPlaceHolder key={menuItem.key}>{menuItem.label}</MenuPlaceHolder>
  );

const NavBarContainer = ({
  children,
  setCorsErrorModalOpen,
  ...props
}: {
  children: any;
  setCorsErrorModalOpen: Dispatch<SetStateAction<boolean>>;
  props?: FlexProps;
}) => {
  return (
    <Flex
      as="nav"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      {...props}
    >
      {children}
      <Home {...{ setCorsErrorModalOpen }} />
    </Flex>
  );
};
