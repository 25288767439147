import { GetEventAdjustmentsForTableQuery } from "../../../generated/graphql";
import { EventAdjustmentTableRow } from "../EventAdjustmentTable/EventAdjustmentTable";

export const getEventAdjustmentLayersDataRows = function (
  eventAdjustmentsResult: GetEventAdjustmentsForTableQuery | undefined
) {
  return (
    eventAdjustmentsResult?.eventAdjustments?.reduce<EventAdjustmentTableRow[]>(
      (tableRows, eventAdjustment) => {
        if (
          eventAdjustment?.adjustments &&
          eventAdjustment?.id &&
          eventAdjustment?.name
        ) {
          eventAdjustment?.adjustments.forEach((adjustment) => {
            if (
              adjustment?.locationId &&
              adjustment?.day &&
              adjustment?.locationTitle &&
              adjustment?.operationalDate &&
              adjustment?.hourlyAdjustments
            ) {
              tableRows.push({
                layer: eventAdjustment.name,
                date: adjustment.operationalDate,
                locationId: adjustment.locationId,
                locationName: adjustment.locationTitle,
                day: adjustment.day,
                hours: adjustment.hourlyAdjustments,
              });
            }
          });
        }
        return tableRows;
      },
      []
    ) ?? []
  );
};
