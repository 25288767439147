import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import { GraphQLError } from "graphql";
import { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { AdjustedDriverHoursRunsSelect } from "../../components/AdjustedDriverHoursRunsSelect/AdjustedDriverHoursRunsSelect";
import { CancelJobsButton } from "../../components/CancelJobsButton/CancelJobsButton";
import { CountrySelect } from "../../components/CountrySelect/CountrySelect";
import { GPPopup } from "../../components/GPPopup/GPPopup";
import { UploadAdjustedDriverHours } from "../../components/UploadAdjustedDriverHours/UploadAdjustedDriverHours";
import { UploadIncrementalAdjustedDriverHours } from "../../components/UploadIncrementalAdjustedDriverHours/UploadIncrementalAdjustedDriverHours";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import {
  GetRunsQuery,
  useApproveAndReleaseShiftsMutation,
  useGetLatestJobStatusQuery,
  useGetMostRecentAdjustedHoursUploadDateQuery,
  useGetMostRecentOdhTransformRunTimestampsLazyQuery,
  useGetMostRecentOvfSubmitDateQuery,
  useGetMostRecentReportingBundleUploadTimestampQuery,
  useGetMostRecentShiftsApprovalTimestampQuery,
  useGetMostRecentShiftsPublishTimestampQuery,
  useGetOdhLocationsLazyQuery,
  useGetRunsLazyQuery,
  useGetSasUrlForFileInDsBlobStorageLazyQuery,
  useGetShifterPipelineRunsIdQuery,
  useGetShifterStatusQuery,
  usePublishShifterPipelineMutation,
  useStartShifterPipelineMutation,
  useStartTransformMutation,
  useTriggerJobRunMutation,
  useUploadShiftsReportingBundleMutation,
  useValidateShifterPipelineMutation,
} from "../../generated/graphql";
import { exportCSVFile } from "../../helpers/csv/exportToCSV";
import { constants } from "../../helpers/general/constants";
import {
  formatLongDateString,
  getISODateOfCurrentOperationalMonday,
  getISODateOfNextOperationalMonday,
} from "../../helpers/general/date";
import { getNumbersBetween } from "../../helpers/general/getNumbersBetween";
import { getGraphQLErrorsAsHumanReadableStrings } from "../../helpers/graphql/getGraphQLErrorsAsHumanReadableStrings";
import { getODHCSVFromODHLocations } from "../../helpers/odhTransform/getODHCSVFromODHLocations";
import { isTestRunning } from "../../helpers/test/isTestRunning";
import { downloadUrl } from "../../helpers/url/downloadUrl";
import { GPPopupOption } from "./GPPopupOption/GPPopupOption";
import { GPPopupSecret } from "./GPPopupSecret/GPPopupSecret";
import { LocationsByDayMenu } from "./LocationsByDayMenu/LocationsByDayMenu";
import { LocationsByHourMenu } from "./LocationsByHourMenu/LocationsByHourMenu";
import { LocationsByWeekMenu } from "./LocationsByWeekMenu/LocationsByWeekMenu";
import { TotalByDayMenu } from "./TotalByDayMenu/TotalByDayMenu";
import { TotalByWeekMenu } from "./TotalByWeekMenu/TotalByWeekMenu";
import { shifterPipelineState } from "./constants/shifterPipelineState";
import { POPUP_MESSAGES } from "./helpers/popupMessages";
import { muiShiftsCsvJob, OdhTransformJob, theShifterJob } from "./jobs";
import styles from "./styles.module.css";

export const ShiftQA = () => {
  const secondInMilliseconds = 1000;
  const minuteInMilliseconds = 60 * secondInMilliseconds;

  const [popupHeader, setPopupHeader] = useState<string>("");
  const [
    triggerAdjustedDriverHoursRunsSelectRefresh,
    setTriggerAdjustedDriverHoursRunsSelectRefresh,
  ] = useState<number>(0);
  const [shifterStatus, setShifterStatus] = useState<string>("");
  const [shifterDatabricksRunId, setShifterDatabricksRunId] =
    useState<number>();

  const [popupMessage, setPopupMessage] = useState<string | React.ReactElement>(
    ""
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenOptionApprove,
    onOpen: onOpenOptionApprove,
    onClose: onCloseOptionApprove,
  } = useDisclosure();
  const {
    isOpen: isOpenOptionPublish,
    onOpen: onOpenOptionPublish,
    onClose: onCloseOptionPublish,
  } = useDisclosure();
  const {
    isOpen: isOpenSecret,
    onOpen: onOpenSecret,
    onClose: onCloseSecret,
  } = useDisclosure();

  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );
  const [selectedAdjustedDHRunId, setSelectedAdjustedDHRunId] =
    useState<number>(-1);

  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [runningODHTransform, setRunningODHTransform] =
    useState<boolean>(false);

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
    setSelectedAdjustedDHRunId(-1);
    setSelectedCountry("");
  }

  function handleDPHoursRunsSelect(runId: number | undefined) {
    if (!runId) {
      runId = -1;
    }
    setSelectedAdjustedDHRunId(runId);
  }

  function isCurrentWeekSelected() {
    return selectedWeek === getISODateOfCurrentOperationalMonday();
  }

  function handleCountrySelect(country: string) {
    setSelectedCountry(country);
  }

  const [
    getMostRecentOdhTransformRunTimestamps,
    {
      data: mostRecentODHTransformRunTimestamps,
      loading: mostRecentODHTransformRunTimestampsIsLoading,
    },
  ] = useGetMostRecentOdhTransformRunTimestampsLazyQuery();

  const {
    data: mostRecentAdjustedHoursUploadDate,
    loading: mostRecentAdjustedHoursUploadDateIsLoading,
  } = useGetMostRecentAdjustedHoursUploadDateQuery({
    skip: !selectedWeek,
    variables: {
      operationalWeekStartDate: selectedWeek,
    },
  });

  const {
    data: mostRecentShiftsApprovalTimestamp,
    loading: mostRecentShiftsApprovalTimestampIsLoading,
  } = useGetMostRecentShiftsApprovalTimestampQuery({
    skip:
      !selectedWeek || !selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0,
    variables: {
      operationalWeekStartDate: selectedWeek,
      adjustedDHRunId: selectedAdjustedDHRunId,
    },
  });

  const {
    data: mostRecentReportingBundleUploadTimestamp,
    loading: mostRecentReportingBundleUploadTimestampIsLoading,
  } = useGetMostRecentReportingBundleUploadTimestampQuery({
    skip:
      !selectedWeek || !selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0,
    variables: {
      operationalWeekStartDate: selectedWeek,
      adjustedDHRunId: selectedAdjustedDHRunId,
    },
  });

  const {
    data: mostRecentShiftsPublishTimestamp,
    loading: mostRecentShiftsPublishTimestampIsLoading,
  } = useGetMostRecentShiftsPublishTimestampQuery({
    skip:
      !selectedWeek || !selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0,
    variables: {
      operationalWeekStartDate: selectedWeek,
      adjustedDHRunId: selectedAdjustedDHRunId,
    },
  });

  const {
    data: mostRecentOvfSubmitDateData,
    loading: gettinMostRecentOvfSubmitDate,
  } = useGetMostRecentOvfSubmitDateQuery({
    variables: {
      selectedWeek: selectedWeek,
    },
  });

  const [muiShiftsCsvFilename, setMuiShiftsCsvFilename] =
    useState<string>("mui_shifts_qa.csv");
  const [muiShiftsCsvJobHasBeenRun, setMuiShiftsCsvJobHasBeenRun] =
    useState<boolean>(false);

  const {
    data: latestMuiShiftsCsvJobStatusData,
    loading: latestMuiShiftsCsvJobStatusIsLoading,
  } = useGetLatestJobStatusQuery({
    variables: {
      jobId: muiShiftsCsvJob.id,
      environment: "current",
    },
    pollInterval: 10 * secondInMilliseconds,
  });

  const [startMuiShiftsCsvJob, { loading: startMuiShiftsCsvJobIsLoading }] =
    useTriggerJobRunMutation({
      variables: {
        jobId: muiShiftsCsvJob.id,
        queryParams: {
          operationalWeekStartDate: selectedWeek,
          output_filename: muiShiftsCsvFilename,
        },
        environment: "current",
      },
      refetchQueries: ["GetLatestJobStatus", "getRuns"],
    });

  const [lastMuiShiftsCsvJobRunStatus, setLastMuiShiftsCsvJobRunStatus] =
    useState(latestMuiShiftsCsvJobStatusData?.getLatestJobStatus?.status ?? "");
  const [lastMuiShiftsCsvJobRunStartDate, setLastMuiShiftsCsvJobRunStartDate] =
    useState(
      latestMuiShiftsCsvJobStatusData?.getLatestJobStatus?.startDate ?? ""
    );
  const [lastMuiShiftsCsvJobRunEndDate, setLastMuiShiftsCsvJobRunEndDate] =
    useState(
      latestMuiShiftsCsvJobStatusData?.getLatestJobStatus?.endDate ?? ""
    );

  useEffect(() => {
    if (
      !mostRecentODHTransformRunTimestampsIsLoading &&
      !mostRecentODHTransformRunTimestamps
    ) {
      void getMostRecentOdhTransformRunTimestamps();
    }
    const interval = setInterval(() => {
      if (runningODHTransform) {
        // only check recent timestamps if running ODH Transform, so if refresh page, then this polling will stop
        // TODO: Get ODH Transform from server side, and start polling based on whether it has running ODH Transform
        void getMostRecentOdhTransformRunTimestamps();
      }
    }, minuteInMilliseconds);
    return () => clearInterval(interval);
  });

  const [lastUpload, setLastUpload] = useState<string>();
  const [lastRelease, setLastRelease] = useState<string>();
  const [lastPublish, setLastPublish] = useState<string>();
  const [
    lastShiftsReportingBundleUploadTimestamp,
    setShiftsLastReportingBundleUploadTimestamp,
  ] = useState<string>();

  const [startTransform, { loading: transformMutationLoading }] =
    useStartTransformMutation({
      refetchQueries: ["GetMostRecentODHTransformRunTimestamps", "getRuns"],
      variables: {
        selectedWeek: selectedWeek,
      },
    });

  async function handleStartTransform() {
    try {
      setRunningODHTransform(true);
      await startTransform();
      setRunningODHTransform(false);
    } catch (err) {
      let message = "Unknown Error!";
      if (err instanceof Error) message = err.message;
      setPopupHeader("Error");
      setPopupMessage(message);
      onOpen();
      setRunningODHTransform(false);
    }
  }

  const [
    uploadShiftsReportingBundle,
    { loading: uploadShiftsReportingBundleLoading },
  ] = useUploadShiftsReportingBundleMutation({
    context: {
      skipDefaultErrorHandling: true,
    },
  });

  const [approveAndReleaseShifts, { loading: approveAndReleaseShiftsLoading }] =
    useApproveAndReleaseShiftsMutation({
      context: {
        skipDefaultErrorHandling: true,
      },
    });

  const [validateShifterPipeline, { loading: validateShifterPipelineLoading }] =
    useValidateShifterPipelineMutation({
      context: {
        skipDefaultErrorHandling: true,
      },
    });

  const [publishShifterPipeline, { loading: publishShifterPipelineLoading }] =
    usePublishShifterPipelineMutation({
      context: {
        skipDefaultErrorHandling: true,
      },
    });

  const [getSasUrlForFileInDSBlobStorage] =
    useGetSasUrlForFileInDsBlobStorageLazyQuery({
      onCompleted: (data) => {
        downloadUrl(data.getSasUrlForFileInDSBlobStorage ?? "");
      },
    });

  const [getRuns, { data: getRunsData, loading: getRunsLoading }] =
    useGetRunsLazyQuery({
      onCompleted: (data) => processAndSetShiftRunData(data),
    });
  const [
    getOdhTransformData,
    { data: odhTransformData, loading: odhTransformDataLoading },
  ] = useGetOdhLocationsLazyQuery();

  const [runningShifts, setRunShiftsLoading] = useState<boolean>(false);

  const shiftPassword = process.env.REACT_APP_SHIFT_ADMIN_PASSWORD ?? "";

  function processAndSetShiftRunData(runsData: GetRunsQuery) {
    const lifeCycleState =
      runsData?.getRunsList?.runs?.[0]?.state?.life_cycle_state;
    if (
      lifeCycleState &&
      ["PENDING", "RUNNING", "TERMINATING"].includes(lifeCycleState)
    ) {
      setRunShiftsLoading(true);
      setShiftRun(runsData?.getRunsList?.runs?.[1]?.end_time ?? "");
      setLastShiftRunDatabricksRunId(
        runsData?.getRunsList?.runs?.[1]?.run_id ?? 0
      );
      setShiftRunStatus(
        runsData?.getRunsList?.runs?.[1]?.state?.result_state ?? ""
      );
    } else {
      setRunShiftsLoading(false);
      setShiftRun(runsData?.getRunsList?.runs?.[0]?.end_time ?? "");
      setLastShiftRunDatabricksRunId(
        runsData?.getRunsList?.runs?.[0]?.run_id ?? 0
      );
      setShiftRunStatus(
        runsData?.getRunsList?.runs?.[0]?.state?.result_state ?? ""
      );
    }
  }

  useEffect(() => {
    if (!getRunsLoading && !getRunsData) {
      void getRuns({
        variables: { runsListParams: { job_id: theShifterJob.id } },
      });
    }
    const interval = setInterval(() => {
      if (runningShifts) {
        void getRuns({
          variables: { runsListParams: { job_id: theShifterJob.id } },
        });
      }
    }, minuteInMilliseconds);
    return () => clearInterval(interval);
  }, [
    getRuns,
    getRunsData,
    getRunsLoading,
    theShifterJob.id,
    minuteInMilliseconds,
    runningShifts,
  ]);

  const [isShifterPipelineRunning, setShifterPipelineRunning] =
    useState<boolean>(false);

  const shifterNotRunningStates = [
    shifterPipelineState.readyForQa,
    shifterPipelineState.submitted,
    shifterPipelineState.valid,
    shifterPipelineState.complete,
    shifterPipelineState.invalid,
    shifterPipelineState.raisingErrors,
  ];

  useGetShifterStatusQuery({
    skip: !selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0,
    variables: {
      adjustedDHRunId: selectedAdjustedDHRunId,
    },
    onCompleted: (data) => {
      const isRunning =
        data.shifterStatus &&
        !shifterNotRunningStates.includes(data.shifterStatus.state);
      setShifterStatus(data?.shifterStatus?.state ?? "");
      setShifterDatabricksRunId(
        data?.shifterStatus?.databricksRunId ?? undefined
      );
      setShifterPipelineRunning(!!isRunning);
    },
    pollInterval: minuteInMilliseconds,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: shifterPipelineRunsIdData,
    refetch: refetchShifterPipelineRunsId,
  } = useGetShifterPipelineRunsIdQuery({
    skip: !selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0,
    variables: {
      operationalWeekStartDate: selectedWeek,
      adjustedDHRunId: selectedAdjustedDHRunId,
    },
  });

  const [startShifterPipeline] = useStartShifterPipelineMutation();

  async function handleRunShifts() {
    if (!selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0) return;
    setRunShiftsLoading(true);
    setShifterPipelineRunning(true);
    const result = await startShifterPipeline({
      variables: {
        operationalWeekStartDate: selectedWeek,
        adjustedDHRunId: selectedAdjustedDHRunId,
      },
      refetchQueries: ["GetShifterStatus", "getRuns"],
    });
    if (result.errors?.length) {
      setRunShiftsLoading(false);
    }
    await refetchShifterPipelineRunsId();
    setShifterPipelineRunning(false);
  }

  async function handleShiftsReportingBundleUpload() {
    if (!selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0) return;
    const response = await uploadShiftsReportingBundle({
      variables: {
        operationalWeekStartDate: selectedWeek,
        adjustedDHRunId: selectedAdjustedDHRunId,
      },
    });

    if (response.errors) {
      showErrorPopup(response.errors);
      return;
    }

    showSuccessPopup(POPUP_MESSAGES.UploadReportingBundleSuccess);
  }

  async function uploadShifts() {
    if (!selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0) return;
    onCloseOptionApprove();
    setShifterPipelineRunning(true);
    const response = await approveAndReleaseShifts({
      variables: {
        operationalWeekStartDate: selectedWeek,
        adjustedDHRunId: selectedAdjustedDHRunId,
      },
    });
    setShifterPipelineRunning(false);

    if (response.errors) {
      showErrorPopup(
        response.errors,
        'Click "Approve and Release" again to retry'
      );
      return;
    }
    showSuccessPopup(
      `${POPUP_MESSAGES.ApproveAndReleaseShiftsSuccess}\n${
        response.data?.approveAndReleaseShifts.message ?? ""
      }`
    );
  }

  async function validateShifts() {
    if (!selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0) return;
    onCloseOptionApprove();
    setShifterPipelineRunning(true);
    const response = await validateShifterPipeline({
      variables: {
        operationalWeekStartDate: selectedWeek,
        adjustedDHRunId: selectedAdjustedDHRunId,
      },
    });
    setShifterPipelineRunning(false);

    if (response.errors) {
      showErrorPopup(response.errors);
      return;
    }
    showWarningPopup(
      `${POPUP_MESSAGES.ValidateShifterPipelineSuccess}\n${
        response.data?.validateShifterShifts.message ?? ""
      }`,
      onOpenOptionPublish
    );
  }

  async function publishShifts() {
    if (!selectedAdjustedDHRunId || selectedAdjustedDHRunId < 0) return;
    onCloseOptionPublish();
    setShifterPipelineRunning(true);
    const response = await publishShifterPipeline({
      variables: {
        operationalWeekStartDate: selectedWeek,
        adjustedDHRunId: selectedAdjustedDHRunId,
      },
    });
    setShifterPipelineRunning(false);

    if (response.errors) {
      showErrorPopup(
        response.errors,
        'Click "Validate and Publish" again to retry'
      );
      return;
    }
    showSuccessPopup(
      `${POPUP_MESSAGES.PublishPipelineSuccess}\n${
        response.data?.publishShifterShifts.message ?? ""
      }`
    );
  }

  async function handleShiftsUpload() {
    if (
      mostRecentShiftsApprovalTimestamp?.mostRecentShiftsApprovalTimestamp !=
      null
    ) {
      showWarningPopup(
        POPUP_MESSAGES.ApproveAndReleaseShiftsWarning,
        onOpenOptionApprove
      );
      return;
    }
    await uploadShifts();
  }

  function showSuccessPopup(message: string) {
    setPopupHeader("Success");
    setPopupMessage(<section>{message}</section>);
    onOpen();
    // onOpen seems to open both modals, but we only want the one
    onCloseOptionApprove();
  }

  function showWarningPopup(message: string, callback: () => void) {
    setPopupHeader("Warning");
    setPopupMessage(<section>{message}</section>);
    callback();
  }

  function showSecretPopup() {
    setPopupHeader("Enter Password");
    setPopupMessage(<section></section>);
    onOpenSecret();
  }

  function showErrorPopup(
    errors: readonly GraphQLError[],
    additionalMessage?: string
  ) {
    setPopupHeader("Graphql Error");
    setPopupMessage(
      <section>
        {getGraphQLErrorsAsHumanReadableStrings(errors)}
        <div>{additionalMessage}</div>
      </section>
    );
    onOpen();
    onCloseOptionApprove();
  }

  const [lastRunEndDate, setLastRunEndDate] = useState(
    mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformRun?.endDate ??
      ""
  );

  const [lastRunStartDate, setLastRunStartDate] = useState(
    mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformRun?.startDate ??
      ""
  );

  const [lastRunStatus, setLastRunStatus] = useState(
    mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformRun?.status ?? ""
  );

  const [lastShiftRun, setShiftRun] = useState<string>(
    getRunsData?.getRunsList?.runs?.[0]?.start_time ?? ""
  );

  const [lastShiftRunDatabricksRunId, setLastShiftRunDatabricksRunId] =
    useState<number>(getRunsData?.getRunsList?.runs?.[1]?.run_id ?? 0);

  const [lastShiftRunStatus, setShiftRunStatus] = useState<string>(
    getRunsData?.getRunsList?.runs?.[1]?.state?.result_state ?? ""
  );

  const [batchId, setBatchId] = useState(
    mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformJobDate?.batch ??
      ""
  );

  useEffect(
    function refreshMostRecentSubmissionTimestamps() {
      setLastRunEndDate(
        mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformRun
          ?.endDate ?? ""
      );
      setLastRunStartDate(
        mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformRun
          ?.startDate ?? ""
      );
      setLastRunStatus(
        mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformRun
          ?.status ?? ""
      );

      setBatchId(
        mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformJobDate
          ?.batch ?? ""
      );
      setLastUpload(
        mostRecentAdjustedHoursUploadDate?.mostRecentAdjustedHoursUploadDate
          ?.date ?? ""
      );
      setLastRelease(
        mostRecentShiftsApprovalTimestamp?.mostRecentShiftsApprovalTimestamp ??
          ""
      );
      setLastPublish(
        mostRecentShiftsPublishTimestamp?.mostRecentShiftsPublishTimestamp ?? ""
      );
      setShiftsLastReportingBundleUploadTimestamp(
        mostRecentReportingBundleUploadTimestamp?.mostRecentShiftsReportingBundleUploadTimestamp ??
          ""
      );
      setLastMuiShiftsCsvJobRunStatus(
        latestMuiShiftsCsvJobStatusData?.getLatestJobStatus?.status ?? ""
      );
      setLastMuiShiftsCsvJobRunStartDate(
        latestMuiShiftsCsvJobStatusData?.getLatestJobStatus?.startDate ?? ""
      );
      setLastMuiShiftsCsvJobRunEndDate(
        latestMuiShiftsCsvJobStatusData?.getLatestJobStatus?.endDate ?? ""
      );
    },
    [
      mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformJobDate?.batch,
      mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformJobDate?.date,
      mostRecentAdjustedHoursUploadDate?.mostRecentAdjustedHoursUploadDate
        ?.date,
      mostRecentReportingBundleUploadTimestamp?.mostRecentShiftsReportingBundleUploadTimestamp,
      mostRecentShiftsApprovalTimestamp?.mostRecentShiftsApprovalTimestamp,
      mostRecentShiftsPublishTimestamp?.mostRecentShiftsPublishTimestamp,
      mostRecentODHTransformRunTimestamps?.mostRecentOdhTransformRun,
      latestMuiShiftsCsvJobStatusData?.getLatestJobStatus?.status,
    ]
  );

  useEffect(() => {
    if (
      lastMuiShiftsCsvJobRunStatus === "SUCCESS" &&
      muiShiftsCsvJobHasBeenRun
    ) {
      void getSasUrlForFileInDSBlobStorage({
        variables: { filename: "mui_shifts/" + muiShiftsCsvFilename },
      });
    }
  }, [lastMuiShiftsCsvJobRunStatus]);

  useEffect(() => {
    if (odhTransformData && !odhTransformDataLoading) {
      const headers = [
        "date",
        "id",
        "market",
        "dow",
        "mode",
        ...getNumbersBetween(0, constants.time.HOURS_IN_A_DAY - 1).map(
          (number) => `ovf${number}`
        ),
        "ovfTotal",
        "er1",
        "er2",
        ...getNumbersBetween(0, constants.time.HOURS_IN_A_DAY - 1).map(
          (number) => `odh${number}`
        ),
        "odhTotal",
        "er3",
        "er4",
        ...getNumbersBetween(0, constants.time.HOURS_IN_A_DAY - 1).map(
          (number) => `hours${number}`
        ),
        "hoursTotal",
      ];

      const odhTransformDataCSV = getODHCSVFromODHLocations(odhTransformData);

      exportCSVFile(
        odhTransformDataCSV,
        headers,
        "OdhTransformDocument",
        false
      );
    }
  }, [odhTransformData, odhTransformDataLoading]);

  return (
    <Box pl={8} pr={8}>
      <GPPopup
        isOpen={isOpen}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onClose}
      />
      <GPPopupOption
        isOpen={isOpenOptionApprove}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onCloseOptionApprove}
        onContinue={() => {
          void uploadShifts();
        }}
      />
      <GPPopupOption
        isOpen={isOpenOptionPublish}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onCloseOptionPublish}
        onContinue={() => {
          onCloseOptionPublish();
          showSecretPopup();
        }}
      />

      <GPPopupSecret
        isOpen={isOpenSecret}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        secret={shiftPassword}
        onClose={onCloseSecret}
        onContinue={() => {
          void publishShifts();
        }}
      />
      <Flex justifyContent="flex-start">
        <Box className={styles.box}>
          <WeekSelect
            onChange={handleWeekSelect}
            selectedWeek={selectedWeek}
            showCurrentWeek
            testId="shiftqa-week-select"
          />
        </Box>
        <Box className={styles.mostRecentOvfSubmitText}>
          <span>
            {gettinMostRecentOvfSubmitDate ? (
              <Spinner size="xl" />
            ) : mostRecentOvfSubmitDateData?.mostRecentOvfSubmit ? (
              `Last OVF submitted ${formatLongDateString(
                mostRecentOvfSubmitDateData?.mostRecentOvfSubmit
              )}`
            ) : (
              "No OVF Submitted This Week"
            )}
          </span>
        </Box>
      </Flex>
      <Flex justifyContent="flex-start">
        <FormControl>
          <table>
            <tbody>
              <tr>
                <td valign="top">
                  <Box className={styles.box}>
                    <Flex justifyContent="flex-start">
                      <label className={styles.headerText}>
                        Driver Partner Hours
                      </label>
                    </Flex>

                    <ButtonGroup variant="outline">
                      <Button
                        isDisabled={
                          mostRecentODHTransformRunTimestampsIsLoading ||
                          transformMutationLoading
                        }
                        isLoading={
                          mostRecentODHTransformRunTimestampsIsLoading ||
                          transformMutationLoading
                        }
                        loadingText={
                          transformMutationLoading ? "Running..." : "Loading..."
                        }
                        onClick={() => {
                          void handleStartTransform();
                        }}
                        colorScheme="blue"
                        width="350px"
                        data-test-id="shiftqa-run-odh-transform"
                      >
                        Run ODH transform
                      </Button>
                      <CancelJobsButton job={OdhTransformJob} />
                    </ButtonGroup>

                    <Flex justifyContent="flex-start">
                      <FormHelperText data-test-id="shiftqa-odh-status">
                        {mostRecentODHTransformRunTimestampsIsLoading ? (
                          <Spinner size="xl" />
                        ) : (
                          <p>
                            Last run started on:{" "}
                            {formatLongDateString(lastRunStartDate)},<br />
                            ended on {formatLongDateString(lastRunEndDate)},
                            <br />
                            with status {lastRunStatus}
                          </p>
                        )}
                      </FormHelperText>
                    </Flex>
                    <ButtonGroup variant="outline" paddingTop="10px">
                      <Button
                        leftIcon={<FaDownload />}
                        isDisabled={
                          !batchId ||
                          batchId === "" ||
                          mostRecentODHTransformRunTimestampsIsLoading ||
                          !selectedWeek
                        }
                        isLoading={mostRecentODHTransformRunTimestampsIsLoading}
                        loadingText="Loading..."
                        onClick={() =>
                          void getOdhTransformData({
                            variables: {
                              batchId: batchId,
                              operationalWeekStartDate: selectedWeek,
                            },
                          })
                        }
                        colorScheme="blue"
                        width="350px"
                        data-test-id="shiftqa-download-odh-transform"
                      >
                        Download ODH DP Hours
                      </Button>
                    </ButtonGroup>
                    <Flex justifyContent="flex-start">
                      <FormHelperText>
                        Download DP Hours that generated by latest ODH Transform
                        Run
                      </FormHelperText>
                    </Flex>
                    <Flex justifyContent="flex-start" paddingTop="10px">
                      <UploadAdjustedDriverHours
                        selectedWeek={selectedWeek}
                        onSuccess={() => {
                          setTriggerAdjustedDriverHoursRunsSelectRefresh(
                            triggerAdjustedDriverHoursRunsSelectRefresh + 1
                          );
                        }}
                        testId="shiftqa-upload-adjusted-driver-hours"
                      />
                    </Flex>
                    <Flex justifyContent="flex-start" paddingTop="10px">
                      <UploadIncrementalAdjustedDriverHours
                        selectedWeek={selectedWeek}
                        onSuccess={() =>
                          setTriggerAdjustedDriverHoursRunsSelectRefresh(
                            triggerAdjustedDriverHoursRunsSelectRefresh + 1
                          )
                        }
                        testId="shiftqa-upload-incremental-adjusted-driver-hours"
                      />
                    </Flex>
                    <Flex justifyContent="flex-start">
                      <FormHelperText>
                        {mostRecentAdjustedHoursUploadDateIsLoading ? (
                          <Spinner size="xl" />
                        ) : (
                          `Last upload on ${formatLongDateString(lastUpload)}`
                        )}
                      </FormHelperText>
                    </Flex>
                  </Box>
                </td>
                <td>
                  <div className={styles.verticalBar}></div>
                </td>
                <td valign="top">
                  <Box className={styles.box}>
                    <Flex justifyContent="flex-start">
                      <label className={styles.headerText}>Shifts</label>
                    </Flex>
                    <Flex>
                      <AdjustedDriverHoursRunsSelect
                        onChange={handleDPHoursRunsSelect}
                        selectedWeek={selectedWeek}
                        selectedAdjustedDHRunId={selectedAdjustedDHRunId}
                        triggerRefresh={
                          triggerAdjustedDriverHoursRunsSelectRefresh
                        }
                        testId="shiftqa-adjusted-driver-hours-runs-select"
                      />
                    </Flex>
                    <Flex justifyContent="flex-start" marginBottom={5}>
                      {shifterPipelineRunsIdData?.shifterPipelineRunsId
                        ? `Run ID: ${
                            shifterPipelineRunsIdData.shifterPipelineRunsId
                          }${shifterStatus ? ", State: " + shifterStatus : ""}${
                            shifterDatabricksRunId
                              ? ", Databricks Run ID: " +
                                shifterDatabricksRunId.toString()
                              : ""
                          }`
                        : "No run started for selected driver partner hours yet"}
                    </Flex>
                    <ButtonGroup variant="outline" paddingTop="10px">
                      <Button
                        isDisabled={
                          runningShifts ||
                          isShifterPipelineRunning ||
                          !selectedAdjustedDHRunId ||
                          selectedAdjustedDHRunId < 0
                        }
                        isLoading={runningShifts}
                        loadingText="Running..."
                        colorScheme="blue"
                        width="350px"
                        onClick={() => {
                          void handleRunShifts();
                        }}
                        data-test-id="shiftqa-run-shifts"
                      >
                        Run Shifts
                      </Button>
                      <CancelJobsButton job={theShifterJob} />
                    </ButtonGroup>
                    <Flex justifyContent="flex-start">
                      <FormHelperText>
                        {getRunsLoading ? (
                          <Spinner size="xl" />
                        ) : (
                          `Databricks Run ID ${lastShiftRunDatabricksRunId}: last run completed on ${formatLongDateString(
                            lastShiftRun
                          )} with status ${lastShiftRunStatus}`
                        )}
                      </FormHelperText>
                    </Flex>
                    <ButtonGroup
                      variant="outline"
                      spacing="6"
                      paddingTop="10px"
                    >
                      <Button
                        isDisabled={
                          mostRecentReportingBundleUploadTimestampIsLoading ||
                          uploadShiftsReportingBundleLoading ||
                          !selectedAdjustedDHRunId ||
                          selectedAdjustedDHRunId < 0
                        }
                        isLoading={
                          mostRecentReportingBundleUploadTimestampIsLoading ||
                          uploadShiftsReportingBundleLoading
                        }
                        loadingText={
                          uploadShiftsReportingBundleLoading
                            ? "Uploading..."
                            : "Loading..."
                        }
                        onClick={() => {
                          void handleShiftsReportingBundleUpload();
                        }}
                        colorScheme="blue"
                        width="350px"
                        data-test-id="shiftqa-upload-ovf"
                      >
                        Upload OVF and Hours Reporting Bundle
                      </Button>
                    </ButtonGroup>
                    <Flex justifyContent="flex-start">
                      <FormHelperText>
                        {mostRecentReportingBundleUploadTimestampIsLoading ? (
                          <Spinner size="xl" />
                        ) : (
                          `Last release on ${formatLongDateString(
                            lastShiftsReportingBundleUploadTimestamp
                          )}`
                        )}
                      </FormHelperText>
                    </Flex>
                    <ButtonGroup
                      variant="outline"
                      spacing="6"
                      paddingTop="10px"
                    >
                      <Button
                        isDisabled={
                          mostRecentShiftsApprovalTimestampIsLoading ||
                          approveAndReleaseShiftsLoading ||
                          isShifterPipelineRunning ||
                          runningShifts ||
                          !selectedAdjustedDHRunId ||
                          selectedAdjustedDHRunId < 0
                        }
                        isLoading={isShifterPipelineRunning && !runningShifts}
                        loadingText="Approving..."
                        onClick={() => {
                          void handleShiftsUpload();
                        }}
                        colorScheme="blue"
                        width="350px"
                        data-test-id="shiftqa-approve-and-release-shifts"
                      >
                        Approve and Release Shifts
                      </Button>
                    </ButtonGroup>
                    <Flex justifyContent="flex-start">
                      <FormHelperText>
                        {mostRecentShiftsApprovalTimestampIsLoading ? (
                          <Spinner size="xl" />
                        ) : (
                          `Last release on ${formatLongDateString(lastRelease)}`
                        )}
                      </FormHelperText>
                    </Flex>
                    <SplitTreatments names={["publishShiftsEnabled"]}>
                      {({ treatments: { publishShiftsEnabled }, isReady }) => {
                        const isFeatureOn =
                          isReady && publishShiftsEnabled.treatment === "on";
                        if (
                          (isFeatureOn || isTestRunning()) &&
                          isCurrentWeekSelected()
                        ) {
                          return (
                            <div>
                              {" "}
                              <ButtonGroup
                                variant="outline"
                                spacing="6"
                                paddingTop="10px"
                              >
                                <Button
                                  isDisabled={
                                    approveAndReleaseShiftsLoading ||
                                    isShifterPipelineRunning ||
                                    runningShifts ||
                                    validateShifterPipelineLoading ||
                                    publishShifterPipelineLoading ||
                                    !selectedAdjustedDHRunId ||
                                    selectedAdjustedDHRunId < 0
                                  }
                                  isLoading={
                                    validateShifterPipelineLoading ||
                                    publishShifterPipelineLoading
                                  }
                                  loadingText="Running..."
                                  onClick={() => {
                                    void validateShifts();
                                  }}
                                  colorScheme="blue"
                                  width="350px"
                                  data-test-id="shiftqa-validate-and-publish-shifts"
                                >
                                  Validate and Publish Shifts
                                </Button>
                              </ButtonGroup>
                              <Flex justifyContent="flex-start">
                                <FormHelperText>
                                  {mostRecentShiftsPublishTimestampIsLoading ? (
                                    <Spinner size="xl" />
                                  ) : (
                                    `Last publish on ${formatLongDateString(
                                      lastPublish
                                    )}`
                                  )}
                                </FormHelperText>
                              </Flex>
                            </div>
                          );
                        }
                        return null;
                      }}
                    </SplitTreatments>
                    <ButtonGroup
                      variant="outline"
                      spacing="6"
                      paddingTop="10px"
                    >
                      <Button
                        isDisabled={
                          startMuiShiftsCsvJobIsLoading ||
                          latestMuiShiftsCsvJobStatusIsLoading ||
                          muiShiftsCsvFilename === "" ||
                          lastMuiShiftsCsvJobRunStatus === "RUNNING"
                        }
                        onClick={() => {
                          setMuiShiftsCsvJobHasBeenRun(true);
                          void startMuiShiftsCsvJob();
                        }}
                        colorScheme="blue"
                        width="350px"
                        data-test-id="shiftqa-download-mui-shifts"
                      >
                        Download &amp; QA MUI Shifts
                      </Button>
                      <CancelJobsButton job={muiShiftsCsvJob} />
                    </ButtonGroup>
                    <Input
                      hidden
                      value={muiShiftsCsvFilename}
                      isDisabled={lastMuiShiftsCsvJobRunStatus === "RUNNING"}
                      onChange={(event: {
                        target: { value: React.SetStateAction<string> };
                      }) => {
                        setMuiShiftsCsvFilename(event.target.value);
                      }}
                      type="text"
                      placeholder="Enter mui shift csv filename"
                    />
                    {!latestMuiShiftsCsvJobStatusIsLoading && (
                      <p>
                        Last run started on:{" "}
                        {formatLongDateString(lastMuiShiftsCsvJobRunStartDate)},
                        <br />
                        ended on{" "}
                        {formatLongDateString(lastMuiShiftsCsvJobRunEndDate)},
                        <br />
                        with status {lastMuiShiftsCsvJobRunStatus}
                      </p>
                    )}
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
        </FormControl>
      </Flex>
      <Flex justifyContent="flex-start">
        {shifterPipelineRunsIdData?.shifterPipelineRunsId &&
          selectedAdjustedDHRunId > 0 && (
            <Box className={styles.box}>
              <CountrySelect
                onChange={handleCountrySelect}
                selectedCountry={selectedCountry}
                testId="shiftqa-country-select"
              />
            </Box>
          )}
      </Flex>
      <Box className={styles.box}>
        {selectedCountry && selectedAdjustedDHRunId > 0 && (
          <TotalByWeekMenu
            selectedCountryCode={selectedCountry}
            operationalWeekStartDate={selectedWeek}
            adjustedDHRunId={selectedAdjustedDHRunId}
          />
        )}
      </Box>
      <Box className={styles.box}>
        {selectedCountry && selectedAdjustedDHRunId > 0 && (
          <LocationsByWeekMenu
            selectedCountryCode={selectedCountry}
            operationalWeekStartDate={selectedWeek}
            adjustedDHRunId={selectedAdjustedDHRunId}
          />
        )}
      </Box>
      <Box className={styles.box}>
        {selectedCountry && selectedAdjustedDHRunId > 0 && (
          <TotalByDayMenu
            selectedCountryCode={selectedCountry}
            operationalWeekStartDate={selectedWeek}
            adjustedDHRunId={selectedAdjustedDHRunId}
          />
        )}
      </Box>
      <Box className={styles.box}>
        {selectedCountry && selectedAdjustedDHRunId > 0 && (
          <LocationsByDayMenu
            selectedCountryCode={selectedCountry}
            operationalWeekStartDate={selectedWeek}
            adjustedDHRunId={selectedAdjustedDHRunId}
          />
        )}
      </Box>
      <Box className={styles.box}>
        {shifterPipelineRunsIdData?.shifterPipelineRunsId &&
          selectedAdjustedDHRunId > 0 && (
            <LocationsByHourMenu
              operationalWeekStartDate={selectedWeek}
              adjustedDHRunId={selectedAdjustedDHRunId}
            />
          )}
      </Box>
    </Box>
  );
};
