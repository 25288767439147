import _ from "lodash";
import {
  GetAdjustedDriverHourLocationsQuery,
  GetOdhLocationsQuery,
} from "../../generated/graphql";
import { getWeeklyOdh } from "../driverHours/getWeeklyOdh";
import { constants } from "../general/constants";
import { getNumbersBetween } from "../general/getNumbersBetween";

interface OdhCSVRow {
  date: string;
  id: string;
  market: string;
  dow: string;
  mode: string;
  hourlyOvfs: string[];
  ovfTotal: string;
  hourlyOdhs: string[];
  odhTotal: string;
  hours: string[];
  hoursTotal: string;
}

type AdjDHCSVRow = Pick<
  OdhCSVRow,
  "date" | "id" | "market" | "dow" | "mode" | "hours" | "hoursTotal"
>;

const firstHeaderRow = formatCSVRow({
  date: "",
  id: "",
  market: "",
  dow: "",
  mode: "",
  hourlyOvfs: ["Volume"].concat([...Array(23).keys()].map(() => "")),
  ovfTotal: "",
  hourlyOdhs: ["ODH"].concat([...Array(23).keys()].map(() => "")),
  odhTotal: "",
  hours: ["Hours"].concat([...Array(23).keys()].map(() => "")),
  hoursTotal: "",
});

const secondHeaderRow = formatCSVRow({
  date: "Date",
  id: "ID",
  market: "Market",
  dow: "DoW",
  mode: "Mode",
  hourlyOvfs: [
    ...getNumbersBetween(
      constants.time.LOCATION_START_HOUR,
      constants.time.HOURS_IN_A_DAY - 1
    ).map((number) => `${number}`),
    ...getNumbersBetween(0, constants.time.LOCATION_START_HOUR - 1).map(
      (number) => `${number}`
    ),
  ],
  ovfTotal: "Total",
  hourlyOdhs: [
    ...getNumbersBetween(
      constants.time.LOCATION_START_HOUR,
      constants.time.HOURS_IN_A_DAY - 1
    ).map((number) => `${number}`),
    ...getNumbersBetween(0, constants.time.LOCATION_START_HOUR - 1).map(
      (number) => `${number}`
    ),
  ],
  odhTotal: "Total",
  hours: [
    ...getNumbersBetween(
      constants.time.LOCATION_START_HOUR,
      constants.time.HOURS_IN_A_DAY - 1
    ).map((number) => `${number}`),
    ...getNumbersBetween(0, constants.time.LOCATION_START_HOUR - 1).map(
      (number) => `${number}`
    ),
  ],
  hoursTotal: "Total",
});

const adjDHfirstHeaderRow = formatAdjDHCSVRow({
  date: "",
  id: "",
  market: "",
  dow: "",
  mode: "",
  hours: ["Hours"].concat([...Array(23).keys()].map(() => "")),
  hoursTotal: "",
});

const adjDHSecondHeaderRow = formatAdjDHCSVRow({
  date: "Date",
  id: "ID",
  market: "Market",
  dow: "DoW",
  mode: "Mode",
  hours: [
    ...getNumbersBetween(
      constants.time.LOCATION_START_HOUR,
      constants.time.HOURS_IN_A_DAY - 1
    ).map((number) => `${number}`),
    ...getNumbersBetween(0, constants.time.LOCATION_START_HOUR - 1).map(
      (number) => `${number}`
    ),
  ],
  hoursTotal: "Total",
});

export function getODHCSVFromODHLocations(
  odhTransformData: GetOdhLocationsQuery
) {
  const csvRows = [firstHeaderRow, secondHeaderRow];

  if (odhTransformData.odhLocations) {
    odhTransformData.odhLocations.forEach((locationWeeklyData) => {
      if (
        !locationWeeklyData ||
        !locationWeeklyData.id ||
        !locationWeeklyData.title ||
        !locationWeeklyData.ovf ||
        !locationWeeklyData.driverHours
      ) {
        // eslint-disable-next-line no-console
        console.log(
          locationWeeklyData?.id
            ? `Missing data for location ${locationWeeklyData.id}`
            : `Missing data for unknown location`
        );

        return;
      }

      const dates = locationWeeklyData.ovf.dates;

      locationWeeklyData.ovf.ovfs.forEach((weeklyDataForMode) => {
        const mode = weeklyDataForMode.mode;
        const ovfs = weeklyDataForMode.values;

        const hoursRaw = locationWeeklyData.driverHours!.hours.find(
          (driverHours) => driverHours.mode === mode
        )!.values;
        const hours = hoursRaw;
        const odhs = getWeeklyOdh(ovfs, hours);

        for (let i = 0; i < constants.time.DAYS_IN_A_WEEK; i++) {
          if (ovfs[i] && hours[i] && odhs[i] && dates[i]) {
            const ovfTotal = ovfs[i].reduce<number>(
              (sum, ovf) => (ovf ? sum + ovf : sum),
              0
            );
            const hoursTotal = hours[i].reduce<number>(
              (sum, hour) => (hour ? sum + hour : sum),
              0
            );
            const odhTotal = hoursTotal
              ? (ovfTotal / hoursTotal).toFixed(2)
              : "";

            csvRows.push(
              formatCSVRow({
                date: `${dates[i]}`,
                id: locationWeeklyData.id,
                market: locationWeeklyData.title,
                dow: constants.time.DAYS_OF_THE_WEEK[i],
                mode,
                hourlyOvfs: ovfs[i].map((ovf) =>
                  typeof ovf === "number" ? `${ovf}` : ""
                ),
                ovfTotal: `${ovfTotal}`,
                hourlyOdhs: odhs[i].map((odh) =>
                  typeof odh === "number" ? `${odh}` : ""
                ),
                odhTotal: `${odhTotal}`,
                hours: hours[i].map((hour) =>
                  typeof hour === "number" ? `${hour}` : ""
                ),
                hoursTotal: `${hoursTotal}`,
              })
            );
          }
        }
      });
    });
  }
  return csvRows;
}

export function getDHCSVFromAdjustedDHs(
  adjustedDHsData: GetAdjustedDriverHourLocationsQuery
) {
  const csvRows = [adjDHfirstHeaderRow, adjDHSecondHeaderRow];

  if (adjustedDHsData.adjustedDriverHourLocations) {
    adjustedDHsData.adjustedDriverHourLocations.forEach(
      (locationWeeklyData) => {
        if (
          !locationWeeklyData ||
          !locationWeeklyData.id ||
          !locationWeeklyData.title ||
          !locationWeeklyData.driverHours
        ) {
          // eslint-disable-next-line no-console
          console.log(
            locationWeeklyData?.id
              ? `Missing data for location ${locationWeeklyData.id}`
              : `Missing data for unknown location`
          );

          return;
        }

        const dates = locationWeeklyData.driverHours.dates;

        locationWeeklyData.driverHours.hours.forEach((weeklyDataForMode) => {
          const mode = weeklyDataForMode.mode;
          const hours = weeklyDataForMode.values;

          for (let i = 0; i < constants.time.DAYS_IN_A_WEEK; i++) {
            if (hours[i] && dates[i]) {
              const hoursTotal = hours[i].reduce<number>(
                (sum, hour) => (hour ? sum + hour : sum),
                0
              );

              csvRows.push(
                formatAdjDHCSVRow({
                  date: `${dates[i]}`,
                  id: locationWeeklyData.id.toString(),
                  market: locationWeeklyData.title ?? "",
                  dow: constants.time.DAYS_OF_THE_WEEK[i],
                  mode,
                  hours: hours[i].map((hour) =>
                    typeof hour === "number" ? `${hour}` : ""
                  ),
                  hoursTotal: `${hoursTotal}`,
                })
              );
            }
          }
        });
      }
    );
  }
  return csvRows;
}

function formatAdjDHCSVRow(row: AdjDHCSVRow) {
  return {
    date: row.date,
    id: row.id,
    market: row.market,
    dow: row.dow,
    mode: row.mode,
    emptySpace1: "",
    emptySpace2: "",
    emptySpace3: "",
    emptySpace4: "",
    ...row.hours.reduce((hoursColumnNamesToOdhMap, hours, index) => {
      hoursColumnNamesToOdhMap[`hours${index}`] = hours;
      return hoursColumnNamesToOdhMap;
    }, {} as Record<string, unknown>),
    hoursTotal: row.hoursTotal,
  };
}

function formatCSVRow(row: OdhCSVRow) {
  return {
    date: row.date,
    id: row.id,
    market: row.market,
    dow: row.dow,
    mode: row.mode,
    ...row.hourlyOvfs.reduce((ovfColumnNamesToOvfMap, ovf, index) => {
      ovfColumnNamesToOvfMap[`ovf${index}`] = ovf;
      return ovfColumnNamesToOvfMap;
    }, {} as Record<string, unknown>),
    ovfTotal: row.ovfTotal,
    emptySpace1: "",
    emptySpace2: "",
    ...row.hourlyOdhs.reduce((odhColumnNamesToOdhMap, odh, index) => {
      odhColumnNamesToOdhMap[`odh${index}`] = odh;
      return odhColumnNamesToOdhMap;
    }, {} as Record<string, unknown>),
    odhTotal: row.odhTotal,
    emptySpace3: "",
    emptySpace4: "",
    ...row.hours.reduce((hoursColumnNamesToOdhMap, hours, index) => {
      hoursColumnNamesToOdhMap[`hours${index}`] = hours;
      return hoursColumnNamesToOdhMap;
    }, {} as Record<string, unknown>),
    hoursTotal: row.hoursTotal,
  };
}
