/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */
import {
  Button,
  Center,
  Container,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { CellProps, usePagination, useTable } from "react-table";
import { usePrevious } from "../../helpers/customHooks/usePrevious";

export interface FetchDataArgs {
  pageSize: number;
  pageIndex: number;
}

interface GPTableProps {
  columns: any[];
  data: any[];
  loading: boolean;
  pageCount: number;
  pageSizeOptions: number[];
  totalCount: number;
  onPageChange?: React.Dispatch<React.SetStateAction<FetchDataArgs>>;
  hiddenColumns?: string[];
  forceRefresh?: boolean;
  setForceRefresh?: (forceRefresh: boolean) => void;
  updateCurrentData?: (args: {
    column: string;
    value: string;
    props: CellProps<any>;
  }) => Promise<void>;
  testId?: string;
}
export const GPTable: React.FC<GPTableProps> = ({ ...props }) => {
  const { forceRefresh, setForceRefresh, updateCurrentData, testId } = props;

  const {
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount = props.pageCount,
    getTableBodyProps,
    prepareRow,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: props.columns,
      data: props.data ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: props.pageSizeOptions[0],
        hiddenColumns: props.hiddenColumns ?? [],
      },
      manualPagination: true,
      pageCount: props.pageCount,
      autoResetPage: false,
      updateCurrentData,
    },
    usePagination
  );

  const previousPageIndex = usePrevious(pageIndex);
  const previousPageSize = usePrevious(pageSize);

  React.useEffect(() => {
    if (
      pageIndex !== previousPageIndex ||
      pageSize !== previousPageSize ||
      forceRefresh
    ) {
      if (forceRefresh && setForceRefresh) {
        setForceRefresh(false);
      }
      if (props.onPageChange) {
        props.onPageChange({ pageIndex, pageSize });
      }
    }
  });

  return (
    <Container maxW="container.lg">
      <Table size="sm" variant="striped" data-test-id={testId}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {props.loading ? (
          <Tbody>
            <Tr>
              <Td colSpan={10000}>
                <Center>
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        data-test-column={cell.column.testId}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
            <Tr>
              <Td colSpan={10000}>
                Showing records {pageSize * pageIndex + 1} to{" "}
                {pageSize * pageIndex + page.length} of {props.totalCount}{" "}
                results
              </Td>
            </Tr>
          </Tbody>
        )}
      </Table>
      <div className="pagination">
        <Button onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
          First Page
        </Button>{" "}
        <Button onClick={() => previousPage()} isDisabled={!canPreviousPage}>
          Prev Page
        </Button>{" "}
        <Button onClick={() => nextPage()} isDisabled={!canNextPage}>
          Next Page
        </Button>{" "}
        <Button onClick={() => gotoPage(pageCount - 1)} isDisabled={!canNextPage}>
          Last Page
        </Button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {props.pageCount}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <NumberInput
            min={1}
            max={props.pageCount}
            defaultValue={pageIndex + 1}
            onChange={(_valueAsString: string, valueAsNumber: number) => {
              gotoPage(valueAsNumber - 1);
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </span>{" "}
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {props.pageSizeOptions.map((newPageSize) => (
            <option key={newPageSize} value={newPageSize}>
              Show {newPageSize}
            </option>
          ))}
        </Select>
      </div>
    </Container>
  );
};
