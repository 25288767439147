import { Box } from "@chakra-ui/react";
import { GPSimpleTable } from "../../components/GPSimpleTable/GPSimpleTable";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { useGetShiftLeaderShiftRequestsQuery } from "../../generated/graphql";
import { UploadShiftLeaderShiftRequests } from "./UploadShiftLeaderShiftRequests/UploadShiftLeaderShiftRequests";

export const OaShiftLeaderShiftRequests = () => {
  const {
    data: shiftLeaderShiftRequestsData,
    loading: shiftLeaderShiftRequestsIsLoading,
  } = useGetShiftLeaderShiftRequestsQuery();

  return (
    <Box padding={20}>
      {shiftLeaderShiftRequestsIsLoading && <PageSpinner />}
      <Box>
        <UploadShiftLeaderShiftRequests testId="upload-shift-leader-shift-requests" />
      </Box>
      {!shiftLeaderShiftRequestsIsLoading && (
        <GPSimpleTable
          testId="shift-leader-shift-requests-table"
          rows={[
            [
              "Location Name",
              "Shift Leader",
              "Day",
              "Start time availability",
              "Shift length",
            ],
            ...shiftLeaderShiftRequestsData!.getShiftLeaderShiftRequests.map(
              (shiftLeaderShiftRequest) => [
                shiftLeaderShiftRequest?.location_name ?? "",
                shiftLeaderShiftRequest?.shift_leader ?? "",
                shiftLeaderShiftRequest?.day_of_week ?? "",
                shiftLeaderShiftRequest?.start_time ?? "",
                shiftLeaderShiftRequest?.length ?? "",
              ]
            ),
          ]}
        />
      )}
    </Box>
  );
};
