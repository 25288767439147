import { Select } from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import { useGetCountryCodesQuery } from "../../generated/graphql";

export const CountrySelect = (props: {
  onChange: (selectedCountry: string) => void;
  allOptionDisabled?: boolean;
  selectedCountry?: string;
  testId?: string;
}) => {
  const { data } = useGetCountryCodesQuery();

  const countryCodes = data?.countries
    ? data.countries.reduce<string[]>((countries, country) => {
        if (country?.countryCode) {
          countries.push(country?.countryCode);
        }
        return countries;
      }, [])
    : [];

  const selectHandler: ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const selectedCountry = event.currentTarget.value;
    return props.onChange(selectedCountry);
  };

  return (
    <section>
      <label
        style={{
          fontWeight: 700,
          display: "block",
        }}
      >
        Country:
      </label>
      <Select
        id="select-country"
        data-test-id={props.testId ?? "select-country"}
        variant="outline"
        placeholder="Select Country"
        onChange={selectHandler}
        value={props.selectedCountry}
      >
        <option
          disabled={props.allOptionDisabled ? true : undefined}
          value="all"
        >
          All
        </option>
        {countryCodes.map((countryCode) => {
          return (
            <option key={countryCode} value={countryCode}>
              {countryCode}
            </option>
          );
        })}
      </Select>
    </section>
  );
};
