import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { ReactElement } from "react";
import { WeeklyQa } from "../../generated/graphql";
import { getISODateFromTimestampString } from "../../helpers/general/date";
import styles from "./styles.module.css";

interface QAOverviewTableProps {
  weeklyQA: WeeklyQa[];
}

export const QAOverviewTable: React.FC<QAOverviewTableProps> = ({
  weeklyQA,
}) => {
  const ROW_TITLE_TO_CONTENTS_MAP: Record<string, ReactElement[]> = {
    Week: [],
    Passed: [],
    Locations: [],
    Days: [],
    Hours: [],
    Orders: [],
  };

  weeklyQA.forEach((week, index) => {
    ROW_TITLE_TO_CONTENTS_MAP["Week"].push(
      getAsTdElement(
        index,
        getISODateFromTimestampString(week.operationalWeekStartDate).substring(
          "YYYY-".length
        )
      )
    );
    ROW_TITLE_TO_CONTENTS_MAP["Passed"].push(
      getAsTdElement(
        index,
        week.weeklyMissingLocations.length === 0 &&
          week.dailyMissingLocations.length === 0 &&
          week.hourlyMissingLocations.length === 0
          ? "yes"
          : "no"
      )
    );
    ROW_TITLE_TO_CONTENTS_MAP["Locations"].push(
      getAsTdElement(
        index,
        week.weeklyMissingLocations.length.toLocaleString("en-US")
      )
    );
    ROW_TITLE_TO_CONTENTS_MAP["Days"].push(
      getAsTdElement(
        index,
        week.dailyMissingLocations.length.toLocaleString("en-US")
      )
    );
    ROW_TITLE_TO_CONTENTS_MAP["Hours"].push(
      getAsTdElement(
        index,
        week.hourlyMissingLocations.length.toLocaleString("en-US")
      )
    );
    ROW_TITLE_TO_CONTENTS_MAP["Orders"].push(
      getAsTdElement(index, week.orders.toLocaleString("en-US"))
    );
  });

  return (
    <Table size="md" variant="simple">
      <Thead>
        <Tr className={styles.oddTableRow}>
          <Th key="week" pl="0">
            Week #
          </Th>
          {weeklyQA?.map((_week, index) => (
            <Th key={index} pl="0">
              {index}
            </Th>
          ))}
        </Tr>
      </Thead>
      {Object.keys(ROW_TITLE_TO_CONTENTS_MAP).map((rowTitle, index) =>
        getTableBodyRow(
          ROW_TITLE_TO_CONTENTS_MAP[rowTitle],
          index % 2 === 0 ? styles.evenTableRow : styles.oddTableRow,
          rowTitle
        )
      )}
    </Table>
  );
};

function getTableBodyRow(
  columns: ReactElement[],
  className: string,
  rowTitle: string
) {
  return (
    <Tbody key={rowTitle}>
      <Tr className={className}>
        <Td key="first-row" pl="0">
          {rowTitle}
        </Td>
        {columns}
      </Tr>
    </Tbody>
  );
}

function getAsTdElement(
  key: string | number,
  contents: ReactElement | string | number
) {
  return (
    <Td key={key} p="3" pl="0">
      {contents}
    </Td>
  );
}
