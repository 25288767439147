import {
  Center,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { ChangeEventHandler } from "react";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { Column, useSortBy, useTable } from "react-table";
import { PageSpinner } from "../../../components/PageSpinner/PageSpinner";
import { SelectLocationsMenu } from "../../../components/SelectLocationsMenu/SelectLocationsMenu";
import {
  useGetShiftsDailyQaQuery,
  useGetShiftsQaLocationsByMetroRegionsQuery,
} from "../../../generated/graphql";
import { toEmptyOrRoundedString } from "../../../helpers/general/number";

interface LocationsByDayMenuProps {
  selectedCountryCode: string;
  operationalWeekStartDate: string;
  adjustedDHRunId: number;
}

interface LocationsByDayTableRow {
  metro: string;
  locationId: number;
  mode: string;
  date: string;
  weekday: string;
  ovf: string | number;
  odhTargetMin: string | number;
  odhTarget: string | number;
  odhTargetMax: string | number;
  odhPlan: string | number;
  odhPlanVar: string | number;
  odhSched: string | number;
  odhSchedVar: string | number;
  dhModeled: string | number;
  dhAdjusted: string | number;
  appliedAbsenteeismAdjustmentPercentage: string | number;
  appliedAbsenteeismAdjustmentHours: string | number;
  appliedUnscheduledPercentage: string | number;
  appliedUnscheduledHours: string | number;
  netAbsenteeismAdjustmentHours: string | number;
  dhNetOfAbsenteeismAdjustments: string | number;
  minimumDpAdjustment: string | number;
  dhPlanned: string | number;
  taperAdjustmentHours: string | number;
  dhPlannedTapered: string | number;
  dhPlannedTaperedPercentage: string | number;
  dhAssignedInitial: string | number;
  dhUnassignedInitial: string | number;
  dhNetOfAssigned: string | number;
  dhScheduled: string | number;
  dhScheduledMustRequest: string | number;
  dhTotalExpected: string | number;
  dhTotalUpdated: string | number;
  dhTotalDelta: string | number;
  dhAssignedDelta: string | number;
  dhFailedUpload: string | number;
  minHooStart: string;
  maxHooEnd: string;
  minShiftStart: string;
  maxShiftEnd: string;
  startEndFlag: string;
  dhVarFlag: string;
  odhFlag: string;
}

export const LocationsByDayMenu: React.FC<LocationsByDayMenuProps> = ({
  selectedCountryCode,
  operationalWeekStartDate,
  adjustedDHRunId,
}) => {
  const [selectedMetroRegion, setSelectedMetroRegion] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const { data: metroRegionsResponse, loading: metroRegionsIsLoading } =
    useGetShiftsQaLocationsByMetroRegionsQuery({
      variables: {
        countryCode: selectedCountryCode,
        operationalWeekStartDate,
        adjustedDHRunId,
      },
    });

  const { data: shiftsDailyQaResponse, loading: shiftsDailyQaIsLoading } =
    useGetShiftsDailyQaQuery({
      variables: {
        locationIds:
          selectedMetroRegion && selectedLocation
            ? metroRegionsResponse?.shiftsQALocationsByMetroRegions
              ? selectedLocation.toLowerCase() === "flagged"
                ? metroRegionsResponse?.shiftsQALocationsByMetroRegions.find(
                    (metroRegion) => metroRegion.name === selectedMetroRegion
                  )?.locationIds ?? []
                : [parseInt(selectedLocation, 10)]
              : []
            : [],
        flaggedOnly: selectedLocation === "flagged",
        operationalWeekStartDate,
        adjustedDHRunId,
      },
      skip: !selectedLocation,
    });

  const tableData = useMemo(
    () =>
      (shiftsDailyQaResponse ? shiftsDailyQaResponse.shiftsDailyQA : []).map(
        (shiftDailyQAForLocation) => {
          return {
            metro: metroRegionsResponse?.shiftsQALocationsByMetroRegions
              ? metroRegionsResponse?.shiftsQALocationsByMetroRegions.find(
                  (odhLocationByMetroRegion) =>
                    odhLocationByMetroRegion.name.toLowerCase() !== "all" &&
                    odhLocationByMetroRegion.locationIds.indexOf(
                      shiftDailyQAForLocation.locationId
                    ) >= 0
                )?.name ?? ""
              : "",
            locationId: shiftDailyQAForLocation.locationId ?? "",
            mode: shiftDailyQAForLocation.mode ?? "",
            date: shiftDailyQAForLocation.date ?? "",
            weekday: shiftDailyQAForLocation.weekday ?? "",
            ovf: shiftDailyQAForLocation.ovf?.toLocaleString("en-US") ?? "",
            odhTargetMin: toEmptyOrRoundedString(
              shiftDailyQAForLocation.odhTargetMin
            ),
            odhTarget: toEmptyOrRoundedString(
              shiftDailyQAForLocation.odhTarget
            ),
            odhTargetMax: toEmptyOrRoundedString(
              shiftDailyQAForLocation.odhTargetMax
            ),
            odhPlan: toEmptyOrRoundedString(shiftDailyQAForLocation.odhPlan),
            odhPlanVar: toEmptyOrRoundedString(
              shiftDailyQAForLocation.odhPlanVar
            ),
            odhSched: toEmptyOrRoundedString(shiftDailyQAForLocation.odhSched),
            odhSchedVar: toEmptyOrRoundedString(
              shiftDailyQAForLocation.odhSchedVar
            ),
            dhModeled:
              shiftDailyQAForLocation.dhModeled?.toLocaleString("en-US") ?? "",
            dhAdjusted:
              shiftDailyQAForLocation.dhAdjusted?.toLocaleString("en-US") ?? "",
            appliedAbsenteeismAdjustmentPercentage: toEmptyOrRoundedString(
              shiftDailyQAForLocation.appliedAbsenteeismAdjustmentPercentage
            ),
            appliedAbsenteeismAdjustmentHours: toEmptyOrRoundedString(
              shiftDailyQAForLocation.appliedAbsenteeismAdjustmentHours
            ),
            appliedUnscheduledPercentage: toEmptyOrRoundedString(
              shiftDailyQAForLocation.appliedUnscheduledPercentage
            ),
            appliedUnscheduledHours: toEmptyOrRoundedString(
              shiftDailyQAForLocation.appliedUnscheduledHours
            ),
            netAbsenteeismAdjustmentHours: toEmptyOrRoundedString(
              shiftDailyQAForLocation.netAbsenteeismAdjustmentHours
            ),
            dhNetOfAbsenteeismAdjustments: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhNetOfAbsenteeismAdjustments
            ),
            minimumDpAdjustment: toEmptyOrRoundedString(
              shiftDailyQAForLocation.minimumDpAdjustment
            ),
            dhPlanned: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhPlanned
            ),
            taperAdjustmentHours: toEmptyOrRoundedString(
              shiftDailyQAForLocation.taperAdjustmentHours
            ),
            dhPlannedTapered: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhPlannedTapered
            ),
            dhPlannedTaperedPercentage: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhPlannedTaperedPercentage
            ),
            dhAssignedInitial: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhAssignedInitial
            ),
            dhUnassignedInitial: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhUnassignedInitial
            ),
            dhNetOfAssigned: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhNetOfAssigned
            ),
            dhScheduled: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhScheduled
            ),
            dhScheduledMustRequest: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhScheduledMustRequest
            ),
            dhTotalExpected: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhTotalExpected
            ),
            dhTotalUpdated: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhTotalUpdated
            ),
            dhTotalDelta: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhTotalDelta
            ),
            dhAssignedDelta: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhAssignedDelta
            ),
            dhFailedUpload: toEmptyOrRoundedString(
              shiftDailyQAForLocation.dhFailedUpload
            ),
            minHooStart: shiftDailyQAForLocation.minHooStart ?? "",
            maxHooEnd: shiftDailyQAForLocation.maxHooEnd ?? "",
            minShiftStart: shiftDailyQAForLocation.minShiftStart ?? "",
            maxShiftEnd: shiftDailyQAForLocation.maxShiftEnd ?? "",
            startEndFlag: shiftDailyQAForLocation.startEndFlag ? "1" : "",
            dhVarFlag: shiftDailyQAForLocation.dhVarFlag ? "1" : "",
            odhFlag: shiftDailyQAForLocation.odhFlag ? "1" : "",
          };
        }
      ) as LocationsByDayTableRow[],
    [shiftsDailyQaResponse, metroRegionsResponse]
  );

  const columns: Array<Column<LocationsByDayTableRow>> = useMemo(
    () => [
      {
        Header: "Metro",
        accessor: "metro",
      },
      {
        Header: "Loc",
        accessor: "locationId",
      },
      {
        Header: "Mode",
        accessor: "mode",
      },
      {
        Header: "Day",
        accessor: "date",
      },
      {
        Header: "Weekday",
        accessor: "weekday",
      },
      {
        Header: "OVF",
        accessor: "ovf",
      },
      {
        Header: "ODH TARGET MIN",
        accessor: "odhTargetMin",
      },
      {
        Header: "ODH TARGET",
        accessor: "odhTarget",
      },
      {
        Header: "ODH TARGET MAX",
        accessor: "odhTargetMax",
      },
      {
        Header: "ODH PLAN",
        accessor: "odhPlan",
      },
      {
        Header: "ODH PLAN VAR",
        accessor: "odhPlanVar",
      },
      {
        Header: "ODH SCHED.",
        accessor: "odhSched",
      },
      {
        Header: "ODH SCHED. VAR",
        accessor: "odhSchedVar",
      },
      {
        Header: "DH Modeled",
        accessor: "dhModeled",
      },
      {
        Header: "DH Adjusted",
        accessor: "dhAdjusted",
      },
      {
        Header: "APPLIED ABSENTEE ADJ %",
        accessor: "appliedAbsenteeismAdjustmentPercentage",
      },
      {
        Header: "APPLIED ABSENTEE ADJ HRS",
        accessor: "appliedAbsenteeismAdjustmentHours",
      },
      {
        Header: "APPLIED UNSCHED %",
        accessor: "appliedUnscheduledPercentage",
      },
      {
        Header: "APPLIED UNSCHED HRS",
        accessor: "appliedUnscheduledHours",
      },
      {
        Header: "NET ABSENTEE ADJUSTMENT",
        accessor: "netAbsenteeismAdjustmentHours",
      },
      {
        Header: "DH NET ABSENTEE ADJUSTED",
        accessor: "dhNetOfAbsenteeismAdjustments",
      },
      {
        Header: "MIN DP ADJ",
        accessor: "minimumDpAdjustment",
      },
      {
        Header: "DH PLAN",
        accessor: "dhPlanned",
      },
      {
        Header: "TAPER ADJ",
        accessor: "taperAdjustmentHours",
      },
      {
        Header: "DH PLAN TAPERED",
        accessor: "dhPlannedTapered",
      },
      {
        Header: "DH PLAN TAPERED %",
        accessor: "dhPlannedTaperedPercentage",
      },
      {
        Header: "DH ASSIGNED IN MUI",
        accessor: "dhAssignedInitial",
      },
      {
        Header: "DH UNASSIGNED IN MUI",
        accessor: "dhUnassignedInitial",
      },
      {
        Header: "DH NET OF ASSIGNED",
        accessor: "dhNetOfAssigned",
      },
      {
        Header: "DH SCHED.",
        accessor: "dhScheduled",
      },
      {
        Header: "DH SCHED. MUST REQUEST",
        accessor: "dhScheduledMustRequest",
      },
      {
        Header: "DH TL EXPECTED",
        accessor: "dhTotalExpected",
      },
      {
        Header: "DH TL UPDATED",
        accessor: "dhTotalUpdated",
      },
      {
        Header: "DH TL DELTA",
        accessor: "dhTotalDelta",
      },
      {
        Header: "DH ASSIGNED DELTA",
        accessor: "dhAssignedDelta",
      },
      {
        Header: "DH FAILED UPLOAD",
        accessor: "dhFailedUpload",
      },
      {
        Header: "HoO Start",
        accessor: "minHooStart",
      },
      {
        Header: "HoO End",
        accessor: "maxHooEnd",
      },
      {
        Header: "Min Shift Start",
        accessor: "minShiftStart",
      },
      {
        Header: "Max Shift End",
        accessor: "maxShiftEnd",
      },
      {
        Header: "Start/End Flag",
        accessor: "startEndFlag",
      },
      {
        Header: "DH Var. Flag",
        accessor: "dhVarFlag",
      },
      {
        Header: "ODH Flag",
        accessor: "odhFlag",
      },
    ],
    []
  );
  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable(
    {
      columns: columns,
      data: tableData,
      getRowId: (row, _relativeIndex, _parent) => {
        return row.locationId.toString() + row.date + row.mode;
      },
    },
    useSortBy
  );

  const handleChangeSelectedMetroRegion: ChangeEventHandler<
    HTMLSelectElement
  > = (event) => {
    event.preventDefault();
    const newMetroRegion = event.currentTarget.value;
    setSelectedMetroRegion(newMetroRegion);
    setSelectedLocation("");
  };

  const handleChangeSelectedLocation: ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    event.preventDefault();
    const newLocation = event.currentTarget.value;
    setSelectedLocation(newLocation);
  };

  useEffect(() => {
    setSelectedMetroRegion("");
    setSelectedLocation("");
  }, [selectedCountryCode]);

  return (
    <section>
      {(metroRegionsIsLoading || shiftsDailyQaIsLoading) && <PageSpinner />}
      <label
        style={{
          fontWeight: 700,
          marginTop: 10,
          marginBottom: 10,
          display: "block",
        }}
      >
        Locations by Day
      </label>
      <SelectLocationsMenu
        id="locations-by-day"
        metroRegionsIsLoading={metroRegionsIsLoading}
        metroRegions={
          metroRegionsResponse?.shiftsQALocationsByMetroRegions ?? []
        }
        selectedMetroRegion={selectedMetroRegion}
        selectedLocation={selectedLocation}
        allLocationsOptionIsEnabled={false}
        flaggedLocationsOptionIsEnabled
        handleChangeSelectedMetroRegion={handleChangeSelectedMetroRegion}
        handleChangeSelectedLocation={handleChangeSelectedLocation}
        testId="shiftqa-select-location-menu-by-day"
      />
      <Table size="md" variant="striped" data-test-id="shiftqa-daily-qa-table">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(
                    Object.assign(column.getSortByToggleProps(), {
                      style: { minWidth: column.minWidth },
                    })
                  )}
                  key={column.id}
                  pl="0"
                >
                  <Flex alignItems="center">
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsChevronCompactDown />
                      ) : (
                        <BsChevronCompactUp />
                      )
                    ) : (
                      ""
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {shiftsDailyQaIsLoading ? (
          <Tbody>
            <Tr>
              <Td colSpan={10000}>
                <Center>
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={cell.column.id + "x" + cell.row.id}
                        pl="0"
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
    </section>
  );
};
