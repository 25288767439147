import { GPSimpleTable } from "../../../components/GPSimpleTable/GPSimpleTable";
import { useGetShiftsWeeklyQaTotalQuery } from "../../../generated/graphql";
import {
  toEmptyOrRoundedString,
  toEmptyStringIfNotNumber,
} from "../../../helpers/general/number";

export interface TotalByWeekMenuProps {
  selectedCountryCode: string;
  operationalWeekStartDate: string;
  adjustedDHRunId: number;
}
export const TotalByWeekMenu = ({
  selectedCountryCode: countryCode,
  operationalWeekStartDate,
  adjustedDHRunId,
}: TotalByWeekMenuProps) => {
  const { data } = useGetShiftsWeeklyQaTotalQuery({
    variables: {
      countryCode,
      operationalWeekStartDate,
      adjustedDHRunId,
    },
    skip: !countryCode || !operationalWeekStartDate,
  });
  const total = data?.shiftsWeeklyQATotal;
  return (
    <section>
      <label
        style={{
          fontWeight: 700,
          marginTop: 10,
          marginBottom: 10,
          display: "block",
        }}
      >
        Total by Week
      </label>
      <GPSimpleTable
        testId="shiftqa-total-by-week-table"
        rows={[
          [
            "OVF",
            "DH MODELED",
            "DH ADJUSTED",
            "DH NET OF HOH ADJ",
            "APPLIED ABSENTEE ADJ %",
            "PRED. ABSENTEE ADJ HRS",
            "APPLIED ABSENTEE ADJ HRS",
            "APPLIED UNSCHED %",
            "PRED UNSCHED HRS",
            "APPLIED UNSCHED HRS",
            "NET ABSENTEE ADJUSTMENT",
            "DH NET ABSENTEE ADJUSTED",
            "MIN DP ADJ",
            "DH PLAN",
            "TAPER ADJ",
            "DH PLAN TAPERED",
            "DH ASSIGNED IN MUI",
            "DH UNASSIGNED IN MUI",
            "DH NET OF ASSIGNED",
            "DH SCHED.",
            "DH SCHED. MUST REQUEST",
            "DH TL EXPECTED",
            "DH TL UPDATED",
            "DH TL DELTA",
            "DH ASSIGNED DELTA",
            "DH FAILED UPLOAD",
          ],
        ].concat(
          total
            ? [
                [
                  toEmptyStringIfNotNumber(total?.ovf),
                  toEmptyStringIfNotNumber(total?.dhModeled),
                  toEmptyStringIfNotNumber(total?.dhAdjusted),
                  toEmptyOrRoundedString(
                    total?.dhNetOfHoursOfOperationAdjustments
                  ),
                  toEmptyOrRoundedString(
                    total?.appliedAbsenteeismAdjustmentPercentage
                  ),
                  toEmptyOrRoundedString(
                    total?.predictedAbsenteeismAdjustmentHours
                  ),
                  toEmptyOrRoundedString(
                    total?.appliedAbsenteeismAdjustmentHours
                  ),
                  toEmptyOrRoundedString(total?.appliedUnscheduledPercentage),
                  toEmptyOrRoundedString(total?.predictedUnscheduledHours),
                  toEmptyOrRoundedString(total?.appliedUnscheduledHours),
                  toEmptyOrRoundedString(total?.netAbsenteeismAdjustmentHours),
                  toEmptyOrRoundedString(total?.dhNetOfAbsenteeismAdjustments),
                  toEmptyStringIfNotNumber(total?.minimumDpAdjustment),
                  toEmptyOrRoundedString(total?.dhPlanned),
                  toEmptyOrRoundedString(total?.taperAdjustmentHours),
                  toEmptyOrRoundedString(total?.dhPlannedTapered),
                  toEmptyOrRoundedString(total?.dhAssignedInitial),
                  toEmptyOrRoundedString(total?.dhUnassignedInitial),
                  toEmptyOrRoundedString(total?.dhNetOfAssigned),
                  toEmptyOrRoundedString(total?.dhScheduled),
                  toEmptyOrRoundedString(total?.dhScheduledMustRequest),
                  toEmptyOrRoundedString(total?.dhTotalExpected),
                  toEmptyOrRoundedString(total?.dhTotalUpdated),
                  toEmptyOrRoundedString(total?.dhTotalDelta),
                  toEmptyOrRoundedString(total?.dhAssignedDelta),
                  toEmptyOrRoundedString(total?.dhFailedUpload),
                ],
              ]
            : []
        )}
      />
    </section>
  );
};
