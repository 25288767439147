import React from "react";
import {
  CellRenderer,
  GPHourlyDataSheetProps,
} from "../GPHourlyDataSheet/GPHourlyDataSheet";
import { GPHourlyDataSheet } from "../GPHourlyDataSheet/GPHourlyDataSheet";

export type OnChangeHandler = (
  newValue: string | number | null | undefined,
  row: number,
  col: number
) => void;

type GPHourlyDataOverrideSheetProps = Pick<
  GPHourlyDataSheetProps,
  | "dates"
  | "hourlyDataForWeek"
  | "summaryColumnsDataRows"
  | "isPercentage"
  | "dataCellWidth"
> & {
  id?: string;
  onChange?: OnChangeHandler;
};

export const GPHourlyDataOverrideSheet: React.FC<
  GPHourlyDataOverrideSheetProps
> = ({
  id,
  hourlyDataForWeek,
  dates,
  summaryColumnsDataRows,
  isPercentage,
  dataCellWidth,
  onChange,
}) => {
  const getDataCellStyles = (cellRenderer: CellRenderer) => {
    const backgroundColor =
      cellRenderer.cell.isHourlyDataCell &&
      (cellRenderer.cell.value === 0 ||
        cellRenderer.cell.value === null ||
        cellRenderer.cell.value === undefined)
        ? "yellow"
        : "white";
    const cellWidth = cellRenderer.cell.isHourlyDataCell
      ? dataCellWidth ?? ""
      : "";
    return {
      backgroundColor,
      width: cellWidth,
    };
  };

  return (
    <GPHourlyDataSheet
      id={id}
      hourlyDataForWeek={hourlyDataForWeek}
      dates={dates}
      summaryColumnsDataRows={summaryColumnsDataRows}
      getDataCellStyles={getDataCellStyles}
      nullValuePlaceholder=""
      isEditable
      isPercentage={isPercentage}
      onCellsChanged={(arrayOfChanges) => {
        const { value, row, col } = arrayOfChanges[0];
        return onChange ? onChange(value, row, col) : null;
      }}
      testId={id}
    />
  );
};
