import { Box, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useMemo } from "react";
import { ForecasterSelect } from "../../components/ForecasterSelect/ForecasterSelect";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import {
  Maybe,
  useGetWeeklyAdjustTableDataQuery,
  useUpsertWeeklyAdjustmentMutation,
} from "../../generated/graphql";
import { LAYER_TYPES } from "../../helpers/constants";
import { getArraySum } from "../../helpers/general/array";
import { getISODateOfNextOperationalMonday } from "../../helpers/general/date";
import {
  getTagTotalByEvent,
  getWeeklySummaryTotals,
  updateWeeklySummaryRowBySeasonalityAdjustment,
} from "../../helpers/weeklyAdjust/getRowTotals";
import {
  WeeklySummaryTable,
  WeeklySummaryTableRow,
} from "./WeeklySummaryTable/WeeklySummaryTable";

export const WeeklyAdjust = () => {
  const [selectedForecaster, setSelectedForecaster] = useState<string>("all");
  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );
  const [updatedRow, setUpdatedRow] = useState<WeeklySummaryTableRow>();

  function handleForecasterSelect(forecasterId: string) {
    setSelectedForecaster(forecasterId);
  }

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
    setUpdatedRow(undefined);
  }

  const { data, loading: tableDataLoading } = useGetWeeklyAdjustTableDataQuery({
    variables: {
      forecasterId: selectedForecaster,
      operationalWeekStartDate: selectedWeek,
    },
  });

  const [
    upsertWeeklyAdjustment,
    { loading: upsertWeeklyAdjustmentLoading },
  ] = useUpsertWeeklyAdjustmentMutation();

  const tableData: WeeklySummaryTableRow[] = useMemo(() => {
    return (
      data?.weeklyAdjustLocations?.reduce<WeeklySummaryTableRow[]>(
        (accum, location) => {
          if (
            location?.id &&
            location?.dopplerForecast !== null &&
            location?.threeWeekOrderHistory !== null &&
            location?.eventAdjustmentTotals &&
            location?.seasonalityAdjustments
          ) {
            const weeklyAdjust = Math.round(
              getArraySum(
                location.seasonalityAdjustments.weeklyLayer as number[][]
              )
            );
            const dailyAdjust = Math.round(
              getArraySum(
                location.seasonalityAdjustments.dailyLayer as number[][]
              )
            );
            const hourlyAdjust = Math.round(
              getArraySum(
                location.seasonalityAdjustments.hourlyLayer as number[][]
              )
            );
            const eventsAjustments = location.eventAdjustmentTotals.map(
              (tagData) => {
                return {
                  tag: tagData?.tag ?? "",
                  total: tagData?.total ?? 0,
                };
              }
            );
            const referral = getTagTotalByEvent(
              eventsAjustments,
              LAYER_TYPES.Referral
            );
            const promos = getTagTotalByEvent(
              eventsAjustments,
              LAYER_TYPES.Promotion
            );

            const partner = getTagTotalByEvent(
              eventsAjustments,
              LAYER_TYPES.Partner
            );
            const other = getTagTotalByEvent(
              eventsAjustments,
              LAYER_TYPES.Other
            );
            const planning = getTagTotalByEvent(
              eventsAjustments,
              LAYER_TYPES.Planning
            );

            const tableRow: WeeklySummaryTableRow = {
              id: parseInt(location.id, 10),
              title: location.title,
              dopplerForecast: location.dopplerForecast,
              threeWeekOrderHistory: location.threeWeekOrderHistory,
              weeklyOverride: location.seasonalityAdjustments.weeklyOverride,
              diff:
                location.dopplerForecast - location.threeWeekOrderHistory > 0
                  ? "Higher"
                  : "Lower",
              totalWeeklyAdjustment: weeklyAdjust,
              totalDailyAdjustment: dailyAdjust,
              totalHourlyAdjustment: hourlyAdjust,
              totalSeasonalAdjustment: 0,
              referralLayer: referral,
              promosLayer: promos,
              partnerLayer: partner,
              otherLayer: other,
              ovfPure: 0,
              planningLayer: planning,
              finalOvf: 0,
            };

            accum.push(getWeeklySummaryTotals(tableRow));
          }
          return accum;
        },
        []
      ) ?? []
    );
  }, [data]);

  async function handleEnterWeeklyAdjustment(upsertWeeklyAdjustmentVars: {
    locationId: number;
    forecasterId?: Maybe<number> | undefined;
    adjustmentType: string;
    operationalWeekStartDate: string;
    dateString: string;
    value?: Maybe<number> | undefined;
  }) {
    const response = await upsertWeeklyAdjustment({
      variables: upsertWeeklyAdjustmentVars,
    });

    if (!response.errors && response.data?.upsertSeasonalAdjustment) {
      let selectedRow = tableData.find(
        (row) => row.id === upsertWeeklyAdjustmentVars.locationId
      );
      if (selectedRow) {
        selectedRow = updateWeeklySummaryRowBySeasonalityAdjustment(
          response.data.upsertSeasonalAdjustment,
          selectedRow
        );
        setUpdatedRow(selectedRow);
      }
    }
  }

  return (
    <section>
      <Flex justifyContent="flex-start">
        <Box marginLeft="10px" marginTop="10px" marginBottom="10px">
          <WeekSelect onChange={handleWeekSelect} selectedWeek={selectedWeek} />
        </Box>
      </Flex>
      <Flex justifyContent="flex-start">
        <Box marginLeft="10px" marginTop="10px" marginBottom="10px">
          <ForecasterSelect
            onChange={handleForecasterSelect}
            selectedForecaster={selectedForecaster}
            operationalWeek={selectedWeek}
          />
        </Box>
      </Flex>
      <Flex justifyContent="flex-center">
        <Box marginTop="10px" marginBottom="10px">
          <WeeklySummaryTable
            tableData={tableData}
            updatedRow={updatedRow}
            operationalWeekStartDate={selectedWeek}
            handleEnterWeeklyAdjustment={handleEnterWeeklyAdjustment}
            upsertSeasonalAdjustmentLoading={upsertWeeklyAdjustmentLoading}
            loading={tableDataLoading}
            key={selectedForecaster + selectedWeek}
          />
        </Box>
      </Flex>
    </section>
  );
};
