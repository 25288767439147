import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

interface GPPopupSecretProps {
  isOpen: boolean;
  header: string;
  body: React.ReactElement;
  secret: string;
  onClose: () => void;
  onContinue: () => void;
}

export const GPPopupSecret: React.FC<GPPopupSecretProps> = (props) => {
  const { isOpen, header, body, secret, onClose, onContinue } = props;
  const [show, setShow] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const [inputPassword, setInputPassword] = React.useState<string>("");
  const handlePasswordChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => setInputPassword(event.target.value);

  const errorMessage: React.ReactElement = (
    <Text
      fontSize="lg"
      fontWeight="bold"
      color="red.500"
      align="center"
      visibility={showError ? "visible" : "hidden"}
    >
      Invalid Password
    </Text>
  );

  const handlePasswordValidate = () => {
    if (inputPassword === secret) {
      setShowError(false);
      onClose();
      onContinue();
    } else {
      setShowError(true);
    }
    setInputPassword("");
  };

  const handleShowPassword = () => setShow(!show);
  return (
    <Modal id="gp-popup-secret" isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {body}
          <InputGroup size="md">
            <Input
              value={inputPassword}
              onChange={handlePasswordChange}
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter password"
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          {errorMessage}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="ghost" onClick={handlePasswordValidate}>
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
