export const get2DArray = function <Type>(
  rows: number,
  cols: number,
  initialItemValue: unknown
): Type[][] {
  return new Array(rows)
    .fill(initialItemValue)
    .map(() => new Array<Type>(cols).fill(initialItemValue as Type));
};

export const getArraySum = function (array: number[] | number[][]): number {
  return array.flat().reduce((sum, value) => sum + value, 0);
};

export const getArrayWithZeroesForNulls = function (
  array: Array<number | null>
) {
  return array.map((value) => {
    return value ? value : 0;
  });
};
