import { EventAdjustmentTableRow } from "../../pages/EventAdjustmentUpload/EventAdjustmentTable/EventAdjustmentTable";
import { getArraySum } from "../general/array";
import { getNumbersBetween } from "../general/getNumbersBetween";

interface RowObject {
  layer: string;
  date: string;
  location_id: number;
  location_name: string;
  day: string;
  hours: string[];
  total: string;
}

export function transformEventAdjustmentData(
  eventAdjustmentData: EventAdjustmentTableRow[]
): RowObject[] {
  const formattedData: RowObject[] = [];

  eventAdjustmentData.forEach((row) => {
    const dataRow = {
      layer: row.layer,
      date: row.date,
      location_id: row.locationId,
      location_name: row.locationName,
      day: row.day,
      hours: row.hours.map((hour) => hour.toString() ?? 0),
      total: getArraySum(row.hours).toString(),
    };

    formattedData.push({
      ...dataRow,
      ...getNumbersBetween(0, 23).reduce(
        (hourToOrderVolumeMap, currentHour) => {
          hourToOrderVolumeMap[currentHour] =
            row.hours[
              currentHour >= 5 ? currentHour - 5 : 23 - 5 + currentHour + 1
            ]?.toString() ?? 0;

          return hourToOrderVolumeMap;
        },
        {} as Record<string, unknown>
      ),
    });
  });

  return formattedData;
}
