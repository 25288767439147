import { Code, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GPUploadPopup } from "../../../components/GPUploadPopup/GPUploadPopup";
import { useUploadEventAdjustmentMutation } from "../../../generated/graphql";
import { TEXT } from "../../../helpers/constants";
import styles from "./styles.module.css";

interface UploadEventAdjustmentModalProps {
  setForceRefresh?: (forceRefresh: boolean) => void;
  layerType: string | null;
  operationalWeekStartDate: string;
  disabled?: boolean;
  onSuccess?: () => void;
}

export const UploadEventAdjustmentModal: React.FC<
  UploadEventAdjustmentModalProps
> = ({ layerType, operationalWeekStartDate, disabled, onSuccess }) => {
  const mutationParameters = {
    layerType,
    operationalWeekStartDate,
  };

  return (
    <GPUploadPopup
      onSuccess={onSuccess}
      disabled={disabled}
      modelTitle={TEXT.UPLOAD_EVENT_ADJ}
      modelHeader={TEXT.HEADER_UPLOAD_EVENT_ADJ}
      modelBody={
        <>
          <p className={styles.uploadMessage}>
            Upload a csv file of Event Adjustment per location per day local
            time. Any existing Event Adjustments of the same name for the same
            week will be deleted. Required columns are: <Code>layer</Code>,{" "}
            <Code>location_id</Code> and <Code>date</Code>, and the hours{" "}
            <Code>0</Code> through
            <Code>23</Code>.
          </p>
          <span>For Example:</span>
          <Table size="sm" variant="striped" marginBottom="10px">
            <Thead>
              <Tr>
                <Th>layer</Th>
                <Th>location_id</Th>
                <Th>date</Th>
                <Th>5</Th>
                <Th>6</Th>
                <Th>7</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Ice Cream Promo</Td>
                <Td>175</Td>
                <Td>4/25/21</Td>
                <Td>2</Td>
                <Td>-1</Td>
                <Td>0</Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      }
      uploadMutation={useUploadEventAdjustmentMutation}
      uploadMutationProps={mutationParameters}
      allowDatesOutsideSelectedWeekCheckbox
      testId="upload-event-adjustment-popup"
    ></GPUploadPopup>
  );
};
