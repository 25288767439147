import { GPUploadPopup } from "../../components/GPUploadPopup/GPUploadPopup";
import { useUploadOaLaborMappingMutation } from "../../generated/graphql";
import { TEXT } from "../../helpers/constants/TEXT";
import {
  ColumnSetting,
  ColumnSettingValue,
  exportXlsxFileWithStyle,
} from "../../helpers/excel/exportXlsxFile";

interface UploadOaMappingProps {
  setForceRefresh?: (forceRefresh: boolean) => void;
  onSuccess?: () => void;
  selectedWeek?: string;
  allowDatesOutsideSelectedWeekCheckbox?: boolean;
  testId?: string;
}

const oaMappingColumns: {
  [key: string]: {
    header: string;
    example: string | number;
    setting: ColumnSettingValue;
  };
} = {
  locationId: {
    header: "*Location ID",
    example: 87,
    setting: {
      type: "text",
    },
  },
  schedulingOrganization: {
    header: "*Scheduling Organization",
    example: "SUP_ORG_152",
    setting: {
      type: "text",
    },
  },
  subgroupOrganization: {
    header: "*Subgroup Organization",
    example: "0087",
    setting: {
      type: "text",
    },
  },
  scheduleTagType1: {
    header: "*Schedule Tag Value for Schedule Tag Type 1",
    example: "MFC_General",
    setting: {
      type: "text",
    },
  },
  scheduleTagType2: {
    header: "*Schedule Tag Value for Schedule Tag Type 2",
    example: "MFC_ATH_Athens_87_0087",
    setting: {
      type: "text",
    },
  },
  mfcName: {
    header: "MFC Name",
    example: "ATH_Athens_87",
    setting: {
      type: "text",
    },
  },
};

export const UploadOaMapping: React.FC<UploadOaMappingProps> = ({
  setForceRefresh,
  selectedWeek,
  allowDatesOutsideSelectedWeekCheckbox,
  testId,
}) => {
  const mutationParameters = {
    selectedWeek,
  };

  const downloadTemplate = () => {
    const data: { [key: string]: string | number } = {};
    const columnsSetting: ColumnSetting = {};
    for (const key in oaMappingColumns) {
      if (Object.prototype.hasOwnProperty.call(oaMappingColumns, key)) {
        const column = oaMappingColumns[key];
        data[column.header] = column.example;
        columnsSetting[column.header] = column.setting;
      }
    }

    exportXlsxFileWithStyle(
      [
        {
          data: [data],
          sheetName: `sheet1`,
          columnsSetting,
        },
      ],
      `OA Labor Mapping Template`
    );
  };

  return (
    <GPUploadPopup
      setUploadComplete={setForceRefresh}
      modelTitle={TEXT.UPLOAD_OA_MAPPING}
      modelHeader={TEXT.HEADER_UPLOAD_OA_MAPPING}
      modelBody={
        <>
          <p>
            Upload an excel file of OA Labor Mapping. Previous OA Labor Mapping
            will be overwritten. Download a simple{" "}
            <span
              style={{ color: "rgb(25, 118, 210)", cursor: "pointer" }}
              onClick={downloadTemplate}
            >
              EXCEL TEMPLATE
            </span>{" "}
            to see an example of the format required. Fill in the template, and
            upload it to the app
          </p>
          <p>
            <b>Column Name is case sensitive</b>
          </p>
        </>
      }
      uploadMutation={useUploadOaLaborMappingMutation}
      uploadMutationProps={mutationParameters}
      uploadMutationRefetchQueries={[
        "GetMostRecentLaborMappingUploadTimestamp",
      ]}
      uploadButtonWidth={350}
      excel
      testId={testId}
    ></GPUploadPopup>
  );
};
