import {
  Center,
  ChakraProvider,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { Column, useSortBy, useTable } from "react-table";
import { getArraySum } from "../../../helpers/general/array";
import { getNumbersBetween } from "../../../helpers/general/getNumbersBetween";

export interface EventAdjustmentTableRow {
  layer: string;
  date: string;
  locationId: number;
  locationName: string;
  day: string;
  hours: number[];
}

export interface EventAdjustmentTableProps {
  tableData: EventAdjustmentTableRow[];
  loading: boolean;
}

const hourColumns: Array<Column<EventAdjustmentTableRow>> = [
  ...getNumbersBetween(5, 23),
  ...getNumbersBetween(0, 4),
].map((hour, index) => {
  return {
    Header: hour.toString(),
    accessor: (row) => row.hours[index].toFixed(2),
  };
});

const columns: Array<Column<EventAdjustmentTableRow>> = [
  {
    Header: "Layer",
    accessor: "layer",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Loc ID",
    accessor: "locationId",
  },
  {
    Header: "Loc Name",
    accessor: "locationName",
  },
  {
    Header: "Day",
    accessor: "day",
  },
  ...hourColumns,
  { Header: "Total", accessor: (row) => getArraySum(row.hours).toFixed(2) },
];

export const EventAdjustmentTable: React.FC<EventAdjustmentTableProps> = ({
  tableData,
  loading,
}) => {
  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable(
    {
      columns: columns,
      data: tableData,
      getRowId: (row, _relativeIndex, _parent) => {
        return row.locationId.toString() + row.date + row.layer;
      },
    },
    useSortBy
  );

  return (
    <ChakraProvider>
      <Table size="sm" variant="striped" data-test-id="event-adjustment-table">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column) => (
                <Th
                  userSelect="none"
                  {...column.getHeaderProps(
                    Object.assign(column.getSortByToggleProps(), {
                      style: { minWidth: column.minWidth },
                    })
                  )}
                  key={column.id}
                >
                  <Flex alignItems="center">
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsChevronCompactDown />
                      ) : (
                        <BsChevronCompactUp />
                      )
                    ) : (
                      ""
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {loading ? (
          <Tbody>
            <Tr>
              <Td colSpan={10000}>
                <Center>
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={cell.column.id + "x" + cell.row.id}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
    </ChakraProvider>
  );
};
