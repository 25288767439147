import { GridElement } from "./GPHourlyDataSheet";

export const getDataRowsWithSummaryColumns = (
  dataRows: GridElement[][],
  summaryColumnsDataRows: Array<Array<string | number>>
): GridElement[][] => {
  const dataRowsWithSummaryColumns: GridElement[][] = [];
  dataRows.forEach((dataRow, index) => {
    dataRowsWithSummaryColumns.push(
      dataRow.concat(
        summaryColumnsDataRows[index].map(
          (summaryColumnsDataRow): GridElement => ({
            value: summaryColumnsDataRow,
          })
        )
      )
    );
  });

  return dataRowsWithSummaryColumns;
};
