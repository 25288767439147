import React from "react";
import ReactDataSheet from "react-datasheet";
import { getNumbersBetween } from "../../helpers/general/getNumbersBetween";
import { isInteger } from "../../helpers/general/isInteger";
import { CellStyler, getCellRenderer } from "./getCellRenderer";
import { getDataRowsWithSummaryColumns } from "./getDataRowsWithSummaryColumns";
import { getDataRowsWithoutSummaryColumns } from "./getDataRowsWithoutSummaryColumns";

export interface GridElement extends ReactDataSheet.Cell<GridElement> {
  value: string | number | null | undefined;
  isHourlyDataCell?: boolean;
}

class MyReactDataSheet extends ReactDataSheet<GridElement> {}

export type CellRenderer = React.PropsWithChildren<
  ReactDataSheet.CellRendererProps<GridElement>
>;

export interface GPHourlyDataSheetProps {
  id?: string;
  hourlyDataForWeek: Array<Array<number | null>>;
  dates: string[];
  summaryColumnsDataRows?: Array<Array<string | number>>;
  getDataCellStyles?: CellStyler;
  nullValuePlaceholder?: string;
  isEditable?: boolean;
  onCellsChanged?: ReactDataSheet.CellsChangedHandler<GridElement>;
  isPercentage?: boolean;
  dataCellWidth?: string;
  testId?: string;
}

export const DAY_INDEX_TO_NAME: {
  [key: string]: number | string;
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
} = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
  7: "Total",
};

export const GPHourlyDataSheet: React.FC<GPHourlyDataSheetProps> = ({
  id,
  hourlyDataForWeek,
  dates,
  summaryColumnsDataRows,
  getDataCellStyles,
  nullValuePlaceholder,
  isEditable,
  onCellsChanged,
  isPercentage,
  testId,
}) => {
  const headerRow = [
    { value: "Date", disableEvents: true },
    { value: "", disableEvents: true },
    ...getNumbersBetween(5, 23).map((number) => ({
      value: number,
      disableEvents: true,
    })),
    ...getNumbersBetween(0, 4).map((number) => ({
      value: number,
      disableEvents: true,
    })),
  ];

  let dataRows = getDataRowsWithoutSummaryColumns(
    hourlyDataForWeek,
    [headerRow],
    dates,
    isEditable
  );

  if (summaryColumnsDataRows) {
    dataRows = getDataRowsWithSummaryColumns(dataRows, summaryColumnsDataRows);
  }

  const valueRenderer = (cell: GridElement) => {
    if (cell.value === null && cell.isHourlyDataCell) {
      return nullValuePlaceholder ?? "-";
    }
    if (cell.value === undefined && cell.isHourlyDataCell) {
      return "";
    }
    if (!isInteger(cell.value) && cell.isHourlyDataCell && !isPercentage) {
      return parseFloat((cell.value as number).toFixed(2));
    }
    if (cell.isHourlyDataCell && isPercentage) {
      return (cell.value as number).toFixed(2) + "%";
    }
    return cell.value;
  };

  const cellRenderer: ReactDataSheet.CellRenderer<GridElement> = getCellRenderer(
    getDataCellStyles
  );

  return (
    <div id={id ?? ""} data-test-id={testId}>
      <MyReactDataSheet
        data={dataRows}
        valueRenderer={valueRenderer}
        cellRenderer={cellRenderer}
        onCellsChanged={onCellsChanged ?? (() => undefined)}
      />
    </div>
  );
};
