import MockDate from "mockdate";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import "./helpers/thirdPartyCSS";

if (
  process.env.REACT_APP_MOCK_DATE_ENABLED === "true" &&
  process.env.REACT_APP_MOCK_DATE_TIME
) {
  MockDate.set(new Date(process.env.REACT_APP_MOCK_DATE_TIME));
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // Removed React.StrictMode because of this issue https://github.com/apollographql/apollo-client/issues/9819
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
