import {
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { constants } from "../../../helpers/general/constants";

function formatWeeklyData(dailyData: number[]) {
  return dailyData.map((hourlyData, index) => {
    return {
      x: constants.time.GOPUFF_HOURS_FOR_CHARTS[index],
      y: hourlyData,
    };
  });
}

export const HourlyChart = (props: {
  threeWeekTrailingData: number[];
  adjustedForecastData: number[];
}) => {
  const threeWeekTrailingDataInput = formatWeeklyData(
    props.threeWeekTrailingData
  );
  const adjustedForecastDataInput = formatWeeklyData(
    props.adjustedForecastData
  );

  return (
    <div>
      <XYPlot
        width={300}
        height={150}
        margin={{ left: 35, right: 10, top: 10, bottom: 25 }}
      >
        <VerticalGridLines tickTotal={24} />
        <HorizontalGridLines />
        <XAxis title="local time" tickValues={[5, 11, 17, 23]} />
        <YAxis title="Hourly %" />
        {/*  Hacky workaround for react vis to set the origin at (5:00AM, 0). No native option to do so, so included an invisible point there */}
        <MarkSeries
          data={[{ x: 5, y: 0, size: 3 }]}
          style={{ display: "none" }}
        />
        <LineSeries
          key="ThreeWeekTrailingAvg"
          data={threeWeekTrailingDataInput}
          color="orange"
        />
        <LineSeries
          key="AdjustedForecast"
          data={adjustedForecastDataInput}
          color="blue"
        />
      </XYPlot>
    </div>
  );
};
