import { Select } from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import { useGetCurrentlyActiveDopplerRunDateQuery } from "../../generated/graphql";
import { constants } from "../../helpers/general/constants";
import {
  getDateInLocalTime,
  getISODatesOfWeeksOfMondaysFromStartingDate,
} from "../../helpers/general/date";
import styles from "./styles.module.css";

export const WeekSelect = (props: {
  onChange: (selectedWeek: string) => void;
  selectedWeek: string;
  showCurrentWeek?: boolean;
  testId?: string;
}) => {
  const { data: currentlyActiveDopplerRunDate } =
    useGetCurrentlyActiveDopplerRunDateQuery() ?? "";

  const getWeeks = (): string[] => {
    if (
      !currentlyActiveDopplerRunDate ||
      !currentlyActiveDopplerRunDate.currentlyActiveDopplerRunDate
    )
      return [] as string[];

    const weeks = getISODatesOfWeeksOfMondaysFromStartingDate(
      constants.weeksOutToForecast,
      getDateInLocalTime(
        new Date(currentlyActiveDopplerRunDate.currentlyActiveDopplerRunDate)
      ),
      undefined,
      !!props?.showCurrentWeek,
      -3
    );

    // if selectedWeek is not in weeks, set selectedWeek to the first week
    if (!weeks.includes(props.selectedWeek)) {
      props.onChange(weeks[0]);
    }
    return weeks;
  };

  const selectHandler: ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const selectedWeek = event.currentTarget.value;
    return props.onChange(selectedWeek);
  };

  return (
    <section>
      <label htmlFor="select-week" className={styles.weekSelect}>
        Week:
      </label>
      <Select
        id="select-week"
        variant="outline"
        onChange={selectHandler}
        value={props.selectedWeek}
        data-test-id={props.testId ?? "select-week"}
      >
        {getWeeks().map((week) => {
          return (
            <option key={week} value={week}>
              {week}
            </option>
          );
        })}
      </Select>
    </section>
  );
};
