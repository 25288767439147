import { Select } from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import styles from "./styles.module.css";

export const LocationSelect = (props: {
  locationIds: number[];
  onChange: (selectedLocation: number) => void;
  selectedLocation: number;
}) => {
  const selectHandler: ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const selectedLocation = parseInt(event.currentTarget.value, 10);
    return props.onChange(selectedLocation);
  };

  return (
    <section>
      <label htmlFor="select-location" className={styles.locationSelect}>
        Location:
      </label>
      <Select
        id="select-location"
        variant="outline"
        placeholder="Select location"
        onChange={selectHandler}
        value={props.selectedLocation}
        data-test-id="select-location"
      >
        {props.locationIds.map((locationId) => {
          return (
            <option key={locationId} value={locationId}>
              {locationId}
            </option>
          );
        })}
      </Select>
    </section>
  );
};
