import { IBrowserSettings } from "@splitsoftware/splitio-react/types/splitio/splitio.d";

const splitIoConfiguration: IBrowserSettings = {
  core: {
    authorizationKey:
      process.env.REACT_APP_SPLITIO_CLIENT_AUTHORIZATION_KEY ?? "",
    key: "*",
  },
  scheduler: {
    offlineRefreshRate: 5,
  },
};

if (process.env.REACT_APP_SPLITIO_CLIENT_AUTHORIZATION_KEY === "localhost") {
  splitIoConfiguration.features = {
    driverSchedulingUpdatedApiEnabled: "on",
    publishShiftsEnabled: "on",
    oaLaborEnabled: "on",
  };
}

export default splitIoConfiguration;
