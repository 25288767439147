import { Box, Select } from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import { LocationsByMetroRegion } from "../../generated/graphql";
import styles from "./styles.module.css";

interface SelectLocationsMenuProps {
  metroRegionsIsLoading: boolean;
  metroRegions: LocationsByMetroRegion[];
  selectedMetroRegion?: string;
  selectedLocation?: string;
  allLocationsOptionIsEnabled: boolean;
  flaggedLocationsOptionIsEnabled?: boolean;
  id: string;
  handleChangeSelectedMetroRegion: ChangeEventHandler<HTMLSelectElement>;
  handleChangeSelectedLocation: ChangeEventHandler<HTMLSelectElement>;
  showLabel?: boolean;
  className?: string;
  testId?: string;
}

export const SelectLocationsMenu: React.FC<SelectLocationsMenuProps> = ({
  metroRegionsIsLoading,
  metroRegions,
  selectedMetroRegion,
  selectedLocation,
  allLocationsOptionIsEnabled,
  flaggedLocationsOptionIsEnabled,
  id,
  handleChangeSelectedMetroRegion,
  handleChangeSelectedLocation,
  showLabel,
  className,
  testId = "select-locations-menu",
}) => {
  const locationIdsInSelectedMetro =
    metroRegions.find((metroRegion) => metroRegion.name === selectedMetroRegion)
      ?.locationIds ?? [];

  locationIdsInSelectedMetro.sort((a, b) => a - b);

  return (
    <section className={className}>
      <Box display="flex" id={`select-${id}-container`}>
        <section>
          {showLabel && (
            <label
              htmlFor={`select-${id}-region`}
              className={styles.selectLabel}
            >
              Metro Region:
            </label>
          )}
          <Select
            id={`select-${id}-region`}
            variant="outline"
            placeholder="Select Metro Region"
            onChange={handleChangeSelectedMetroRegion}
            value={selectedMetroRegion}
            isDisabled={metroRegionsIsLoading}
            data-test-id={`${testId}-metro-region`}
          >
            {metroRegions.map((metroRegion) => {
              return (
                <option key={metroRegion.name} value={metroRegion.name}>
                  {metroRegion.name}
                </option>
              );
            })}
          </Select>
        </section>
        <section>
          {showLabel && (
            <label
              htmlFor={`select-${id}-location`}
              className={styles.selectLabel}
            >
              Location:
            </label>
          )}
          <Select
            id={`select-${id}-location`}
            variant="outline"
            placeholder="Select Location"
            onChange={handleChangeSelectedLocation}
            value={selectedLocation}
            isDisabled={!selectedMetroRegion}
            data-test-id={`${testId}-location`}
          >
            {flaggedLocationsOptionIsEnabled && (
              <option key="flagged" value="flagged">
                Flagged
              </option>
            )}
            {allLocationsOptionIsEnabled && (
              <option key="all" value="all">
                All
              </option>
            )}
            {locationIdsInSelectedMetro.map((locationId) => (
              <option key={locationId} value={locationId}>
                {locationId}
              </option>
            ))}
          </Select>
        </section>
      </Box>
    </section>
  );
};
