import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import styles from "./styles.module.css";

interface GPSimpleTableProps {
  rows: Array<Array<string | number>>;
  testId?: string;
}

export const GPSimpleTable: React.FC<GPSimpleTableProps> = ({
  rows,
  testId,
}) => {
  return (
    <Table size="md" variant="simple" data-test-id={testId}>
      <Thead>
        <Tr className={styles.oddTableRow}>
          {rows[0].map((column, index) => (
            <Th key={index} pl="0">
              {column}
            </Th>
          ))}
        </Tr>
      </Thead>
      {rows.slice(1).map((columns, rowIndex) => (
        <Tbody key={rowIndex}>
          <Tr
            className={
              rowIndex % 2 === 0 ? styles.evenTableRow : styles.oddTableRow
            }
          >
            {columns.map((column, columnIndex) => (
              <Td key={columnIndex} pl="0">
                {column}
              </Td>
            ))}
          </Tr>
        </Tbody>
      ))}
    </Table>
  );
};
