import { onError } from "@apollo/client/link/error";
import { constants } from "../helpers/general/constants";

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (networkError && !graphQLErrors) {
      alert(
        joinByNewLines(["Network error", JSON.stringify(networkError, null, 2)])
      );
      return;
    }

    const context = operation.getContext();
    const error = graphQLErrors?.[0];
    if (context.skipDefaultErrorHandling || !error) return;

    const alertBody: string[] = [];
    const errorCode = error.extensions?.code as string | undefined;

    if (errorCode === constants.errorCodes.tokenExpired) {
      window.location.reload();
      return;
    }

    if (errorCode === constants.errorCodes.badUserInput) {
      alertBody.push("Invalid Input");
      if (error.message) {
        alertBody.push(`Message: ${error.message}`);
      }
      const validationErrorMessages = error.extensions
        ?.validationErrorMessages as string[] | undefined;
      if (validationErrorMessages?.length) {
        alertBody.push(
          "Details: ",
          ...validationErrorMessages.map((v) => `- ${v}`)
        );
      }
    } else {
      alertBody.push(errorCode ?? "Error");
      if (error.message) {
        alertBody.push(`Message: ${error.message}`);
      }
    }
    if (response?.extensions?.traceId) {
      alertBody.push(`Trace ID: ${response.extensions.traceId as string}`);
    }
    if (graphQLErrors && graphQLErrors.length > 1) {
      alertBody.push(
        `Additional ${graphQLErrors.length - 1} errors:`,
        JSON.stringify(graphQLErrors.slice(1))
      );
    }
    alert(joinByNewLines(alertBody));
  }
);

function joinByNewLines(messages: string[]) {
  return messages.join("\n");
}
