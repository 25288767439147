/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaUpload } from "react-icons/fa";
import { TEXT } from "../../helpers/constants/TEXT";
import { constants } from "../../helpers/general/constants";
import { FileUpload } from "../FileUpload/FileUpload";
import { GPPopup } from "../GPPopup/GPPopup";
// import styles from "./styles.module.css";

interface GPUploadPopupProps {
  setUploadComplete?: (uploadComplete: boolean) => void;
  modelTitle: string;
  modelHeader: string;
  modelBody: any;
  uploadMutation: any;
  uploadMutationProps?: any;
  uploadMutationRefetchQueries?: string[];
  allowDatesOutsideSelectedWeekCheckbox?: boolean;
  allowTypeName?: boolean;
  disabled?: boolean;
  uploadButtonWidth?: number;
  size?: string;
  excel?: boolean;
  onSuccess?: () => void;
  testId?: string;
}

export const GPUploadPopup: React.FC<GPUploadPopupProps> = (props) => {
  const [uploadError, setUploadError] = useState("");
  const [validationErrorMessages, setValidationErrorMessages] = useState<
    string[]
  >([]);
  const [successMessage, setSuccessMessage] = useState("Upload Successful.");
  const [name, setName] = useState("");
  const [file, setFile] = useState<File>();
  const [allowDatesOutsideSelectedWeek, setAllowDatesOutsideSelectedWeek] =
    useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: successIsOpen,
    onOpen: successOnOpen,
    onClose: successOnClose,
  } = useDisclosure();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const onChangeFile = (newFile?: File) => {
    setFile(newFile);
  };

  const onCloseModal = () => {
    onClose();
    setFile(undefined);
    setUploadError("");
    setValidationErrorMessages([]);
  };

  const [mutation, { loading: uploadIsInProgress }] = props.uploadMutation({
    context: {
      skipDefaultErrorHandling: true,
    },
    skip: !file,
  });

  const onUpload = async () => {
    if (file) {
      const queries = props.uploadMutationRefetchQueries;

      const response = await mutation({
        variables: props.allowDatesOutsideSelectedWeekCheckbox
          ? {
              ...props.uploadMutationProps,
              file,
              allowDatesOutsideSelectedWeek,
            }
          : props.allowTypeName
          ? {
              ...props.uploadMutationProps,
              name,
              file,
            }
          : {
              ...props.uploadMutationProps,
              file,
            },
        refetchQueries: queries,
      });

      const responseValidationErrorMessages = response.errors?.[0]?.extensions
        ?.validationErrorMessages as string[] | undefined;
      const responseErrorMessage = response.errors?.[0]?.message as string;

      const responseSuccessMessage =
        response?.data && response.data[Object.keys(response.data)[0]]?.message
          ? response.data[Object.keys(response.data)[0]]?.message
          : "Upload Successful.";

      if (
        responseErrorMessage?.includes(TEXT.DATE_FORMAT_GRAPHQL_ERROR_SUBSTRING)
      ) {
        setUploadError(
          TEXT.UPLOAD_ERROR + TEXT.DATE_FORMAT_HUMAN_READABLE_ERROR
        );
      } else if (responseValidationErrorMessages?.length) {
        if (responseErrorMessage) {
          setUploadError(responseErrorMessage);
        } else {
          setUploadError(TEXT.UPLOAD_ERROR);
        }
        setValidationErrorMessages(responseValidationErrorMessages);
      } else if (response.errors) {
        setUploadError(
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          TEXT.UPLOAD_ERROR + response.errors[0].message
        );
      } else {
        setUploadError("");
        setValidationErrorMessages([]);
        setSuccessMessage(responseSuccessMessage);
        onCloseModal();
        successOnOpen();
        props.onSuccess?.();
      }
    }
  };

  return (
    <>
      <GPPopup
        isOpen={successIsOpen}
        body={<span>{successMessage}</span>}
        header="Success"
        onClose={successOnClose}
      />
      <Button
        variant="outline"
        width={props.uploadButtonWidth}
        colorScheme="blue"
        leftIcon={<FaUpload />}
        onClick={onOpen}
        isDisabled={props.disabled}
        data-test-id={props.testId && `${props.testId}-open-popup-button`}
      >
        {props.modelTitle}
      </Button>
      <Modal
        id="gp-upload-popup"
        isOpen={isOpen}
        onClose={onClose}
        size={props.size ?? "lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.modelHeader}</ModalHeader>
          <ModalCloseButton onClick={onCloseModal} />
          <ModalBody>
            {props.modelBody}
            {uploadError && (
              <Alert status="error">
                <Accordion allowToggle>
                  <AccordionItem style={{ border: "none" }}>
                    <AccordionButton>
                      {uploadError}
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {validationErrorMessages.map((message, index) => (
                        <div key={index}>{message}</div>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Alert>
            )}
            <FileUpload
              acceptedFileTypes={
                props.excel
                  ? constants.excel.validFileTypes.join(", ")
                  : constants.csv.validFileTypes.join(", ")
              }
              placeholder={TEXT.CHOOSE_FILE}
              errorMessage={
                file &&
                (props.excel
                  ? !constants.excel.validFileTypes.includes(file.type)
                    ? TEXT.ERROR_POPUP_FILE_TYPE +
                      `: ${file.type} is not an excel file`
                    : ""
                  : !constants.csv.validFileTypes.includes(file.type)
                  ? TEXT.ERROR_POPUP_FILE_TYPE +
                    `: ${file.type} is not a csv file`
                  : "")
              }
              onChange={onChangeFile}
              value={file?.name ?? ""}
              testId={props.testId && `${props.testId}-upload`}
            >
              {TEXT.TARGET_FILE}
            </FileUpload>
            {props.allowDatesOutsideSelectedWeekCheckbox ? (
              <Checkbox
                onChange={(event) => {
                  setAllowDatesOutsideSelectedWeek(event.target.checked);
                }}
                isChecked={allowDatesOutsideSelectedWeek}
              >
                Allow dates outside selected week
              </Checkbox>
            ) : null}
            {props.allowTypeName ? (
              <FormControl>
                <FormLabel htmlFor="input-name">Name</FormLabel>
                <Input
                  placeholder="Please type a meaningful name"
                  onChange={onChange}
                  value={name}
                  data-test-id={props.testId && `${props.testId}-name`}
                />
              </FormControl>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              leftIcon={<FaUpload />}
              isDisabled={!file || uploadIsInProgress}
              mr={3}
              onClick={() => {
                void onUpload();
              }}
              data-test-id={props.testId && `${props.testId}-upload-button`}
            >
              {uploadIsInProgress ? TEXT.UPLOADING : TEXT.UPLOAD}
            </Button>
            <Button variant="ghost" onClick={onCloseModal}>
              {TEXT.CANCEL}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
