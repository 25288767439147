import { Box, Container, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { CountrySelect } from "../../components/CountrySelect/CountrySelect";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { SelectForecastLocationsMenu } from "../../components/SelectForecastLocationsMenu/SelectForecastLocationsMenu";
import { useGetMultiweekForecastQuery } from "../../generated/graphql";
import { constants } from "../../helpers/general/constants";
import {
  getISODateOfCurrentOperationalMonday,
  getISODateOfNextOperationalMonday,
} from "../../helpers/general/date";
import { WeeklyChart } from "./WeeklyChart/WeeklyChart";
import { WeeklyDataTable } from "./WeeklyDataTable/WeeklyDataTable";

const HISTORICAL_WEEKS_TO_LOOK_BACK = 3;

export const MultiweekForecast = () => {
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>();
  const [selectedMetroRegion, setSelectedMetroRegion] = useState<string>();
  const weeksToInclude = constants.weeksOutToForecast;
  const operationalWeekStartDateOfCurrentOperationalWeek = getISODateOfCurrentOperationalMonday();
  const operationalWeekStartDateOfNextOperationalWeek = getISODateOfNextOperationalMonday();

  const {
    data: multiweekForecast,
    loading: isMultiweekForecastLoading,
  } = useGetMultiweekForecastQuery({
    variables: {
      operationalWeekStartDate: operationalWeekStartDateOfNextOperationalWeek,
      weeks: weeksToInclude,
      locationIds: selectedLocationIds ?? [],
    },
    skip: (selectedLocationIds ?? []).length === 0,
  });

  return (
    <Container maxW="container.xl" alignContent="center" width="100%">
      <Box pl={8} pr={8}>
        <Flex>
          <CountrySelect
            allOptionDisabled
            onChange={(countryCode) => {
              setSelectedCountry(countryCode);
              setSelectedLocationIds([]);
            }}
            selectedCountry={selectedCountry}
          />
          {selectedCountry && (
            <SelectForecastLocationsMenu
              countryCode={selectedCountry}
              operationalWeekStartDate={
                operationalWeekStartDateOfCurrentOperationalWeek
              }
              selectedLocation={selectedLocation?.toString()}
              setSelectedLocation={setSelectedLocation}
              setSelectedLocationIds={setSelectedLocationIds}
              selectedMetroRegion={selectedMetroRegion}
              setSelectedMetroRegion={setSelectedMetroRegion}
              weeksToInclude={weeksToInclude}
            />
          )}
        </Flex>
      </Box>
      <Box mt={10}>
        {isMultiweekForecastLoading && <PageSpinner />}
        {(selectedLocationIds ?? []).length > 0 &&
          !isMultiweekForecastLoading && (
            <WeeklyChart
              thisYearActuals={
                multiweekForecast?.multiweekForecast?.thisYearActualsByWeek ??
                []
              }
              lastYearActuals={
                multiweekForecast?.multiweekForecast?.lastYearActualsByWeek ??
                []
              }
              ovfPublishedByWeek={
                multiweekForecast?.multiweekForecast?.ovfPublishedByWeek
                  ? multiweekForecast?.multiweekForecast?.ovfPublishedByWeek
                  : []
              }
              adjustmentsWeeklyTotals={
                multiweekForecast?.multiweekForecast?.adjustmentsWeeklyTotals
                  ? multiweekForecast?.multiweekForecast
                      ?.adjustmentsWeeklyTotals
                  : []
              }
              activeDopplerWeeklyTotals={
                multiweekForecast?.multiweekForecast?.activeDopplerWeeklyTotals
                  ? multiweekForecast?.multiweekForecast
                      ?.activeDopplerWeeklyTotals
                  : []
              }
            />
          )}
      </Box>
      <Box>
        {(selectedLocationIds ?? []).length > 0 &&
          !isMultiweekForecastLoading && (
            <WeeklyDataTable
              multiweekForecast={multiweekForecast}
              historicalWeeksToLookBack={HISTORICAL_WEEKS_TO_LOOK_BACK}
              weeksToInclude={weeksToInclude}
              weeksOutToForecast={constants.weeksOutToForecast}
            />
          )}
      </Box>
    </Container>
  );
};
