import { Spinner } from "@chakra-ui/react";
import styles from "./styles.module.css";

export const PageSpinner = () => (
  <Spinner
    id="page-spinner"
    className={styles.spinner}
    thickness="5px"
    speed="0.65s"
    emptyColor="gray.200"
    color="blue.500"
    size="xl"
  />
);
