/* eslint-disable react/jsx-key */
import {
  Center,
  ChakraProvider,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Column, useTable } from "react-table";
import { constants } from "../../../helpers/general/constants";
import { getISODatesOfCurrentOperationalWeek } from "../../../helpers/general/date";
import { roundToDecimalPlaces } from "../../../helpers/general/number";
import { MidweekReforecastTableCell } from "./MidweekReforecastTableCell/MidweekReforecastTableCell";

export interface MidweekReforecastTableRow {
  id: number;
  dataSet: string;
  displayAsPercentage: boolean;
  decimalPlaces: number;
  displayTotal: boolean;
  customTotal?: number[];
  isEditable: boolean;
  Monday: number;
  Tuesday: number;
  Wednesday: number;
  Thursday: number;
  Friday: number;
  Saturday: number;
  Sunday: number;
  total: number;
}

export interface MidweekReforecastTableProps {
  id?: string;
  dates: string[];
  locationId: number;
  metroRegion: string;
  countryCode: string;
  operationalWeekStartDate: string;
  handleEnterDailyAdjustment: (upsertWeeklyAdjustmentVars: {
    locationIds?: [number];
    metroRegion?: string;
    countryCode?: string;
    date: string;
    orderAdjustment: number;
  }) => Promise<void>;
  isCreateOrEditReforecastLayerLoading: boolean;
  tableData: MidweekReforecastTableRow[];
  loading: boolean;
}

const datesInWeek = getISODatesOfCurrentOperationalWeek();

const formatRowValue = (value: number, row: MidweekReforecastTableRow) => {
  let stringValue = row.isEditable
    ? value.toFixed(row.decimalPlaces)
    : value.toLocaleString(undefined, {
        minimumFractionDigits: row.decimalPlaces,
        maximumFractionDigits: row.decimalPlaces,
      });
  if (row.displayAsPercentage) {
    stringValue += "%";
  }
  return stringValue;
};

const dayColumns = constants.time.DAYS_OF_THE_WEEK.map((day, index) => {
  return {
    Header: datesInWeek[index],
    accessor: (row: MidweekReforecastTableRow) => {
      const value = "";
      if (row[day]) {
        return formatRowValue(row[day], row);
      }
      return value;
    },
    Cell: MidweekReforecastTableCell,
    minWidth: 120,
  };
});

const columns: Array<Column<MidweekReforecastTableRow>> = [
  {
    Header: "Data Set",
    accessor: "dataSet",
    minWidth: 300,
  },
  ...dayColumns,
  {
    Header: "Total",
    accessor: (row) => {
      const rowSum = row.displayTotal
        ? roundToDecimalPlaces(row.total, 2)
        : null;
      if (!rowSum) return "";
      return formatRowValue(rowSum, row);
    },
    minWidth: 100,
  },
];

export const MidweekReforecastSummaryTable: React.FC<MidweekReforecastTableProps> = ({
  id,
  dates,
  locationId,
  metroRegion,
  countryCode,
  operationalWeekStartDate,
  handleEnterDailyAdjustment,
  isCreateOrEditReforecastLayerLoading,
  tableData,
  loading,
}) => {
  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable({
    columns: columns,
    data: tableData,
  });

  return (
    <ChakraProvider>
      <Table id={id ?? ""} size="sm" variant="striped">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth },
                  })}
                >
                  <Flex alignItems="center">{column.render("Header")}</Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {loading ? (
          <Tbody>
            <Tr>
              <Td colSpan={10000}>
                <Center>
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render("Cell", {
                          dates,
                          handleEnterDailyAdjustment,
                          colIndex: index - 1,
                          locationId,
                          metroRegion,
                          countryCode,
                          operationalWeekStartDate,
                          isCreateOrEditReforecastLayerLoading,
                        })}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
    </ChakraProvider>
  );
};
