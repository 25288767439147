/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Input } from "@chakra-ui/input";
import React from "react";
import { CellProps } from "react-table";

// Create an editable cell renderer
export function MidweekReforecastTableCell<T extends object>({
  value: initialValue,
  row,
  colIndex,
  updateCurrentData,
  dates,
  locationId,
  metroRegion,
  countryCode,
  handleEnterDailyAdjustment,
  isCreateOrEditReforecastLayerLoading,
  operationalWeekStartDate,
  ...props
}: CellProps<T>) {
  const [value, setValue] = React.useState(initialValue ? initialValue : "");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = (event.target as HTMLInputElement).value;
    const orderAdjustment =
      currentValue === "" ? null : parseFloat(currentValue);
    if (
      !isCreateOrEditReforecastLayerLoading &&
      currentValue !== initialValue
    ) {
      void handleEnterDailyAdjustment({
        locationIds: isNaN(locationId) ? null : [locationId],
        metroRegion: !metroRegion || metroRegion === "All" ? null : metroRegion,
        countryCode: countryCode || null,
        date: (dates as string[])[colIndex as number],
        orderAdjustment,
      });
    }

    if (updateCurrentData) {
      updateCurrentData({ column: props?.cell?.column?.id, value, props });
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.persist();
    if (event.key === "Enter") {
      (event.target as HTMLInputElement).blur();
    }
  };

  React.useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  const original: any = row?.original;
  if (!original?.isEditable) {
    return <div>{String(initialValue)}</div>;
  }

  return (
    <Input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
    />
  );
}
