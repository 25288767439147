import { Box, Button, Container, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { DiscreteColorLegend } from "react-vis";
import { DropdownSelect } from "../../components/DropdownSelect/DropdownSelect";
import { ForecasterSelect } from "../../components/ForecasterSelect/ForecasterSelect";
import { GPHourlyDataHeatSheet } from "../../components/GPHourlyDataHeatSheet/GPHourlyDataHeatSheet";
import { GPHourlyDataOverrideSheet } from "../../components/GPHourlyDataOverrideSheet/GPHourlyDataOverrideSheet";
import { LocationSelect } from "../../components/LocationSelect/LocationSelect";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import {
  Maybe,
  useGetLocationIdsByForecasterQuery,
  useGetLocationSeasonalityAdjustmentsQuery,
  useSetAllHourlyAdjustmentsToHistoricalAverageMutation,
  useUpsertSeasonalAdjustmentMutation,
} from "../../generated/graphql";
import { getSummaryRows } from "../../helpers/dailyHourlyAdjust/getSummaryRows";
import { get2DArray, getArraySum } from "../../helpers/general/array";
import { constants } from "../../helpers/general/constants";
import { getISODateOfNextOperationalMonday } from "../../helpers/general/date";
import { getISODateTimeByHourlyDataSheetRowCol } from "../../helpers/hourlyData/getISODateTimeByHourlyDataSheetRowCol";
import { DailyChart } from "./Charts/DailyChart";
import { HourlyChart } from "./Charts/HourlyChart";
import {
  DailySummaryTable,
  DailySummaryTableRow,
  editableTargetRowHeader,
} from "./DailySummaryTable/DailySummaryTable";
import styles from "./styles.module.css";

export const DailyHourlyAdjust = () => {
  const preselectedLocation = 8;

  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );
  const [selectedForecaster, setSelectedForecaster] = useState<string>("all");
  const [
    selectedHourlyOverrideDistribution,
    setSelectedHourlyOverrideDistribution,
  ] = useState<string>("Location");

  const [selectedLocation, setSelectedLocation] =
    useState<number>(preselectedLocation);

  const {
    data: locationsDataWithIds,
    loading: getLocationIdsByForecasterLoading,
  } = useGetLocationIdsByForecasterQuery({
    variables: {
      forecaster: selectedForecaster,
      operationalWeekStartDate: selectedWeek,
    },
  });

  const {
    data: seasonalityAdjustments,
    loading: getSeasonalityAdjustmentsLoading,
  } = useGetLocationSeasonalityAdjustmentsQuery({
    variables: {
      ids: [selectedLocation],
      operationalWeekStartDate: selectedWeek,
    },
  });

  const [
    upsertSeasonalAdjustment,
    { loading: upsertSeasonalAdjustmentLoading },
  ] = useUpsertSeasonalAdjustmentMutation({
    refetchQueries: ["GetLocationSeasonalityAdjustments"],
  });
  const seasonalityAdjustmentsLocationData = seasonalityAdjustments?.locations
    ? seasonalityAdjustments?.locations[0]
    : null;

  const [
    setAllHourlyAdjustmentsToHistoricalAverage,
    { loading: setAllHourlyAdjustmentsToHistoricalAverageLoading },
  ] = useSetAllHourlyAdjustmentsToHistoricalAverageMutation({
    refetchQueries: ["GetLocationSeasonalityAdjustments"],
  });

  function handleForecasterSelect(forecasterId: string) {
    setSelectedForecaster(forecasterId);
  }

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
  }

  function handleLocationSelect(locationId: number) {
    setSelectedLocation(locationId);
  }

  async function handleEnterDailyAdjustment(upsertWeeklyAdjustmentVars: {
    locationId: number;
    forecasterId?: Maybe<number> | undefined;
    adjustmentType: string;
    operationalWeekStartDate: string;
    dateString: string;
    value?: number | null;
  }) {
    await upsertSeasonalAdjustment({
      variables: upsertWeeklyAdjustmentVars,
    });
  }

  const dopplerForecast =
    seasonalityAdjustmentsLocationData?.seasonalityAdjustments
      ? seasonalityAdjustmentsLocationData.seasonalityAdjustments
          ?.dopplerForecast
      : get2DArray<number>(
          constants.time.DAYS_IN_A_WEEK,
          constants.time.HOURS_IN_A_DAY,
          0
        );

  const dopplerWeeklyTotal = getArraySum(
    dopplerForecast.map((orderVolumesForDay) =>
      orderVolumesForDay.map((orderVolumeForHour) =>
        typeof orderVolumeForHour === "number" ? orderVolumeForHour : 0
      )
    )
  );

  const dopplerDailyPercentages =
    dopplerWeeklyTotal > 0
      ? dopplerForecast.map((hourlyDataForDay) =>
          parseFloat(
            (
              (getArraySum(
                hourlyDataForDay.map((hourlyValue) =>
                  typeof hourlyValue === "number" ? hourlyValue : 0
                )
              ) /
                dopplerWeeklyTotal) *
              100
            ).toFixed(2)
          )
        )
      : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0.0);

  const threeWeekTrailingHourlyAverages =
    seasonalityAdjustmentsLocationData?.threeWeekTrailingAverage
      ? (seasonalityAdjustmentsLocationData.threeWeekTrailingAverage
          ?.hourlyPercentages as number[][])
      : get2DArray<number>(
          constants.time.DAYS_IN_A_WEEK,
          constants.time.HOURS_IN_A_DAY,
          0
        );

  const threeWeekTrailingDailyAverages =
    seasonalityAdjustmentsLocationData?.threeWeekTrailingAverage
      ? (seasonalityAdjustmentsLocationData.threeWeekTrailingAverage
          ?.dailyPercentages as number[])
      : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const hourlyOverrides =
    seasonalityAdjustmentsLocationData?.seasonalityAdjustments
      ? seasonalityAdjustmentsLocationData.seasonalityAdjustments
          ?.hourlyOverrides
      : get2DArray<number>(
          constants.time.DAYS_IN_A_WEEK,
          constants.time.HOURS_IN_A_DAY,
          0
        );

  const dailyOverrides =
    seasonalityAdjustmentsLocationData?.seasonalityAdjustments
      ? (seasonalityAdjustmentsLocationData.seasonalityAdjustments
          ?.dailyOverrides as number[])
      : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const totalSeasonalityAdjustedForecastDistribution =
    seasonalityAdjustmentsLocationData?.seasonalityAdjustments
      ? seasonalityAdjustmentsLocationData.seasonalityAdjustments
          ?.totalSeasonalityAdjustedForecastDistribution
      : get2DArray<number>(
          constants.time.DAYS_IN_A_WEEK,
          constants.time.HOURS_IN_A_DAY,
          0
        );

  const dailySeasonalityAdjustedForecastDistribution =
    seasonalityAdjustmentsLocationData?.seasonalityAdjustments
      ? seasonalityAdjustmentsLocationData.seasonalityAdjustments
          ?.dailySeasonalityAdjustedForecastDistribution
      : new Array<number>(constants.time.DAYS_IN_A_WEEK).fill(0);

  const dates = seasonalityAdjustmentsLocationData?.seasonalityAdjustments
    ? seasonalityAdjustmentsLocationData.seasonalityAdjustments?.dates
    : [];

  const totalSeasonalityHourlyOverridesUIMatrix = get2DArray<number>(
    constants.time.DAYS_IN_A_WEEK,
    constants.time.HOURS_IN_A_DAY,
    null
  );

  for (let i = 0; i < totalSeasonalityHourlyOverridesUIMatrix.length; i++) {
    for (
      let j = 0;
      j < totalSeasonalityHourlyOverridesUIMatrix[i].length;
      j++
    ) {
      if (
        seasonalityAdjustments?.locations?.[0] &&
        seasonalityAdjustments.locations[0].seasonalityAdjustments
          ?.hourlyOverrides &&
        seasonalityAdjustments.locations[0].seasonalityAdjustments
          ?.hourlyOverrides[i][j]
      ) {
        totalSeasonalityHourlyOverridesUIMatrix[i][j] =
          seasonalityAdjustments.locations[0].seasonalityAdjustments
            ?.totalSeasonalityAdjustedForecastDistribution[i][j] ?? 0;
      }
    }
  }

  const selectedForecastersLocations: number[] = [];

  if (locationsDataWithIds?.locations) {
    locationsDataWithIds.locations.forEach((locationObject) => {
      if (locationObject) {
        selectedForecastersLocations.push(parseInt(locationObject.id, 10));
      }
    });

    selectedForecastersLocations.sort((locationId1, locationId2) => {
      return locationId1 - locationId2;
    });
  }

  const dailySummaryTableRowHeaders = [
    "Doppler Forecast",
    "3 Week Trailing Avg",
    "Seasonal Adj Forecast",
    editableTargetRowHeader,
  ];

  const dailySummaryTablePercentages = [
    dopplerDailyPercentages,
    threeWeekTrailingDailyAverages,
    dailySeasonalityAdjustedForecastDistribution,
    dailyOverrides,
  ];

  const dailySummaryTableData: DailySummaryTableRow[] =
    dailySummaryTableRowHeaders.map((rowHeader, index) => {
      return {
        id: index,
        dataSet: rowHeader,
        mon: dailySummaryTablePercentages[index][0],
        tue: dailySummaryTablePercentages[index][1],
        wed: dailySummaryTablePercentages[index][2],
        thu: dailySummaryTablePercentages[index][3],
        fri: dailySummaryTablePercentages[index][4],
        sat: dailySummaryTablePercentages[index][5],
        sun: dailySummaryTablePercentages[index][6],
      };
    });

  const overridesSummaryRows: string[][] = [
    ["Total"],
    ...(hourlyOverrides.reduce((overridesTotals, hourlyDataForDay) => {
      const overridesAsNumbers: number[] = hourlyDataForDay.map((hourlyValue) =>
        typeof hourlyValue === "number" ? hourlyValue : 0
      );
      const floatResult = parseFloat(
        `${getArraySum(overridesAsNumbers)}`
      ).toFixed(2);
      overridesTotals.push([`${floatResult}%`]);
      return overridesTotals;
    }, [] as string[][]) as unknown as string[][]),
  ];

  const adjustDataCellWidth = "40px";

  return (
    <Container maxW="container.xl" alignContent="center" width="100%">
      {(getLocationIdsByForecasterLoading ||
        getSeasonalityAdjustmentsLoading ||
        upsertSeasonalAdjustmentLoading) && <PageSpinner />}
      <Container>
        <Flex justifyContent="center">
          <Box marginLeft="10px" marginTop="10px" marginBottom="10px">
            <WeekSelect
              onChange={handleWeekSelect}
              selectedWeek={selectedWeek}
              key={selectedWeek}
            />
          </Box>
          <Box marginLeft="10px" marginTop="10px" marginBottom="10px">
            <ForecasterSelect
              onChange={handleForecasterSelect}
              selectedForecaster={selectedForecaster}
              key={selectedForecaster}
              operationalWeek={selectedWeek}
            />
          </Box>
          <Box marginLeft="10px" marginTop="10px" marginBottom="10px">
            <LocationSelect
              onChange={handleLocationSelect}
              locationIds={selectedForecastersLocations}
              selectedLocation={selectedLocation}
              key={selectedLocation}
            />
          </Box>
        </Flex>{" "}
      </Container>
      <Container maxW="container.lg" alignContent="center" width="100%">
        <Box marginTop="10px" marginBottom="10px" marginLeft="10px">
          <DailyChart
            rawDopplerData={dailySummaryTablePercentages[0]}
            threeWeekTrailingData={dailySummaryTablePercentages[1]}
            adjustedForecastData={dailySummaryTablePercentages[2]}
          />
        </Box>
      </Container>
      <Flex className={styles.dailyFlex}>
        <Box className={styles.dailyBox}>
          <label
            htmlFor="summary-table-label"
            className={styles.componentLabel}
          >
            Daily Summary Table
          </label>
          <DailySummaryTable
            id="daily-summary-table"
            dates={dates}
            locationId={selectedLocation}
            operationalWeekStartDate={selectedWeek}
            handleEnterDailyAdjustment={handleEnterDailyAdjustment}
            upsertSeasonalAdjustmentLoading={upsertSeasonalAdjustmentLoading}
            tableData={dailySummaryTableData}
            loading={false}
          />
        </Box>
      </Flex>
      <Flex className={styles.dailyFlex}>
        <Box className={styles.dailyBox}>
          <label
            htmlFor="3-week-avg-table-label"
            className={styles.componentLabel}
          >
            3 Week Trailing Average
          </label>
          <GPHourlyDataHeatSheet
            id="three-week-trailing-avg-heatsheet"
            dates={dates}
            hourlyDataForWeek={threeWeekTrailingHourlyAverages}
            summaryColumnsDataRows={getSummaryRows(
              threeWeekTrailingDailyAverages
            )}
            isPercentage
            nullValuePlaceholder="00.00%"
            dataCellWidth={adjustDataCellWidth}
            testId="three-week-trailing-avg-heatsheet"
          />
        </Box>
      </Flex>
      <Flex className={styles.dailyFlex}>
        <Box className={styles.dailyBox}>
          <label
            htmlFor="seasonally-adjusted-forecast-table-label"
            className={styles.componentLabel}
          >
            Seasonally Adjusted Forecast
          </label>
          <GPHourlyDataHeatSheet
            id="seasonally-adjusted-forecast-heatsheet"
            dates={dates}
            hourlyDataForWeek={totalSeasonalityAdjustedForecastDistribution}
            hourlyDataOverrides={totalSeasonalityHourlyOverridesUIMatrix}
            summaryColumnsDataRows={getSummaryRows(
              dailySeasonalityAdjustedForecastDistribution
            )}
            isPercentage
            dataCellWidth={adjustDataCellWidth}
            testId="seasonally-adjusted-forecast-heatsheet"
          />
        </Box>
      </Flex>
      <Flex className={styles.dailyFlex}>
        <Box className={styles.dailyBox}>
          <label
            htmlFor="override-table-label"
            className={styles.componentLabel}
          >
            Enter Desired Hourly Override %
          </label>
          <GPHourlyDataOverrideSheet
            id="seasonally-adjusted-forecast-override-sheet"
            dates={dates}
            hourlyDataForWeek={hourlyOverrides}
            onChange={(newValue, row, col) => {
              if (
                upsertSeasonalAdjustmentLoading ||
                dopplerForecast[row - 1][col - 2] === null
              ) {
                return;
              }
              if (
                (typeof newValue === "string" && parseFloat(newValue) !== 0) ||
                (typeof newValue === "number" && newValue !== 0)
              ) {
                void upsertSeasonalAdjustment({
                  variables: {
                    locationId: selectedLocation,
                    forecasterId: null,
                    adjustmentType: "hourly",
                    operationalWeekStartDate: selectedWeek,
                    dateString: getISODateTimeByHourlyDataSheetRowCol(
                      dates,
                      row,
                      col
                    ),
                    value:
                      newValue && typeof parseFloat(`${newValue}`) === "number"
                        ? parseFloat(`${newValue}`)
                        : null,
                  },
                });
              }
            }}
            summaryColumnsDataRows={overridesSummaryRows}
            isPercentage
            dataCellWidth={adjustDataCellWidth}
          />
        </Box>
      </Flex>
      <Box width="50%" pt="10px">
        Set hourly override distribution to 3 week average for:
        <DropdownSelect
          disabled={
            setAllHourlyAdjustmentsToHistoricalAverageLoading ||
            !selectedLocation
          }
          placeholder=""
          selection={selectedHourlyOverrideDistribution}
          selectOptions={["Location", "Metro Region"]}
          onChange={(hourlyOverrideDistribution) => {
            setSelectedHourlyOverrideDistribution(hourlyOverrideDistribution);
          }}
        />
        <Button
          onClick={() => {
            void setAllHourlyAdjustmentsToHistoricalAverage({
              variables: {
                operationalWeekStartDate: selectedWeek,
                locationId: selectedLocation,
                weeksInPast: 3,
                isMetroRegion:
                  selectedHourlyOverrideDistribution === "Metro Region",
              },
            });
          }}
        >
          Submit
        </Button>
      </Box>
      <Flex
        wrap="wrap"
        minWidth="1400px"
        minHeight="400px"
        px={8}
        justifyContent="center"
      >
        {constants.time.DAYS_OF_THE_WEEK.map((weekday, index) => {
          return (
            <Box
              marginTop="10px"
              marginBottom="10px"
              marginLeft="10px"
              key={weekday}
            >
              <HourlyChart
                threeWeekTrailingData={threeWeekTrailingHourlyAverages[index]}
                adjustedForecastData={
                  totalSeasonalityAdjustedForecastDistribution.map(
                    (hourlyDataForDay) =>
                      hourlyDataForDay.map((hourlyValue) =>
                        typeof hourlyValue === "number" ? hourlyValue : 0
                      )
                  )[index]
                }
              />
              <Flex justifyContent="center">
                <label>{weekday}</label>
              </Flex>
            </Box>
          );
        })}
        <Box>
          <DiscreteColorLegend
            width={200}
            height={100}
            items={[
              { title: "Three Week Trailing Avg", color: "orange" },
              { title: "Adjusted Forecast", color: "blue" },
            ]}
            orientation="vertical"
          />
        </Box>
      </Flex>
    </Container>
  );
};
