import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useRef } from "react";
import { FiFile } from "react-icons/fi";

const HiddenInput = styled.input`
  display: none;
`;

type FileUploadProps = Pick<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "name" | "placeholder" | "children" | "value"
> & {
  isRequired?: boolean;
  acceptedFileTypes?: string;
  errorMessage?: string;
  onChange?: (file?: File) => void;
  testId?: string;
};

export const FileUpload = ({
  name,
  placeholder,
  acceptedFileTypes,
  children,
  errorMessage,
  onChange,
  isRequired = false,
  value,
  testId,
}: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange && e.currentTarget.validity.valid) {
      const firstFile = e.currentTarget.files?.[0];
      onChange(firstFile);
    }
  };

  return (
    <FormControl isInvalid={!!errorMessage} isRequired={isRequired}>
      <FormLabel htmlFor="file-input">{children}</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FiFile} />
        </InputLeftElement>
        <HiddenInput
          ref={inputRef}
          id="file-input"
          type="file"
          accept={acceptedFileTypes}
          name={name}
          onChange={inputOnChange}
          data-test-id={testId && `${testId}-input`}
        />
        <Input
          placeholder={placeholder}
          onClick={onClick}
          value={value}
          readOnly
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
