import { getISODateOfDayAfterISODate } from "../general/date";
import { getHourStringBySpreadsheetColumnIndex } from "./getHourStringBySpreadsheetColumnIndex";

export const getISODateTimeByHourlyDataSheetRowCol = (
  dates: string[],
  row: number,
  col: number
) => {
  const hourString = getHourStringBySpreadsheetColumnIndex(col);
  const dateRowUpdateIndex = parseInt(hourString, 10) >= 5 ? row - 1 : row;
  const isoDate =
    dates[dateRowUpdateIndex] ||
    getISODateOfDayAfterISODate(dates[dates.length - 1]);
  return `${isoDate}T${hourString}:00:00`;
};
