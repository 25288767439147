export interface MENU_ITEM {
  key: number;
  label: string;
  path?: string;
  children?: MENU_ITEM[];
  featureFlag?: string;
}

export const MENU_ITEMS: MENU_ITEM[] = [
  {
    key: 100,
    label: "OA Labor",
    children: [
      {
        key: 101,
        label: "Labor Hours QA",
        path: "/OaQA",
      },
      {
        key: 102,
        label: "Shift Leader Shift Requests",
        path: "/oaShiftLeaderShiftRequests",
      },
    ],
    featureFlag: "oaLaborEnabled",
  },
  {
    key: 1,
    label: "QA + Run",
    children: [
      { key: 2, label: "Ovf", path: "/OvfQA" },
      { key: 3, label: "Doppler QA", path: "/dopplerQA" },
      { key: 4, label: "DP Hours + Shifts", path: "/shiftQA" },
      { key: 22, label: "Midweek Reforecast", path: "/midweekReforecast" },
      { key: 23, label: "8 Week Forecast", path: "/multiWeekForecast" },
    ],
  },
  {
    key: 5,
    label: "Seasonality",
    children: [
      { key: 6, label: "Weekly", path: "/weeklyAdjust" },
      { key: 7, label: "Daily + Hourly", path: "/dailyHourlyAdjust" },
    ],
  },
  {
    key: 8,
    label: "Layers",
    children: [
      { key: 9, label: "Global", path: "/globalAdjust" },
      {
        key: 10,
        label: "Hourly",
        path: "/eventAdjustmentUpload",
      },
    ],
  },
  {
    key: 11,
    label: "Driver Hours",
    children: [
      { key: 12, label: "Location ODH Target", path: "/locationODHTarget" },
      { key: 13, label: "Upload OVF", path: "/uploadOVF" },
    ],
  },
  {
    key: 14,
    label: "Shifts",
  },
  {
    key: 15,
    label: "Doppler",
    children: [
      { key: 16, label: "Add Events" },
      { key: 17, label: "View Insights" },
    ],
  },
  {
    key: 18,
    label: "Admin",
    children: [
      { key: 19, label: "Permissions" },
      { key: 20, label: "Assign Locations", path: "/forecastersUpdate" },
      { key: 21, label: "User Profile", path: "/profile" },
    ],
  },
];
