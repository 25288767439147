import { GPUploadPopup } from "../../../components/GPUploadPopup/GPUploadPopup";
import { useUploadShiftLeaderShiftRequestsMutation } from "../../../generated/graphql";

interface UploadShiftLeaderShiftRequestsProps {
  setForceRefresh?: (forceRefresh: boolean) => void;
  onSuccess?: () => void;
  testId?: string;
}

export const UploadShiftLeaderShiftRequests: React.FC<
  UploadShiftLeaderShiftRequestsProps
> = ({ setForceRefresh, testId }) => {
  return (
    <GPUploadPopup
      setUploadComplete={setForceRefresh}
      modelTitle="Upload Shift Leader Shift Requests"
      modelHeader="Upload Shift Leader Shift Requests File"
      modelBody={
        <p>
          Upload a csv file of Shift Leader Shifts Requests. Previous Shift
          Leader Shift Requests will be overwritten.
        </p>
      }
      uploadMutation={useUploadShiftLeaderShiftRequestsMutation}
      uploadMutationProps={{}}
      uploadMutationRefetchQueries={["GetShiftLeaderShiftRequests"]}
      allowDatesOutsideSelectedWeekCheckbox={false}
      testId={testId}
    ></GPUploadPopup>
  );
};
