/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useDisclosure } from "@chakra-ui/hooks";
import lodash from "lodash";
import { useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { GPEditableCell } from "../../../components/GPEditableCell/GPEditableCell";
import { GPPopup } from "../../../components/GPPopup/GPPopup";
import { FetchDataArgs, GPTable } from "../../../components/GPTable/GPTable";
import {
  GetLocationOdhTargetDataQuery,
  GetLocationOdhTargetFieldsFragment,
  LocationTarget,
  Maybe,
  useCreateLocationOdhTargetDataMutation,
  useGetLocationOdhTargetDataQuery,
  useUpdateLocationOdhTargetDataMutation,
} from "../../../generated/graphql";
import { constants } from "../../../helpers/general/constants";
import { getGraphQLErrorsAsHumanReadableStrings } from "../../../helpers/graphql/getGraphQLErrorsAsHumanReadableStrings";
import styles from "./styles.module.css";

const pageSizes = [25, 50, 100, 150, 200];

const columns: Array<Column<GetLocationOdhTargetFieldsFragment>> = [
  {
    Header: "Location Id",
    accessor: "id",
    testId: "locationId",
  },
  {
    Header: "Location Title",
    accessor: "title",
  },
  {
    accessor: "locationTargetId",
  },
  {
    accessor: "locationId",
  },
  {
    Header: "Target Plan Date",
    accessor: "targetDate",
  },
  {
    Header: "Minimum Orders Delivered Per Hour",
    accessor: "minODH",
    Cell: GPEditableCell,
  },
  {
    Header: "Target Orders Delivered Per Hour",
    accessor: "targetODH",
    Cell: GPEditableCell,
    testId: "targetODH",
  },
  {
    Header: "Maximum Orders Delivered Per Hour",
    accessor: "maxODH",
    Cell: GPEditableCell,
  },
  {
    Header: "Must Request",
    accessor: "driverWaitTime",
    Cell: GPEditableCell,
  },
  {
    Header: "Minimum Car",
    accessor: "mindpCar",
    Cell: GPEditableCell,
  },
  {
    Header: "Minimum Bike",
    accessor: "mindpBike",
    Cell: GPEditableCell,
  },
  {
    Header: "Minimum Foot",
    accessor: "mindpFoot",
    Cell: GPEditableCell,
  },
  {
    Header: "Constrain Flag",
    accessor: "constrainFlag",
    Cell: GPEditableCell,
  },
  {
    Header: "Absentee Car",
    accessor: "absenteeAdjustCar",
    Cell: GPEditableCell,
  },
  {
    Header: "Absentee Bike",
    accessor: "absenteeAdjustBike",
    Cell: GPEditableCell,
  },
  {
    Header: "Absentee Foot",
    accessor: "absenteeAdjustFoot",
    Cell: GPEditableCell,
  },
  {
    Header: "Open From Override",
    accessor: "openFromOverride",
    Cell: GPEditableCell,
    cellClass: styles.locationHourInput,
  },
  {
    Header: "Open To Override",
    accessor: "openToOverride",
    Cell: GPEditableCell,
    cellClass: styles.locationHourInput,
  },
  {
    Header: "Shift Pad",
    accessor: "shiftPad",
    Cell: GPEditableCell,
  },
  {
    Header: "Absentee Lookback",
    accessor: "absenteeismLookbackWindow",
    Cell: GPEditableCell,
  },
  {
    Header: "Unsch. Penalty",
    accessor: "unscheduledPenalty",
    Cell: GPEditableCell,
  },
  {
    Header: "Absentee %",
    accessor: "absenteeismPercentile",
    Cell: GPEditableCell,
  },
  {
    Header: "Unsch. %",
    accessor: "unscheduledPercentile",
    Cell: GPEditableCell,
  },
  {
    Header: "Absentee Cap",
    accessor: "absenteeismCap",
    Cell: GPEditableCell,
  },
  {
    Header: "Unsch. Cap",
    accessor: "unscheduledCap",
    Cell: GPEditableCell,
  },
  {
    Header: "Shift Increment",
    accessor: "shiftIncrement",
    Cell: GPEditableCell,
  },
  {
    Header: "Absenteeism Cut Premium",
    accessor: "absenteeismCutPremium",
    Cell: GPEditableCell,
  },
  {
    Header: "Unscheduled Cut Premium",
    accessor: "unscheduledCutPremium",
    Cell: GPEditableCell,
  },
  {
    Header: "Taper Preset",
    accessor: "taperPreset",
    Cell: GPEditableCell,
    cellClass: styles.taperPresetInput,
    testId: "taper-preset",
  },
  {
    Header: "Skip Mui",
    accessor: "skipMui",
    Cell: GPEditableCell,
    cellClass: styles.booleanInput,
  },
];

const updateQueryCallback = (
  previousData: GetLocationOdhTargetDataQuery,
  findNodeToUpdate: (
    node: Maybe<Partial<LocationTarget>> | undefined
  ) => boolean,
  updateNode: (node: Partial<LocationTarget>) => void
): GetLocationOdhTargetDataQuery => {
  if (!previousData.locationTargets?.edges) {
    return previousData;
  }

  const newData = lodash.cloneDeep(previousData);
  const nodeToUpdate = newData?.locationTargets?.edges
    ?.map((edge) => edge?.node)
    .find(findNodeToUpdate);

  if (nodeToUpdate) {
    updateNode(nodeToUpdate);
  }
  return newData;
};

interface LocationODHTargetTableProps {
  selectedWeek: string;
  forceRefresh: boolean;
  setForceRefresh: (forceRefresh: boolean) => void;
}

export const LocationODHTargetTable: React.FC<LocationODHTargetTableProps> = ({
  selectedWeek,
  forceRefresh,
  setForceRefresh,
}) => {
  const [pageInfo, setPageInfo] = useState<FetchDataArgs>({
    pageSize: 25,
    pageIndex: 0,
  });

  const [popupHeader, setPopupHeader] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string | React.ReactElement>(
    ""
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, loading, refetch, updateQuery } =
    useGetLocationOdhTargetDataQuery({
      variables: {
        first: pageInfo.pageIndex * pageInfo.pageSize,
        last: pageInfo.pageSize,
        targetWeek: selectedWeek,
      },
      notifyOnNetworkStatusChange: true,
      skip: !selectedWeek,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first", // using cached results because if not, updateQuery() does not update the data,
      onCompleted: (completedData) => {
        setPageCount(
          Math.ceil(
            (completedData?.locationTargets?.totalCount ?? 0) /
              pageInfo.pageSize
          ) ?? constants.pagination.MINIMUM_PAGE_COUNT
        );
      },
    });

  const [createFunction] = useCreateLocationOdhTargetDataMutation({
    context: {
      skipDefaultErrorHandling: true,
    },
  });

  const [updateFunction] = useUpdateLocationOdhTargetDataMutation({
    context: {
      skipDefaultErrorHandling: true,
    },
  });

  const [pageCount, setPageCount] = useState(0);

  // How can we speedline this?
  const tableData: GetLocationOdhTargetFieldsFragment[] = useMemo(
    () =>
      data?.locationTargets?.edges?.reduce<
        GetLocationOdhTargetFieldsFragment[]
      >((accum, edge) => {
        if (edge?.node) {
          accum.push(edge.node);
        }
        return accum;
      }, []) ?? [],
    [data]
  );

  const updateCurrentData = async (args: {
    column: string;
    value: string;
    props: CellProps<any>;
  }): Promise<void> => {
    let value: any;

    if (
      args.column === "minODH" ||
      args.column === "targetODH" ||
      args.column === "maxODH" ||
      args.column === "driverWaitTime" ||
      args.column === "mindpCar" ||
      args.column === "mindpBike" ||
      args.column === "mindpFoot" ||
      args.column === "unscheduledPenalty" ||
      args.column === "absenteeismPercentile" ||
      args.column === "unscheduledPercentile" ||
      args.column === "absenteeismCap" ||
      args.column === "unscheduledCap" ||
      args.column === "absenteeismCutPremium" ||
      args.column === "unscheduledCutPremium"
    ) {
      value = Number(args.value);
    } else if (
      args.column === "absenteeAdjustCar" ||
      args.column === "absenteeAdjustBike" ||
      args.column === "absenteeAdjustFoot" ||
      args.column === "skipMui"
    ) {
      value = args.value !== "false";
    } else if (args.column === "shiftPad" || args.column === "shiftIncrement") {
      value =
        Number.isInteger(Number(args.value)) && Number(args.value)
          ? Number(args.value)
          : null;
    } else if (args.column === "absenteeismLookbackWindow") {
      value = Number.isInteger(Number(args.value)) ? Number(args.value) : 0;
    } else {
      value = args.value;
    }

    if (args.props?.cell?.row?.values?.locationTargetId) {
      const updateFunctionResult = await updateFunction({
        variables: {
          locationTargetUpdateInput: {
            id: args.props?.cell?.row?.values?.locationTargetId,
            [args.column]: value,
          },
        },
      });
      if (updateFunctionResult.errors) {
        setPopupHeader("Error");
        setPopupMessage(
          getGraphQLErrorsAsHumanReadableStrings(updateFunctionResult.errors)
        );
        onOpen();
      } else {
        updateQuery((previousData) => {
          return updateQueryCallback(
            previousData,
            (node) =>
              node?.locationTargetId ===
              args.props?.cell?.row?.values?.locationTargetId,
            (nodeToUpdate) => {
              (nodeToUpdate as Record<string, unknown>)[args.column] = value;
            }
          );
        });
      }
    } else if (args.column === "targetODH" && value) {
      const createFunctionResult = await createFunction({
        variables: {
          locationTargetCreateInput: {
            locationId: parseInt(args.props?.row?.values?.id, 10),
            targetDate: args.props?.row?.values?.targetDate,
            targetODH: value,
          },
        },
      });
      if (createFunctionResult.errors) {
        setPopupHeader("Error");
        setPopupMessage(
          getGraphQLErrorsAsHumanReadableStrings(createFunctionResult.errors)
        );
        onOpen();
      } else if (createFunctionResult.data?.createLocationTargets) {
        updateQuery((previousData) => {
          return updateQueryCallback(
            previousData,
            (node) =>
              node?.locationId === args.props?.cell?.row?.values?.locationId,
            (nodeToUpdate) => {
              nodeToUpdate.targetODH = value;
              nodeToUpdate.locationTargetId =
                createFunctionResult.data?.createLocationTargets?.id?.toString() ??
                "";
            }
          );
        });
      }
    } else {
      setPopupHeader("Error");
      setPopupMessage(
        "Please enter target ODH first before making other changes."
      );
      onOpen();
      void refetch();
    }
  };

  return (
    <section>
      <GPPopup
        isOpen={isOpen}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onClose}
      />
      <GPTable
        columns={columns}
        data={tableData}
        loading={loading}
        pageCount={pageCount}
        pageSizeOptions={pageSizes}
        onPageChange={setPageInfo}
        totalCount={data?.locationTargets?.totalCount ?? 0}
        hiddenColumns={["locationTargetId", "locationId"]}
        updateCurrentData={updateCurrentData}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        testId="location-odh-target-table"
      />
    </section>
  );
};
