import {
  addDays,
  addWeeks,
  format,
  formatDistanceToNow,
  getDay,
  getHours,
  getWeek,
  isMonday,
  nextMonday,
  parse,
  subDays,
  subWeeks,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { constants } from "./constants";
const DATE_FORMAT_LONG = "PPPPpp";
const DATE_FORMAT_SHORT = "yyyy-MM-dd";
const DATE_FORMAT_UPLOAD = "MM.dd.yy";
const DATE_FORMAT_SHIFTS = "MM/dd/yyyy";
const TIME_FORMAT_SHIFTS_START_END_TIME = "h:mm a";
const TIME_FORMAT_SHIFTS_TIME = "H:mm";
const NY_TIMEZONE_STRING = "America/New_York";

export function getISODate(date: Date): string {
  return (
    date.toISOString().substring(0, 4) +
    "-" +
    date.toISOString().substring(5, 7) +
    "-" +
    date.toISOString().substring(8, 10)
  );
}

export function parseTimeString(timeStr: string | null): Date | null {
  return timeStr ? new Date("1970-01-01T" + timeStr) : null;
}

export const formatShiftStartEndTimeString = (dateStr?: string | Date | null) =>
  dateStr
    ? format(new Date(dateStr), TIME_FORMAT_SHIFTS_START_END_TIME)
    : "Unknown";

export const formatShiftTimeString = (dateStr?: string | Date | null) =>
  dateStr ? format(new Date(dateStr), TIME_FORMAT_SHIFTS_TIME) : "Unknown";

export function parseShortDate(dateStr: string): Date;
export function parseShortDate(dateStr?: null): null;
export function parseShortDate(dateStr?: string | null) {
  return dateStr ? parse(dateStr, DATE_FORMAT_SHORT, new Date()) : null;
}
export const formatShortDate = (date: Date) => format(date, DATE_FORMAT_SHORT);

export const formatShortDateString = (dateStr?: string | Date | null) =>
  dateStr ? format(new Date(dateStr), DATE_FORMAT_SHORT) : "Unknown";

export const formatLongDateString = (dateStr?: string | Date | null) =>
  dateStr ? format(new Date(dateStr), DATE_FORMAT_LONG) : "Unknown";

export const formatShiftDateString = (dateStr?: string | Date | null) =>
  dateStr ? format(new Date(dateStr), DATE_FORMAT_SHIFTS) : "Unknown";

export const prettyFormatDistanceToNow = (dateStr?: string | Date | null) =>
  dateStr
    ? formatDistanceToNow(new Date(dateStr), {
        addSuffix: true,
      })
    : "Unknown";

export function getYearOfISODate(isoDate: string) {
  return isoDate.substring(0, 4);
}

export function getMonthOfISODate(isoDate: string) {
  return isoDate.substring(5, 7);
}

export function getDayOfISODate(isoDate: string) {
  return isoDate.substring(8, 10);
}

export const getISODateOfDayAfterISODate = function (isoDate: string): string {
  const year = parseInt(getYearOfISODate(isoDate), 10);
  const month = parseInt(getMonthOfISODate(isoDate), 10) - 1;
  const day = parseInt(getDayOfISODate(isoDate), 10);
  const isoDateTimeOfNextDay = addDays(
    new Date(year, month, day),
    1
  ).toISOString();
  return (
    `${getYearOfISODate(isoDateTimeOfNextDay)}` +
    `-${getMonthOfISODate(isoDateTimeOfNextDay)}` +
    `-${getDayOfISODate(isoDateTimeOfNextDay)}`
  );
};

export function getISODatesOfCurrentOperationalWeek(): string[] {
  const dates = [];
  for (let i = 0; i < constants.time.DAYS_IN_A_WEEK; i++) {
    dates.push(
      format(
        addDays(getDateOfCurrentOperationalMonday(), i),
        constants.time.DATE_FNS_DATE_ISO_FORMAT_SPECIFIER
      )
    );
  }
  return dates;
}
export function getDateOfCurrentOperationalMonday(): Date {
  return addWeeks(getDateOfNextOperationalMonday(), -1);
}

export function getDateOfNextOperationalMonday(): Date {
  const currentDateNY = utcToZonedTime(new Date(), NY_TIMEZONE_STRING);
  const isTodayMonday =
    constants.time.DATE_FNS_ENUM_VALUE_TO_DAY_OF_WEEK[getDay(currentDateNY)] ===
    "Monday";
  const isBefore5am = getHours(currentDateNY) < 5;
  return isTodayMonday && isBefore5am
    ? currentDateNY
    : nextMonday(currentDateNY);
}

export function getISODatesofCurrentOperationalWeek(): string[] {
  const startDate = getDateOfCurrentOperationalMonday();
  const operationalDaysInWeek = [] as string[];
  for (
    let dayOfWeek = 0;
    dayOfWeek < constants.time.DAYS_IN_A_WEEK;
    dayOfWeek++
  ) {
    operationalDaysInWeek.push(
      format(
        addDays(startDate, dayOfWeek),
        constants.time.DATE_FNS_DATE_ISO_FORMAT_SPECIFIER
      )
    );
  }
  return operationalDaysInWeek;
}

export function getISODateOfNextOperationalMonday(): string {
  return format(
    getDateOfNextOperationalMonday(),
    constants.time.DATE_FNS_DATE_ISO_FORMAT_SPECIFIER
  );
}

export function getISODateOfCurrentOperationalMonday(): string {
  return format(
    getDateOfCurrentOperationalMonday(),
    constants.time.DATE_FNS_DATE_ISO_FORMAT_SPECIFIER
  );
}

export function getISODatesOfZeroToNWeeksOfMondays(
  weeksOutToForecast: number
): string[] {
  return getISODatesOfWeeksOfMondays(0, weeksOutToForecast);
}

export function getISODatesOfOneToNWeeksOfMondays(
  weeksOutToForecast: number
): string[] {
  return getISODatesOfWeeksOfMondays(1, weeksOutToForecast);
}

export function getISODatesOfStartingDateToNWeeksOfMondays(
  weeksOutToForecast: number,
  startingDate: string
): string[] {
  return [startingDate, ...getISODatesOfWeeksOfMondays(1, weeksOutToForecast)];
}

export function getISODatesOfWeeksOfMondaysFromStartingDate(
  weeksOutToForecast: number,
  startingDate: Date,
  today?: Date,
  includeStartingDate?: boolean,
  previousWeeksToInclude = 0
): string[] {
  today = today ?? utcToZonedTime(new Date(), NY_TIMEZONE_STRING);
  const currentDate = startingDate;
  if (
    getOperationalWeek(currentDate) === getOperationalWeek(today) &&
    getDay(currentDate) >
      constants.time.DATE_FNS_ENUM_DAY_OF_WEEK_TO_VALUE["Monday"]
  ) {
    const weeks = getISODatesOfOneToNWeeksOfMondays(weeksOutToForecast);
    if (includeStartingDate) {
      return [getISODateOfFirstMondayOnOrBeforeDate(startingDate), ...weeks];
    }

    return weeks;
  }
  return getISODatesOfWeeksOfMondays(
    previousWeeksToInclude,
    weeksOutToForecast -
      (getOperationalWeek(today) - getOperationalWeek(currentDate))
  );
}

export function getISODatesOfWeeksOfMondays(
  startWeek: number,
  weeksOutToForecast: number
): string[] {
  const currentDateNY = utcToZonedTime(new Date(), NY_TIMEZONE_STRING);
  const isTodayMonday =
    constants.time.DATE_FNS_ENUM_VALUE_TO_DAY_OF_WEEK[getDay(currentDateNY)] ===
    "Monday";
  const isBefore5am = getHours(currentDateNY) < 5;
  const dateOfPreviousOperationalMonday =
    isTodayMonday && isBefore5am
      ? subWeeks(currentDateNY, 1)
      : subWeeks(nextMonday(currentDateNY), 1);

  const isoDatesOfOneToNWeeksOfMondays: string[] = [];

  for (let week = startWeek; week <= weeksOutToForecast; week++) {
    isoDatesOfOneToNWeeksOfMondays.push(
      format(
        addWeeks(dateOfPreviousOperationalMonday, week),
        constants.time.DATE_FNS_DATE_ISO_FORMAT_SPECIFIER
      )
    );
  }

  return isoDatesOfOneToNWeeksOfMondays;
}

export function getISODateFromTimestampString(timestampString: string) {
  return (
    timestampString.substring(0, 4) +
    "-" +
    timestampString.substring(5, 7) +
    "-" +
    timestampString.substring(8, 10)
  );
}

function getOperationalWeek(date: Date): number {
  const dayOffset =
    constants.time.DATE_FNS_ENUM_DAY_OF_WEEK_TO_VALUE["Sunday"] -
    constants.time.DATE_FNS_ENUM_DAY_OF_WEEK_TO_VALUE["Monday"];
  return getWeek(addDays(date, dayOffset));
}

export function getDateForUpload() {
  return format(new Date(), DATE_FORMAT_UPLOAD);
}

export function getDateInLocalTime(date: Date): Date {
  const returnDate = new Date(date);
  const timeOffsetInMS: number = returnDate.getTimezoneOffset() * 60000;
  returnDate.setTime(returnDate.getTime() + timeOffsetInMS);
  return returnDate;
}

export function getISODateOfToday(): string {
  const currentDateNY = utcToZonedTime(new Date(), NY_TIMEZONE_STRING);
  return format(
    currentDateNY,
    constants.time.DATE_FNS_DATE_ISO_FORMAT_SPECIFIER
  );
}

export function getDatetimeObjectFromISODate(
  isoDate: string,
  hour?: number
): Date {
  return new Date(
    Date.UTC(
      parseInt(getYearOfISODate(isoDate), 10),
      parseInt(getMonthOfISODate(isoDate), 10) - 1,
      parseInt(getDayOfISODate(isoDate), 10),
      hour ?? 0,
      0,
      0,
      0
    )
  );
}

export function getISODateOfFirstMondayOnOrBeforeDate(startDate: Date) {
  let currentDate = new Date(startDate);

  while (!isMonday(currentDate)) {
    currentDate = subDays(currentDate, 1);
  }

  return format(currentDate, constants.time.DATE_FNS_DATE_ISO_FORMAT_SPECIFIER);
}
