import { Code, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GPUploadPopup } from "../../../components/GPUploadPopup/GPUploadPopup";
import { useUploadOdhTargetsMutation } from "../../../generated/graphql";
import { TEXT } from "../../../helpers/constants/TEXT";
import styles from "./styles.module.css";

interface UpdateTargetModalProps {
  setForceRefresh: (forceRefresh: boolean) => void;
  selectedWeek: string;
}

export const UploadTargetsModal: React.FC<UpdateTargetModalProps> = ({
  setForceRefresh,
  selectedWeek,
}) => {
  const mutationParameters = {
    selectedWeek,
  };

  return (
    <GPUploadPopup
      setUploadComplete={setForceRefresh}
      modelTitle={TEXT.UPLOAD_TARGETS}
      modelHeader={TEXT.HEADER_UPLOAD_TARGETS}
      size="6xl"
      testId="upload-targets-popup"
      modelBody={
        <>
          <p className={styles.uploadMessage}>
            Upload a csv formatted file of odh targets per location. Any
            existing targets for a location/date combination will be overwritten
            with the new values. Required columns are <Code>location</Code>,{" "}
            <Code>date</Code>, <Code>target</Code>, <Code>driverwaittime</Code>,{" "}
            <Code>mindpcar</Code>, <Code>mindpbike</Code>,<Code>mindpfoot</Code>{" "}
            and <Code>constrainflag</Code>.
          </p>
          <p className={styles.uploadMessage}>
            Optionally <Code>min</Code> and <Code>max</Code> columns can be used
            to supply min/max acceptable odh values. If missing these will be
            inferred as ± 20% of the target.
          </p>
          <span>For Example:</span>
          <Table className={styles.table} variant="striped">
            <Thead>
              <Tr>
                <Th>Location</Th>
                <Th>Date</Th>
                <Th>Min</Th>
                <Th>Target</Th>
                <Th>Max</Th>
                <Th>DriverWaitTime</Th>
                <Th>MindpCar</Th>
                <Th>MindpBike</Th>
                <Th>MindpFoot</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>57</Td>
                <Td>4/25/21</Td>
                <Td>2.55</Td>
                <Td>3.19</Td>
                <Td>3.83</Td>
                <Td>8.25</Td>
                <Td>3</Td>
                <Td>8</Td>
                <Td>10</Td>
              </Tr>
            </Tbody>
          </Table>
          <Table className={styles.table} variant="striped">
            <Thead>
              <Tr>
                <Th>ConstrainFlag</Th>
                <Th>absenteeAdjustCar</Th>
                <Th>absenteeAdjustBike</Th>
                <Th>absenteeAdjustFoot</Th>
                <Th>openFromOverride</Th>
                <Th>openToOverride</Th>
                <Th>shiftPad</Th>
                <Th>absenteeismLookbackWindow</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>cost</Td>
                <Td>true</Td>
                <Td>true</Td>
                <Td>false</Td>
                <Td>07:00</Td>
                <Td></Td>
                <Td>30</Td>
                <Td>2</Td>
              </Tr>
            </Tbody>
          </Table>
          <Table className={styles.table} variant="striped">
            <Thead>
              <Tr>
                <Th>unscheduledPenalty</Th>
                <Th>absenteeismPercentile</Th>
                <Th>unscheduledPercentile</Th>
                <Th>absenteeismCap</Th>
                <Th>unscheduledCap</Th>
                <Th>shiftIncrement</Th>
                <Th>absenteeismCutPremium</Th>
                <Th>unscheduledCutPremium</Th>
                <Th>taperPreset</Th>
                <Th>skipMui</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>3.4</Td>
                <Td>3.3</Td>
                <Td>4.32</Td>
                <Td>0</Td>
                <Td>4.3</Td>
                <Td>5</Td>
                <Td>1.5</Td>
                <Td>2.5</Td>
                <Td>Basic Taper</Td>
                <Td>false</Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      }
      uploadMutation={useUploadOdhTargetsMutation}
      uploadMutationProps={mutationParameters}
      allowDatesOutsideSelectedWeekCheckbox
    ></GPUploadPopup>
  );
};
