export function roundToDecimalPlaces(
  number: number,
  decimalPlaces: number
): number {
  return parseFloat(
    (
      Math.round(number * Math.pow(10, decimalPlaces)) /
      Math.pow(10, decimalPlaces)
    ).toFixed(decimalPlaces)
  );
}

export function toEmptyOrRoundedString(
  number: number | null | undefined,
  decimalPlaces = 2
): string {
  return typeof number === "number"
    ? roundToDecimalPlaces(number, decimalPlaces).toLocaleString("en-US")
    : "";
}

export function toEmptyStringIfNotNumber(
  number: number | null | undefined
): string {
  return typeof number === "number" ? number.toLocaleString("en-US") : "";
}
