import { get2DArray } from "../general/array";

export const getWeeklyOdh = (
  weeklyOrderForecast: Array<Array<number | null>>,
  weeklyDriverHours: Array<Array<number | null>>
) => {
  const weeklyOdh = get2DArray<number | null>(
    weeklyDriverHours.length,
    weeklyDriverHours[0].length,
    0
  );

  weeklyOdh.forEach((hourlyDataforDay, row) => {
    hourlyDataforDay.forEach((_hourlyValue, col) => {
      const hourlyOrderForecast = weeklyOrderForecast[row][col];
      const hourlyDriverHours = weeklyDriverHours[row][col];
      if (hourlyOrderForecast === null || hourlyDriverHours === null) {
        weeklyOdh[row][col] = null;
      }
      weeklyOdh[row][col] =
        hourlyOrderForecast && hourlyDriverHours
          ? parseFloat((hourlyOrderForecast / hourlyDriverHours).toFixed(2))
          : 0;
    });
  });

  return weeklyOdh;
};
