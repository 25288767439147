import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface GPPopupOptionProps {
  isOpen: boolean;
  header: string;
  body: React.ReactElement;
  onClose: () => void;
  onContinue: () => void;
  testId?: string;
}

export const GPPopupOption: React.FC<GPPopupOptionProps> = (props) => {
  const { isOpen, header, body, onClose, onContinue, testId } = props;
  return (
    <Modal id="gp-popup-option" isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div style={{ whiteSpace: "pre-line" }}>{body}</div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={onClose}
            data-test-id={testId ? `${testId}-close-button` : ""}
          >
            Cancel
          </Button>
          <Button
            variant="ghost"
            onClick={onContinue}
            data-test-id={testId ? `${testId}-continue-button` : ""}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
