import { DAY_INDEX_TO_NAME, GridElement } from "./GPHourlyDataSheet";

export const getDataRowsWithoutSummaryColumns = (
  hourlyDataForWeek: Array<Array<number | null>>,
  dataRows: GridElement[][],
  dates: string[],
  isEditable: boolean | undefined
): GridElement[][] => {
  let dataRowsWithoutSummaryColumns = dataRows;
  hourlyDataForWeek.forEach((hourlyDataForDay, index) => {
    dataRowsWithoutSummaryColumns = dataRowsWithoutSummaryColumns.concat(
      new Array([
        { value: dates[index], disableEvents: true },
        { value: DAY_INDEX_TO_NAME[index], disableEvents: true },
        ...hourlyDataForDay.map(
          (dataValue): GridElement => ({
            value: dataValue,
            isHourlyDataCell: true,
            disableEvents: !isEditable,
          })
        ),
      ])
    );
  });
  return dataRowsWithoutSummaryColumns;
};
