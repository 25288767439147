/* eslint-disable react/jsx-key */
import {
  Center,
  ChakraProvider,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Column, useTable } from "react-table";
import { Maybe } from "../../../generated/graphql";
import { constants } from "../../../helpers/general/constants";
import { roundToDecimalPlaces } from "../../../helpers/general/number";
import { DailySummaryTableCell } from "./DailySummaryTableCell/DailySummaryTableCell";

export interface DailySummaryTableRow {
  id: number;
  dataSet: string;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  sun: number;
}

export interface DailySummaryTableProps {
  id?: string;
  dates: string[];
  locationId: number;
  operationalWeekStartDate: string;
  handleEnterDailyAdjustment: (upsertWeeklyAdjustmentVars: {
    locationId: number;
    forecasterId?: Maybe<number> | undefined;
    adjustmentType: string;
    operationalWeekStartDate: string;
    dateString: string;
    value?: number | null;
  }) => Promise<void>;
  upsertSeasonalAdjustmentLoading: boolean;
  tableData: DailySummaryTableRow[];
  loading: boolean;
}

export const editableTargetRowHeader = "Enter Target %";

const dayColumns = constants.time.DAYS_OF_THE_WEEK_ABBREVIATIONS.map((day) => {
  return {
    Header: day,
    accessor: (row: DailySummaryTableRow) => {
      if (row.dataSet === editableTargetRowHeader) return row[day];
      return row[day].toFixed(2) + "%";
    },
    Cell: DailySummaryTableCell,
    minWidth: 100,
    testId: day,
  };
});

const columns: Array<Column<DailySummaryTableRow>> = [
  {
    Header: "Data Set",
    accessor: "dataSet",
    minWidth: 175,
  },
  ...dayColumns,
  {
    Header: "Total",
    accessor: (row) => {
      let rowSum = roundToDecimalPlaces(
        row.mon + row.tue + row.wed + row.thu + row.fri + row.sat + row.sun,
        2
      );
      if (rowSum > 99.9 && rowSum < 100.0) {
        rowSum = 100.0;
      }
      return `${rowSum.toFixed(2)}%`;
    },
    minWidth: 100,
  },
];

export const DailySummaryTable: React.FC<
  React.PropsWithChildren<DailySummaryTableProps>
> = ({
  id,
  dates,
  locationId,
  operationalWeekStartDate,
  handleEnterDailyAdjustment,
  upsertSeasonalAdjustmentLoading,
  tableData,
  loading,
}) => {
  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable({
    columns: columns,
    data: tableData,
  });

  return (
    <ChakraProvider>
      <Table
        id={id ?? ""}
        size="sm"
        variant="striped"
        data-test-id="daily-summary-table"
      >
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth },
                  })}
                >
                  <Flex alignItems="center">{column.render("Header")}</Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {loading ? (
          <Tbody>
            <Tr>
              <Td colSpan={10000}>
                <Center>
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        data-test-column={cell.column.testId}
                      >
                        {cell.render("Cell", {
                          dates,
                          handleEnterDailyAdjustment,
                          colIndex: index - 1,
                          locationId,
                          operationalWeekStartDate,
                          upsertSeasonalAdjustmentLoading,
                        })}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
    </ChakraProvider>
  );
};
