import { ApolloProvider } from "@apollo/client/react/context/ApolloProvider";
import { ChakraProvider } from "@chakra-ui/react";
import { SplitFactory } from "@splitsoftware/splitio-react";
import { client } from "./apollo/apolloClient";
import { AppRoutes } from "./auth/Routes";
import splitIoConfiguration from "./helpers/featureFlags/splitIoConfiguration";
import "./app.css";

export const App = () => {
  return (
    <ProvidersWrapper>
      <div className="forecasting-app">
        <AppRoutes />
      </div>
    </ProvidersWrapper>
  );
};

export const ProvidersWrapper = ({ children }: { children: JSX.Element }) => {
  return (
    <SplitFactory config={splitIoConfiguration}>
      <ChakraProvider>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </ChakraProvider>
    </SplitFactory>
  );
};
