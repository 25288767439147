import React from "react";
import {
  getHourlyDataForWeekAfterOverrides,
  GetTotalRowsAfterOverridesHandler,
} from "../../helpers/location/getHourlyDataForWeekAfterOverrides";
import {
  GPHourlyDataSheet,
  GPHourlyDataSheetProps,
} from "../GPHourlyDataSheet/GPHourlyDataSheet";
import { getDataCellStyler } from "./getDataCellStyler";
import { getMaxValuesInHourlyData } from "./getMaxValuesInHourlyData";

type GPHourlyDataHeatSheetProps = Pick<
  GPHourlyDataSheetProps,
  | "dates"
  | "hourlyDataForWeek"
  | "summaryColumnsDataRows"
  | "isPercentage"
  | "dataCellWidth"
> & {
  id?: string;
  hourlyDataOverrides?: Array<Array<number | null>>;
  getTotalRowsAfterOverridesHandler?: GetTotalRowsAfterOverridesHandler;
  nullValuePlaceholder?: string;
  testId?: string;
};

export const GPHourlyDataHeatSheet: React.FC<GPHourlyDataHeatSheetProps> = ({
  id,
  hourlyDataForWeek,
  hourlyDataOverrides,
  dates,
  summaryColumnsDataRows,
  isPercentage,
  getTotalRowsAfterOverridesHandler,
  nullValuePlaceholder,
  dataCellWidth,
  testId,
}) => {
  const hourlyDataForWeekAfterOverrides = getHourlyDataForWeekAfterOverrides(
    hourlyDataForWeek,
    hourlyDataOverrides,
    getTotalRowsAfterOverridesHandler
  );

  const { maxValueInDataRows, maxValueInTotalRow } = getMaxValuesInHourlyData(
    hourlyDataForWeekAfterOverrides
  );

  const getDataCellStyles = getDataCellStyler(
    maxValueInDataRows,
    maxValueInTotalRow,
    hourlyDataOverrides,
    dataCellWidth
  );

  return (
    <GPHourlyDataSheet
      id={id}
      hourlyDataForWeek={hourlyDataForWeekAfterOverrides}
      dates={dates}
      summaryColumnsDataRows={summaryColumnsDataRows}
      isPercentage={isPercentage}
      getDataCellStyles={getDataCellStyles}
      nullValuePlaceholder={nullValuePlaceholder}
      testId={testId}
    />
  );
};
