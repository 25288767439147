import { useOktaAuth } from "@okta/okta-react";
import { OktaOAuthError } from "@okta/okta-signin-widget";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

export const Home = ({
  setCorsErrorModalOpen,
}: {
  setCorsErrorModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) return null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const login = () => navigate("/login");

  const isCorsError = (err?: OktaOAuthError): boolean => {
    if (!err) {
      return false;
    }
    return err.name === "AuthApiError" && !err.statusCode;
  };
  const logout = async () => {
    const basename = `${window.location.origin}/`;
    try {
      oktaAuth.options.logoutUrl = basename;
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err as OktaOAuthError)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };
  const button = authState.isAuthenticated ? (
    <button
      onClick={() => {
        void logout();
      }}
    >
      Logout
    </button>
  ) : (
    <button onClick={login}>Login</button>
  );

  return <div>{button}</div>;
};
