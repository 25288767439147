import { Box, Button, Container, Flex } from "@chakra-ui/react";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { useUpdateForecasterAssignmentsMutation } from "../../generated/graphql";

export const ForecastersUpdate = () => {
  const [startUpdate, { loading: updateForecastersLoading }] =
    useUpdateForecasterAssignmentsMutation();

  return (
    <Container alignContent="center">
      {updateForecastersLoading && <PageSpinner />}
      <Flex>
        <Box mx={5}>
          <Button
            isLoading={updateForecastersLoading}
            loadingText="Updating assignments"
            onClick={() => {
              void startUpdate();
            }}
            colorScheme="blue"
          >
            Update Forecaster Assignments
          </Button>
        </Box>
      </Flex>
    </Container>
  );
};
