import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useState } from "react";
import { DropdownSelect } from "../../../components/DropdownSelect/DropdownSelect";
import {
  useGetMetroRegionsQuery,
  useGetOaLocationClustersQuery,
  useGetOaLocationsQuery,
} from "../../../generated/graphql";
import { GPPopupOption } from "../../ShiftQA/GPPopupOption/GPPopupOption";
import styles from "../styles.module.css";
import stateOptions from "./constants/stateOptions.json";

interface ShifterInputPopupProps {
  isOpen: boolean;
  countryCode: string;
  shifterMode: boolean;
  selectedAdjustedOaRunId: number;
  onClose: () => void;
  onContinue: (input: ShifterInput) => Promise<void>;
}

export interface ShifterInput {
  shifterClusters: number[];
  shifterStateCodes: string[];
  shifterMetroRegions: string[];
  shifterLocationsToInclude: number[];
  shifterLocationsToExclude: number[];
  shifterMinShiftLength: number;
  shifterMaxShiftLength: number;
  shifterShiftIncrement: number;
  shifterRoundingAdjustment: string;
  shifterOphAdjustment: number;
  shifterFirstRouterOrderThreshold: number;
  shifterSecondRouterOrderThreshold: number;
  shifterInboundTph: number;
  shifterOvfAssumption: string;
  shifterPackRiskPph: number;
  shifterKitchenOvfAssumption: string;
  shifterKitchenRiskPph: number;
  shifterPackPphDecrease: string;
  shifterTargetsOphType: string;
  shifterOrderForecast: number;
  shifterIcqaTph: number;
}

export const ShifterInputPopup: React.FC<ShifterInputPopupProps> = (props) => {
  const { isOpen, countryCode: countyCode, onClose, onContinue } = props;
  const [shifterClusters, setShifterClusters] = useState<number[]>([]);
  const [shifterStateCodes, setShifterStateCodes] = useState<string[]>([]);
  const [shifterMetroRegions, setShifterMetroRegions] = useState<string[]>([]);
  const [shifterLocationsToInclude, setShifterLocationsToInclude] = useState<
    number[]
  >([]);
  const [shifterLocationsToExclude, setShifterLocationsToExclude] = useState<
    number[]
  >([]);
  const [shifterMinShiftLength, setShifterMinShiftLength] = useState<number>(6);
  const [shifterMaxShiftLength, setShifterMaxShiftLength] = useState<number>(8);
  const [shifterShiftIncrement, setShifterShiftIncrement] =
    useState<number>(60);
  const [shifterRoundingAdjustment, setShifterRoundingAdjustment] =
    useState<string>("Ceiling");
  const [shifterOphAdjustment, setShifterOphAdjustment] = useState<number>(0);
  const [
    shifterFirstRouterOrderThreshold,
    setShifterFirstRouterOrderThreshold,
  ] = useState<number>(80);
  const [
    shifterSecondRouterOrderThreshold,
    setShifterSecondRouterOrderThreshold,
  ] = useState<number>(80);
  const [shifterInboundTph, setShifterInboundTph] = useState<number>(208);
  const [shifterOvfAssumption, setShifterOvfAssumption] =
    useState<string>("-0.05");
  const [shifterPackRiskPph, setShifterPackRiskPph] = useState<number>(24);
  const [shifterKitchenOvfAssumption, setShifterKitchenOvfAssumption] =
    useState<string>("0");
  const [shifterKitchenRiskPph, setShifterKitchenRiskPph] = useState<number>(7);
  const [shifterPackPphDecrease, setShifterPackPphDecrease] =
    useState<string>("-35%");
  const [shifterTargetsOphType, setShifterTargetsOphType] =
    useState<string>("Target OPH");
  const [shifterOrderForecast, setShifterOrderForecast] = useState<number>(0);
  const [shifterIcqaTph, setShifterIcqaTph] = useState<number>(65);

  const { data: oaLocationClusters, loading: oaLocationClustersLoading } =
    useGetOaLocationClustersQuery();

  const { data: metroRegions, loading: metroRegionsLoading } =
    useGetMetroRegionsQuery({ variables: { countryCode: countyCode } });

  const { data: locations, loading: locationsLoading } = useGetOaLocationsQuery(
    { variables: { oaLocationsInput: { country_codes: [countyCode] } } }
  );

  return (
    <GPPopupOption
      isOpen={isOpen}
      header={props.shifterMode ? "Shifter Input" : "GoSked Input"}
      onClose={onClose}
      onContinue={() =>
        void onContinue({
          shifterClusters,
          shifterStateCodes,
          shifterMetroRegions,
          shifterLocationsToInclude,
          shifterLocationsToExclude,
          shifterMinShiftLength,
          shifterMaxShiftLength,
          shifterShiftIncrement,
          shifterRoundingAdjustment,
          shifterOphAdjustment,
          shifterFirstRouterOrderThreshold,
          shifterSecondRouterOrderThreshold,
          shifterInboundTph,
          shifterOvfAssumption,
          shifterPackRiskPph,
          shifterKitchenOvfAssumption,
          shifterKitchenRiskPph,
          shifterPackPphDecrease,
          shifterTargetsOphType,
          shifterOrderForecast,
          shifterIcqaTph,
        })
      }
      testId="shifter-input-popup"
      body={
        <Container>
          <Box margin="10px">
            {/* GoSked Parameters */}
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel>Location Clusters</FormLabel>
              <Select
                isMulti
                name="sample"
                colorScheme="blue"
                isLoading={oaLocationClustersLoading}
                options={oaLocationClusters?.oaLocationClusters.map(
                  (cluster) => ({
                    value: cluster?.id,
                    label: cluster?.name,
                  })
                )}
                placeholder="Select Clusters..."
                closeMenuOnSelect={false}
                onChange={(selectedOptions) => {
                  setShifterClusters(
                    selectedOptions?.map((option) => option.value) as number[]
                  );
                }}
                id="shifter-location-clusters"
                data-test-id="shifter-location-clusters"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                Locations State Codes
              </FormLabel>
              <Select
                isMulti
                name="sample"
                colorScheme="blue"
                options={stateOptions}
                placeholder="Select States..."
                closeMenuOnSelect={false}
                onChange={(selectedOptions) => {
                  setShifterStateCodes(
                    selectedOptions?.map((option) => option.value)
                  );
                }}
                id="shifter-state-codes"
                data-test-id="shifter-state-codes"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                Metro Regions
              </FormLabel>
              <Select
                isMulti
                name="sample"
                colorScheme="blue"
                isLoading={metroRegionsLoading}
                options={metroRegions?.metroRegions?.map((region) => ({
                  value: region,
                  label: region,
                }))}
                placeholder="Select Metro Regions..."
                closeMenuOnSelect={false}
                onChange={(selectedOptions) => {
                  setShifterMetroRegions(
                    selectedOptions?.map((option) => option.value) as string[]
                  );
                }}
                id="shifter-metro-regions"
                data-test-id="shifter-metro-regions"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                Locations to Include
              </FormLabel>
              <Select
                isMulti
                name="sample"
                colorScheme="blue"
                isLoading={locationsLoading}
                options={locations?.oaLocations.map((location) => ({
                  value: location?.location_id,
                  label: location?.location_id,
                }))}
                placeholder="Select Locations..."
                closeMenuOnSelect={false}
                onChange={(selectedOptions) => {
                  setShifterLocationsToInclude(
                    selectedOptions?.map((option) => option?.value as number)
                  );
                }}
                id="locations-to-include"
                data-test-id="locations-to-include"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                Locations to Exclude
              </FormLabel>
              <Select
                isMulti
                name="sample"
                colorScheme="blue"
                isLoading={locationsLoading}
                options={locations?.oaLocations.map((location) => ({
                  value: location?.location_id,
                  label: location?.location_id,
                }))}
                placeholder="Select Locations..."
                closeMenuOnSelect={false}
                onChange={(selectedOptions) => {
                  setShifterLocationsToExclude(
                    selectedOptions?.map((option) => option?.value as number)
                  );
                }}
                id="locations-to-exclude"
                data-test-id="locations-to-exclude"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <DropdownSelect
                placeholder="Select Value"
                label="Adjustment (Ceiling, Floor, Round)"
                selectOptions={["Ceiling", "Floor", "Round"]}
                selection={shifterRoundingAdjustment}
                key={shifterRoundingAdjustment}
                onChange={setShifterRoundingAdjustment}
                testId="shifter-rounding-adjustment"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                Hourly Historical Oph Curve Weekday OPH Adjustment Override
              </FormLabel>
              <NumberInput
                value={shifterOphAdjustment}
                onChange={(_: string, value: number) =>
                  setShifterOphAdjustment(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-oph-adjustment"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                OA Labor & Shift Planning 1st Router Order Threshold Parameter
              </FormLabel>
              <NumberInput
                value={shifterFirstRouterOrderThreshold}
                onChange={(_: string, value: number) =>
                  setShifterFirstRouterOrderThreshold(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-first-router-order-threshold"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                OA Labor & Shift Planning 2nd Router Order Threshold Parameter
              </FormLabel>
              <NumberInput
                value={shifterSecondRouterOrderThreshold}
                onChange={(_: string, value: number) =>
                  setShifterSecondRouterOrderThreshold(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-second-router-order-threshold"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                OA Labor & Shift Planning Inbound (Rec & PutAway) TPH Parameter
              </FormLabel>
              <NumberInput
                value={shifterInboundTph}
                onChange={(_: string, value: number) =>
                  setShifterInboundTph(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-inbound-tph"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                OA Labor & Shift Planning OvF Assumption Parameter
              </FormLabel>
              <Input
                value={shifterOvfAssumption}
                onChange={(event) =>
                  setShifterOvfAssumption(event?.target.value)
                }
                data-test-id="shifter-ovf-assumption"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                OA Labor & Shift Planning Pack Risk PPH Parameter
              </FormLabel>
              <NumberInput
                value={shifterPackRiskPph}
                onChange={(_: string, value: number) =>
                  setShifterPackRiskPph(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-pack-risk-pph"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                Kitchen OvF Assumption Parameter
              </FormLabel>
              <Input
                value={shifterKitchenOvfAssumption}
                onChange={(event) =>
                  setShifterKitchenOvfAssumption(event?.target.value)
                }
                data-test-id="shifter-kitchen-ovf-assumption"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                Kitchen Risk PPH
              </FormLabel>
              <NumberInput
                value={shifterKitchenRiskPph}
                onChange={(_: string, value: number) =>
                  setShifterKitchenRiskPph(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-kitchen-risk-pph"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <DropdownSelect
                placeholder="Select Value"
                label="OA Labor & Shift Planning Weekend Pack PPH Decrease Parameter"
                selectOptions={[
                  "0%",
                  "-5%",
                  "-10%",
                  "-15%",
                  "-20%",
                  "-25%",
                  "-30%",
                  "-35%",
                  "-40%",
                  "-45%",
                  "-50%",
                ]}
                selection={shifterPackPphDecrease}
                key={shifterPackPphDecrease}
                onChange={setShifterPackPphDecrease}
                testId="shifter-pack-pph-decrease"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <DropdownSelect
                placeholder="Select Value"
                label="Weekly OA Labor Targets OPH Type Selection"
                selectOptions={["Target OPH", "Override OPH"]}
                selection={shifterTargetsOphType}
                key={shifterTargetsOphType}
                onChange={setShifterTargetsOphType}
                testId="shifter-targets-oph-type"
              />
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                OA Labor & Shift Planning Order Forecast
              </FormLabel>
              <NumberInput
                value={shifterOrderForecast}
                onChange={(_: string, value: number) =>
                  setShifterOrderForecast(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-order-forecast"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "none" : "block" }}
            >
              <FormLabel className={styles.componentLabel}>
                ICQA (Counting) TPH Parameter
              </FormLabel>
              <NumberInput
                value={shifterIcqaTph}
                onChange={(_: string, value: number) =>
                  setShifterIcqaTph(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-icqa-tph"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            {/* Shifter Mode Parameters */}
            <FormControl
              style={{ display: props.shifterMode ? "block" : "none" }}
            >
              <FormLabel className={styles.componentLabel}>
                Selected OA Hours Run Id: {props.selectedAdjustedOaRunId}
              </FormLabel>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "block" : "none" }}
            >
              <FormLabel className={styles.componentLabel}>
                Min Shift Length (hrs)
              </FormLabel>
              <NumberInput
                value={shifterMinShiftLength}
                onChange={(_: string, value: number) =>
                  setShifterMinShiftLength(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-min-shift-length"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "block" : "none" }}
            >
              <FormLabel className={styles.componentLabel}>
                Max Shift Length (hrs)
              </FormLabel>
              <NumberInput
                value={shifterMaxShiftLength}
                onChange={(_: string, value: number) =>
                  setShifterMaxShiftLength(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-max-shift-length"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              style={{ display: props.shifterMode ? "block" : "none" }}
            >
              <FormLabel className={styles.componentLabel}>
                Shift Increment (mins)
              </FormLabel>
              <NumberInput
                value={shifterShiftIncrement}
                onChange={(_: string, value: number) =>
                  setShifterShiftIncrement(isNaN(value) ? 0 : value)
                }
                data-test-id="shifter-shift-increment"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </Box>
        </Container>
      }
    />
  );
};
