/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Input } from "@chakra-ui/input";
import React from "react";
import { CellProps } from "react-table";

// Create an editable cell renderer
export function DailySummaryTableCell<T extends object>({
  value: initialValue,
  row,
  colIndex,
  updateCurrentData,
  dates,
  locationId,
  handleEnterDailyAdjustment,
  upsertSeasonalAdjustmentLoading,
  operationalWeekStartDate,
  ...props
}: CellProps<T>) {
  const [value, setValue] = React.useState(initialValue ? initialValue : "");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = parseFloat((event.target as HTMLInputElement).value);

    if (!upsertSeasonalAdjustmentLoading && currentValue !== initialValue) {
      void handleEnterDailyAdjustment({
        locationId,
        forecasterId: null,
        adjustmentType: "daily",
        operationalWeekStartDate,
        dateString: (dates as string[])[colIndex as number],
        value: currentValue || null,
      });
    }

    if (updateCurrentData) {
      updateCurrentData({ column: props?.cell?.column?.id, value, props });
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.persist();
    if (event.key === "Enter" && !upsertSeasonalAdjustmentLoading) {
      void handleEnterDailyAdjustment({
        locationId,
        forecasterId: null,
        adjustmentType: "daily",
        operationalWeekStartDate,
        dateString: (dates as string[])[colIndex as number],
        value: parseFloat((event.target as HTMLInputElement).value) || null,
      });
    }
  };

  React.useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  if (row.cells[0].value !== "Enter Target %") {
    return <div>{String(initialValue)}</div>;
  }

  return (
    <Input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      data-test-id="daily-summary-table-input"
    />
  );
}
