export const TEXT = {
  CANCEL: "Cancel",
  CHOOSE_FILE: "Choose file...",
  ERROR_POPUP_FILE_TYPE: "Only csv files are supported",
  DATE_FORMAT_GRAPHQL_ERROR_SUBSTRING:
    "invalid input syntax for type timestamp with time zone",
  DATE_FORMAT_HUMAN_READABLE_ERROR: "Invalid date format",
  HEADER_UPLOAD_ADJOVF: "Upload Adjusted OVF File",
  HEADER_UPLOAD_OA_LABOR_HOURS: "Upload OA Labor Hours File",
  HEADER_UPLOAD_EVENT_ADJ: "Upload Event Adjustment File",
  HEADER_UPLOAD_TARGETS: "Upload Odh Targets File",
  HEADER_UPLOAD_DRIVER_HOURS: "Upload Adjusted Driver Hours File",
  HEADER_UPLOAD_OA_MAPPING: "Upload OA Labor Mapping File",
  HEADER_UPLOAD_OA_ADJUSTMENTS: "Upload OA Labor Adjustments File",
  TARGET_FILE: "Upload file",
  UPLOAD: "Upload",
  UPLOAD_ERROR: "Upload failed due to error: ",
  UPLOADING: "Uploading...",
  UPLOAD_ADJOVF: "Upload Adjusted Ovf",
  UPLOAD_EVENT_ADJ: "Upload Event Adjustment",
  UPLOAD_TARGETS: "Upload targets",
  UPLOAD_OA_LABOR_HOURS: "Upload OA Labor Hours",
  UPLOAD_DRIVER_HOURS: "Upload Adjusted Driver Hours",
  UPLOAD_OA_MAPPING: "Upload OA Labor Mapping",
  UPLOAD_OA_ADJUSTMENTS: "Upload OA Labor Adjustments",
};
