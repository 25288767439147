import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormHelperText,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { addDays, format } from "date-fns";
import { useState } from "react";
import { CancelJobsButton } from "../../components/CancelJobsButton/CancelJobsButton";
import { CountrySelect } from "../../components/CountrySelect/CountrySelect";
import { UploadOaHours } from "../../components/UploadOaHours/UploadOaHours";
import { UploadOaMapping } from "../../components/UploadOaMapping/UploadOaMapping";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import {
  OaShift,
  useGetEibXlsxUrlLazyQuery,
  useGetLatestJobStatusQuery,
  useGetOaShiftsLazyQuery,
  useRunOaShifterMutation,
  useRunGoSkedMutation,
} from "../../generated/graphql";
import { exportCSVFile } from "../../helpers/csv/exportToCSV";
import { exportXlsxFile } from "../../helpers/excel/exportXlsxFile";
import { constants } from "../../helpers/general/constants";
import { getISODateOfNextOperationalMonday } from "../../helpers/general/date";
import {
  formatLongDateString,
  getDatetimeObjectFromISODate,
  getISODate,
} from "../../helpers/general/date";
import { downloadUrl } from "../../helpers/url/downloadUrl";
import { AdjustedOaHoursRunsSelect } from "./../../components/AdjustedOaHoursRunsSelect/AdjustedOaHoursRunsSelect";
import {
  ShifterInput,
  ShifterInputPopup,
} from "./ShifterInputPopup/ShifterInputPopup";
import { oaShifterJob } from "./jobs";
import styles from "./styles.module.css";

export const OaQA = () => {
  // ---------- Dropdowns ----------

  const [triggerOaHoursRunsSelectRefresh, setTriggerOaHoursRunsSelectRefresh] =
    useState<number>(0);

  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const [latestShifterRunStartDate, setLatestShifterRunStartDate] =
    useState<string>("");
  const [latestShifterRunEndDate, setLatestShifterRunEndDate] =
    useState<string>("");
  const [latestShifterRunStatus, setLatestShifterRunStatus] =
    useState<string>("");
  const [selectedAdjustedOaRunId, setSelectedAdjustedOaRunId] =
    useState<number>(-1);
  const [shifterMode, setShifterMode] = useState<boolean>(false);
  const [isShifterRunning, setIsShifterRunning] = useState<boolean>(false);
  const {
    isOpen: isRunShifterInputPopupOpen,
    onOpen: onRunShifterInputPopupOpen,
    onClose: onRunShifterInputPopupClose,
  } = useDisclosure();

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
  }

  function handleCountrySelect(country: string) {
    setSelectedCountry(country);
  }

  function onClickRunGoSked() {
    setShifterMode(false);
    onRunShifterInputPopupOpen();
  }

  function onClickRunShifter() {
    setShifterMode(true);
    onRunShifterInputPopupOpen();
  }

  const [runOaShifter] = useRunOaShifterMutation(); // currently oaShifter contains oa hours and shifts logic
  const [runGoSked] = useRunGoSkedMutation(); // currently oaShifter contains oa hours and shifts logic

  async function handleRunShifts(input: ShifterInput) {
    if (shifterMode) {
      const { errors } = await runOaShifter({
        variables: {
          oaShifterInput: {
            operationalWeekStartDate: selectedWeek,
            minShiftLength: input.shifterMinShiftLength,
            maxShiftLength: input.shifterMaxShiftLength,
            shiftIncrement: input.shifterShiftIncrement,
            adjustedOaHoursRunId: selectedAdjustedOaRunId,
          },
        },
        refetchQueries: ["GetLatestJobStatus", "GetRuns"],
      });
      if (errors?.length) return;
    } else {
      const { errors } = await runGoSked({
        variables: {
          goSkedInput: {
            operationalWeekStartDate: selectedWeek,
            clusters: input.shifterClusters,
            countryCode: selectedCountry,
            stateCodes: input.shifterStateCodes,
            metroRegions: input.shifterMetroRegions,
            locationsToInclude: input.shifterLocationsToInclude,
            locationsToExclude: input.shifterLocationsToExclude,
            roundingAdjustment: input.shifterRoundingAdjustment,
            ophAdjustment: input.shifterOphAdjustment,
            firstRouterOrderThreshold: input.shifterFirstRouterOrderThreshold,
            secondRouterOrderThreshold: input.shifterSecondRouterOrderThreshold,
            inboundTph: input.shifterInboundTph,
            ovfAssumption: input.shifterOvfAssumption,
            packRiskPph: input.shifterPackRiskPph,
            kitchenOvfAssumption: input.shifterKitchenOvfAssumption,
            kitchenRiskPph: input.shifterKitchenRiskPph,
            packPphDecrease: input.shifterPackPphDecrease,
            targetOphType: input.shifterTargetsOphType,
            orderForecast: input.shifterOrderForecast,
            icqaTph: input.shifterIcqaTph,
          },
        },
        refetchQueries: ["GetLatestJobStatus", "GetRuns"],
      });
      if (errors?.length) return;
    }

    onRunShifterInputPopupClose();
    setIsShifterRunning(true);
  }

  function handleOaHoursRunsSelect(runId: number | undefined) {
    if (!runId) {
      runId = -1;
    }
    setSelectedAdjustedOaRunId(runId);
  }

  const [getEib, { loading: eibHoursDataIsLoading }] =
    useGetEibXlsxUrlLazyQuery({
      onCompleted: (data) => {
        downloadUrl(data.eibXlsxUrl);
      },
      fetchPolicy: "no-cache",
    });

  const { loading: latestShifterJobStatusIsLoading } =
    useGetLatestJobStatusQuery({
      variables: {
        jobId: oaShifterJob.id,
        environment: "current",
      },
      pollInterval: constants.time.MILLISECONDS_IN_A_MINUTE,
      onCompleted: (data) => {
        const previousStatus = latestShifterRunStatus;
        const currentStatus = data?.getLatestJobStatus?.status ?? "";
        if (previousStatus === "RUNNING" && currentStatus !== "RUNNING") {
          setIsShifterRunning(false);
        } else if (currentStatus === "RUNNING") {
          setIsShifterRunning(true);
        }
        setLatestShifterRunStartDate(data?.getLatestJobStatus?.startDate ?? "");
        setLatestShifterRunEndDate(data?.getLatestJobStatus?.endDate ?? "");
        setLatestShifterRunStatus(currentStatus);
      },
    });

  const shiftsCsvHeaders = [
    "location_name",
    "ops_date",
    "weekday",
    "start_time",
    "end_time",
    "length",
    "shift_leader",
  ];
  const [getOaShifts, { loading: getOaShiftsLoading }] =
    useGetOaShiftsLazyQuery({
      onCompleted: (data) => {
        const oaShiftsRows = data.oaShifts.map((shifts) => {
          return {
            location_name: shifts.locationName,
            ops_date: shifts.opsDate,
            weekday: shifts.weekday,
            start_time: shifts.startTime,
            end_time: shifts.endTime,
            length: shifts.length,
            shift_leader: shifts.shiftLeader,
          };
        });
        exportCSVFile(
          oaShiftsRows,
          shiftsCsvHeaders,
          `${selectedAdjustedOaRunId} - OA Shifts For Week ${selectedWeek} (${new Date().toISOString()})`
        );
      },
    });

  function mapShiftsToHumanityFormat(shifts: OaShift[]) {
    return shifts.map((shift, index) => {
      const startDate = shift.opsDate;
      const endDate = getISODate(
        addDays(
          getDatetimeObjectFromISODate(startDate),
          shift.startTime < shift.endTime ? 0 : 1
        )
      );
      const notes = (index + 1).toString();
      const position = shift.shiftLeader
        ? shift.shiftLeader
        : "MFC_Operations_Associate";

      const startTime = format(
        new Date(`${startDate}T${shift.startTime}`),
        "hh:mm aa"
      );
      const startTimeAdjusted =
        startTime === "12:00 AM" ? "12:01 AM" : startTime;

      const endTime = format(
        new Date(`${endDate}T${shift.endTime}`),
        "hh:mm aa"
      );
      const endTimeAdjusted = endTime === "12:00 AM" ? "12:01 AM" : endTime;

      return {
        eid: "",
        name: "",
        location: shift.locationName,
        position: position,
        "start date": startDate,
        "end date": endDate,
        "start time": startTimeAdjusted,
        "finish time": endTimeAdjusted,
        notes: notes,
        title: "",
        open: "",
        "remote site": "",
      };
    });
  }

  const [
    getOaShiftsHumanityFormat,
    { loading: getOaShiftsHumanityFormatLoading },
  ] = useGetOaShiftsLazyQuery({
    onCompleted: (data) => {
      const oaShiftsRows = mapShiftsToHumanityFormat(data.oaShifts);
      exportXlsxFile(
        oaShiftsRows,
        `${selectedAdjustedOaRunId} - OA Shifts Humanity Format For Week ${selectedWeek} (${new Date().toISOString()})`
      );
    },
  });

  return (
    <Box pl={8} pr={8}>
      <Flex justifyContent="flex-start">
        <Box className={styles.box} style={{ display: "none" }}>
          <CountrySelect
            onChange={handleCountrySelect}
            selectedCountry={selectedCountry}
            testId="oaqa-country-select"
          />
        </Box>
        <Box className={styles.box}>
          <WeekSelect
            onChange={handleWeekSelect}
            selectedWeek={selectedWeek}
            showCurrentWeek
            testId="oaqa-week-select"
          />
        </Box>
      </Flex>
      <Flex justifyContent="flex-start">
        <ShifterInputPopup
          isOpen={isRunShifterInputPopupOpen}
          countryCode={selectedCountry}
          selectedAdjustedOaRunId={selectedAdjustedOaRunId}
          onClose={onRunShifterInputPopupClose}
          onContinue={handleRunShifts}
          shifterMode={shifterMode}
        />
        <FormControl>
          <table>
            <tbody>
              <tr>
                <td valign="top">
                  <Box className={styles.box}>
                    <Flex justifyContent="flex-start">
                      <label className={styles.headerText}>
                        OA Labor Hours
                      </label>
                    </Flex>

                    <ButtonGroup variant="outline" spacing="6">
                      <Button
                        isDisabled={
                          !selectedWeek ||
                          !selectedCountry ||
                          selectedCountry === "all" ||
                          isShifterRunning
                        }
                        isLoading={isShifterRunning && !shifterMode}
                        loadingText="Running..."
                        colorScheme="blue"
                        width="350px"
                        onClick={onClickRunGoSked}
                        data-test-id="run-gosked"
                      >
                        Run GoSked
                      </Button>
                      <span style={{ display: shifterMode ? "none" : "block" }}>
                        <CancelJobsButton job={oaShifterJob} />
                      </span>
                    </ButtonGroup>
                    <Flex justifyContent="flex-start">
                      <FormHelperText>
                        {latestShifterJobStatusIsLoading ? (
                          <Spinner size="xl" />
                        ) : (
                          <p data-test-id="shifter-status">
                            Last run started on:{" "}
                            {formatLongDateString(latestShifterRunStartDate)},
                            <br />
                            ended on{" "}
                            {formatLongDateString(latestShifterRunEndDate)},
                            <br />
                            with status {latestShifterRunStatus}
                          </p>
                        )}
                      </FormHelperText>
                    </Flex>
                    <Flex justifyContent="flex-start" paddingTop="10px">
                      <UploadOaHours
                        selectedWeek={selectedWeek}
                        onSuccess={() => {
                          setTriggerOaHoursRunsSelectRefresh(
                            triggerOaHoursRunsSelectRefresh + 1
                          );
                        }}
                        testId="oa-upload-oa-hours"
                      />
                    </Flex>
                    <Flex>
                      <ButtonGroup spacing="6" paddingTop="10px">
                        <UploadOaMapping
                          allowDatesOutsideSelectedWeekCheckbox
                          selectedWeek={selectedWeek}
                          testId="oaqa-upload-adjusted-driver-hours"
                        />
                      </ButtonGroup>
                    </Flex>
                  </Box>
                </td>
                <td>
                  <div className={styles.verticalBar}></div>
                </td>
                <td valign="top">
                  <Box className={styles.box}>
                    <Flex justifyContent="flex-start">
                      <label className={styles.headerText}>Shifts|EIB</label>
                    </Flex>
                    <Flex>
                      <AdjustedOaHoursRunsSelect
                        onChange={handleOaHoursRunsSelect}
                        selectedWeek={selectedWeek}
                        selectedAdjustedOaRunId={selectedAdjustedOaRunId}
                        triggerRefresh={triggerOaHoursRunsSelectRefresh}
                        testId="oaqa-adjusted-oa-hours-runs-select"
                      />
                    </Flex>
                    <Flex>
                      <ButtonGroup
                        variant="outline"
                        spacing="6"
                        paddingTop="10px"
                      >
                        <Button
                          isDisabled={
                            !selectedWeek ||
                            !selectedCountry ||
                            selectedCountry === "all" ||
                            isShifterRunning ||
                            !selectedAdjustedOaRunId ||
                            selectedAdjustedOaRunId < 0
                          }
                          isLoading={isShifterRunning && shifterMode}
                          loadingText="Running..."
                          colorScheme="blue"
                          width="350px"
                          onClick={onClickRunShifter}
                          data-test-id="run-shifts"
                        >
                          Run Shifts|EIB
                        </Button>
                        <span
                          style={{ display: shifterMode ? "block" : "none" }}
                        >
                          <CancelJobsButton job={oaShifterJob} />
                        </span>
                      </ButtonGroup>
                    </Flex>
                    <Flex justifyContent="flex-start">
                      <FormHelperText>
                        {latestShifterJobStatusIsLoading ? (
                          <Spinner size="xl" />
                        ) : (
                          <p data-test-id="shifter-status">
                            Last run started on:{" "}
                            {formatLongDateString(latestShifterRunStartDate)},
                            <br />
                            ended on{" "}
                            {formatLongDateString(latestShifterRunEndDate)},
                            <br />
                            with status {latestShifterRunStatus}
                          </p>
                        )}
                      </FormHelperText>
                    </Flex>
                    <Flex>
                      <ButtonGroup spacing="6" paddingTop="10px">
                        <Button
                          isDisabled={
                            isShifterRunning ||
                            !selectedWeek ||
                            !selectedAdjustedOaRunId ||
                            selectedAdjustedOaRunId < 0
                          }
                          isLoading={getOaShiftsLoading}
                          loadingText="Downloading"
                          width="350px"
                          onClick={() => {
                            if (selectedAdjustedOaRunId < 0) return;
                            void getOaShifts({
                              variables: {
                                operationalWeekStartDate: selectedWeek,
                                adjustedOaHoursRunId: selectedAdjustedOaRunId,
                              },
                            });
                          }}
                          data-test-id="download-shifts"
                        >
                          Download Shifts
                        </Button>
                      </ButtonGroup>
                    </Flex>
                    <Flex>
                      <ButtonGroup spacing="6" paddingTop="10px">
                        <Button
                          isDisabled={
                            isShifterRunning ||
                            !selectedWeek ||
                            !selectedAdjustedOaRunId ||
                            selectedAdjustedOaRunId < 0
                          }
                          isLoading={getOaShiftsHumanityFormatLoading}
                          loadingText="Downloading"
                          width="350px"
                          onClick={() => {
                            if (selectedAdjustedOaRunId < 0) return;
                            void getOaShiftsHumanityFormat({
                              variables: {
                                operationalWeekStartDate: selectedWeek,
                                adjustedOaHoursRunId: selectedAdjustedOaRunId,
                              },
                            });
                          }}
                          data-test-id="download-shifts-humanity-format"
                        >
                          Download Shifts In Humanity Format
                        </Button>
                      </ButtonGroup>
                    </Flex>
                    <Flex>
                      <ButtonGroup spacing="6" paddingTop="10px">
                        <Button
                          isDisabled={
                            !selectedWeek ||
                            isShifterRunning ||
                            eibHoursDataIsLoading ||
                            !selectedAdjustedOaRunId ||
                            selectedAdjustedOaRunId < 0
                          }
                          isLoading={eibHoursDataIsLoading}
                          loadingText="Loading..."
                          width="350px"
                          onClick={() => {
                            if (selectedWeek && selectedAdjustedOaRunId > 0) {
                              void getEib({
                                variables: {
                                  operationalWeekStartDate: selectedWeek,
                                  runId: selectedAdjustedOaRunId,
                                },
                              });
                            }
                          }}
                          colorScheme="blue"
                          data-test-id="oaqa-download-eib"
                        >
                          Download EIB
                        </Button>
                      </ButtonGroup>
                    </Flex>
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
        </FormControl>
      </Flex>
    </Box>
  );
};
