import { CloseButton, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import {
  useCancelAllJobRunsMutation,
  useGetRunsQuery,
} from "../../generated/graphql";
import { constants } from "../../helpers/general/constants";
import { Job } from "../../types/Job";
import { GPPopup } from "../GPPopup/GPPopup";

export const CancelJobsButton = (props: { job: Job; isHidden?: boolean }) => {
  const [popupHeader, setPopupHeader] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string | React.ReactElement>(
    ""
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: runningJobsData } = useGetRunsQuery({
    variables: {
      runsListParams: {
        job_id: props.job.id,
        active_only: true,
      },
      environment: props.job.environment,
    },
    pollInterval: 10 * constants.time.MILLISECONDS_IN_A_SECOND,
    onCompleted: (data) => {
      setRunningJobs(data?.getRunsList?.runs ?? []);
    },
  });

  const [runningJobs, setRunningJobs] = useState(
    runningJobsData?.getRunsList?.runs ?? []
  );

  const [cancelJobRuns, { loading: cancelJobRunsLoading }] =
    useCancelAllJobRunsMutation({
      context: {
        skipDefaultErrorHandling: true,
      },
    });

  async function handleCancelJob(job: Job) {
    const response = await cancelJobRuns({
      variables: {
        jobId: job.id,
        environment: job.environment,
      },
      refetchQueries: ["GetLatestJobStatus", "getRuns"],
    });
    setPopupHeader(
      response?.data?.cancelAllJobRuns?.success ? "Success" : "Error"
    );
    setPopupMessage(
      `${job.name}\n${response?.data?.cancelAllJobRuns?.message || ""}`
    );
    onOpen();
  }

  return (
    <>
      <GPPopup
        isOpen={isOpen}
        body={
          typeof popupMessage === "string" ? (
            <span>{popupMessage}</span>
          ) : (
            popupMessage
          )
        }
        header={popupHeader}
        onClose={onClose}
      />
      <CloseButton
        key={`cancel-${props.job.testId as string}`}
        size="lg"
        backgroundColor="red"
        color="white"
        hidden={runningJobs.length === 0}
        isDisabled={cancelJobRunsLoading}
        onClick={() => {
          void handleCancelJob(props.job);
        }}
        data-test-id={`cancel-${props.job.testId as string}`}
      />
    </>
  );
};
