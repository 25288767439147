import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { NavBar } from "../components/NavBar/NavBar";
import CorsErrorModal from "./CorsErrorModal";
import Loading from "./Loading";
import { RequiredAuth } from "./SecureRoute";
import { oktaAuth } from "./oktaAuth";
import { CURRENT_ROUTES, GPRoute, Login } from "./index";

export const AppRoutes = React.memo(() => {
  const navigate = useNavigate();

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    navigate("/login");
  };

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <NavBar {...{ setCorsErrorModalOpen }} />
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <Routes>
        <Route
          path="/login"
          element={<Login {...{ setCorsErrorModalOpen }} />}
        />
        <Route path="/" element={<Login {...{ setCorsErrorModalOpen }} />} />
        <Route
          path="/login/callback"
          element={<LoginCallback loadingElement={<Loading />} />}
        />
        {CURRENT_ROUTES.map((currentRoute: GPRoute, index: number) => {
          return currentRoute.insecure ? (
            <Route
              key={index}
              path={currentRoute.path}
              element={currentRoute.component}
            />
          ) : (
            <Route
              key={index}
              path={currentRoute.path}
              element={<RequiredAuth />}
            >
              <Route path="" element={currentRoute.component} />
            </Route>
          );
        })}
      </Routes>
    </Security>
  );
});

AppRoutes.displayName = "Routes";
