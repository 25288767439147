import {
  ChakraProvider,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { Column, useSortBy, useTable } from "react-table";

type Row = Record<string, unknown>;

interface GPSimpleSortableTableProps {
  rows: Row[];
  columns: Array<Column<Row>>;
  id?: string;
  testId?: string;
}

export const GPSimpleSortableTable: React.FC<GPSimpleSortableTableProps> = ({
  rows,
  columns,
  id,
  testId,
}) => {
  const {
    headerGroups,
    rows: tableRows,
    getTableBodyProps,
    prepareRow,
  } = useTable(
    {
      columns: columns,
      data: rows,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <ChakraProvider>
      <Table size="md" variant="striped" id={id} data-test-id={testId}>
        <Thead>
          {headerGroups.map((headerGroup, headerGroupsIndex) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroupsIndex}>
              {headerGroup.headers.map((column, headerGroupsPropsIndex) => (
                <Th
                  pl="0"
                  userSelect="none"
                  {...column.getHeaderProps(
                    Object.assign(column.getSortByToggleProps(), {
                      style: { minWidth: column.minWidth ?? "auto" },
                    })
                  )}
                  key={headerGroupsPropsIndex}
                >
                  <Flex alignItems="center">
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsChevronCompactDown />
                      ) : (
                        <BsChevronCompactUp />
                      )
                    ) : (
                      ""
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {tableRows.map((row, tableRowIndex) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={tableRowIndex}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <Td
                      pl="0"
                      {...cell.getCellProps()}
                      key={cellIndex}
                      width={cell.column.width}
                      data-test-column={cell.column.testId}
                    >
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </ChakraProvider>
  );
};
