import { get2DArray } from "../general/array";
import { constants } from "../general/constants";
import { getTotalRowsAfterOverrides } from "./getTotalRowsAfterOverrides";

export type GetTotalRowsAfterOverridesHandler = (
  hourlyDataOverrides: Array<Array<number | null>>
) => number[];

export function getHourlyDataForWeekAfterOverrides(
  hourlyDataForWeek: Array<Array<number | null>>,
  hourlyDataOverrides: Array<Array<number | null>> | null | undefined,
  getTotalRowsAfterOverridesHandler?: GetTotalRowsAfterOverridesHandler
): Array<Array<number | null>> {
  if (!hourlyDataOverrides) {
    return hourlyDataForWeek;
  }
  const hourlyDataForWeekAfterOverrides = get2DArray<number | null>(
    hourlyDataOverrides.length,
    hourlyDataOverrides[0].length,
    0
  );
  const hasTotalRow: boolean =
    hourlyDataForWeek.length > constants.time.DAYS_IN_A_WEEK;
  hourlyDataForWeek.forEach((hourlyDataForDay, row) => {
    hourlyDataForDay.forEach((value, col) => {
      if (row < hourlyDataOverrides.length) {
        hourlyDataForWeekAfterOverrides[row][col] =
          hourlyDataOverrides[row][col] ?? value;
      }
    });
  });

  if (!hasTotalRow) {
    return hourlyDataForWeekAfterOverrides;
  }

  const totalRow = getTotalRowsAfterOverridesHandler
    ? getTotalRowsAfterOverridesHandler(hourlyDataForWeekAfterOverrides)
    : getTotalRowsAfterOverrides(hourlyDataForWeekAfterOverrides);
  return hourlyDataForWeekAfterOverrides.concat(new Array(totalRow));
}
