/* eslint-disable react/jsx-key */
import {
  Center,
  ChakraProvider,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { Column, useSortBy, useTable } from "react-table";
import { Maybe } from "../../../generated/graphql";
import styles from "../styles.module.css";
import { WeeklySummaryTableEditableCell } from "./WeeklySummaryTableEditableCell/WeeklySummaryTableEditableCell";

export interface WeeklySummaryTableRow {
  id: number;
  title: string;
  dopplerForecast: number;
  threeWeekOrderHistory: number;
  weeklyOverride: number | null;
  diff: string;
  totalWeeklyAdjustment: number;
  totalDailyAdjustment: number;
  totalHourlyAdjustment: number;
  totalSeasonalAdjustment: number;
  referralLayer: number;
  promosLayer: number;
  partnerLayer: number;
  otherLayer: number;
  ovfPure: number;
  planningLayer: number;
  finalOvf: number;
}

export interface SeasonalityAdjustment {
  dates?: string[];
  weeklyLayer: Array<Array<number | null>>;
  weeklyOverride: number | null;
  dailyLayer: Array<Array<number | null>>;
  hourlyLayer: Array<Array<number | null>>;
}

export interface EventAdjustmentTotal {
  tag: string;
  total: number;
}

export interface WeeklySummaryTableProps {
  tableData: WeeklySummaryTableRow[];
  updatedRow?: WeeklySummaryTableRow;
  operationalWeekStartDate: string;
  handleEnterWeeklyAdjustment: (upsertWeeklyAdjustmentVars: {
    locationId: number;
    forecasterId?: Maybe<number> | undefined;
    adjustmentType: string;
    operationalWeekStartDate: string;
    dateString: string;
    value?: Maybe<number> | undefined;
  }) => Promise<void>;
  upsertSeasonalAdjustmentLoading: boolean;
  loading: boolean;
}

const columns: Array<Column<WeeklySummaryTableRow>> = [
  {
    Header: "Location Id",
    width: 102,
    accessor: "id",
    testId: "location-id",
  },
  {
    Header: "Location Name",
    width: 200,
    accessor: "title",
  },
  {
    Header: "Doppler",
    width: 95,
    accessor: "dopplerForecast",
    testId: "doppler-forecast",
  },
  {
    Header: "3-Week Avg",
    width: 76,
    accessor: "threeWeekOrderHistory",
  },
  {
    Header: "Diff",
    width: 76,
    accessor: "diff",
  },
  {
    Header: "Enter % Adj",
    width: 120,
    accessor: (row) => {
      return row.weeklyOverride ?? "-";
    },
    Cell: WeeklySummaryTableEditableCell,
    id: "EditableCell",
    testId: "adjustment-cell",
  },
  {
    Header: "Total Weekly Orders Adj",
    width: 87,
    accessor: "totalWeeklyAdjustment",
    testId: "total-weekly-adjustment",
  },
  {
    Header: "Total Daily Orders Adj",
    width: 87,
    accessor: "totalDailyAdjustment",
    testId: "total-daily-adjustment",
  },
  {
    Header: "Total Hourly Orders Adj",
    width: 87,
    accessor: "totalHourlyAdjustment",
    testId: "total-hourly-adjustment",
  },
  {
    Header: "Total Seasonally Adj Fcst",
    width: 120,
    accessor: "totalSeasonalAdjustment",
    testId: "total-seasonally-adjustment",
  },
  {
    Header: "Referral Layer",
    width: 100,
    accessor: "referralLayer",
  },
  {
    Header: "Promos Layer",
    width: 90,
    accessor: "promosLayer",
  },
  {
    Header: "Partners Layer",
    width: 103,
    accessor: "partnerLayer",
  },
  {
    Header: "Other Layer",
    width: 83,
    accessor: "otherLayer",
  },
  {
    Header: "OVF Pure",
    width: 76,
    accessor: "ovfPure",
  },
  {
    Header: "Planning Layer",
    width: 102,
    accessor: "planningLayer",
  },
  {
    Header: "FINAL OVF",
    width: 76,
    accessor: "finalOvf",
  },
];

export const WeeklySummaryTable: React.FC<WeeklySummaryTableProps> = ({
  tableData,
  updatedRow,
  loading,
  operationalWeekStartDate,
  handleEnterWeeklyAdjustment,
  upsertSeasonalAdjustmentLoading,
}) => {
  if (updatedRow && !upsertSeasonalAdjustmentLoading) {
    const rowIndex = tableData.findIndex((row) => row.id === updatedRow.id);
    if (rowIndex >= 0) {
      tableData[rowIndex] = updatedRow;
    }
  }

  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable(
    {
      columns: columns,
      data: tableData,
      autoResetSortBy: false,
      getRowId: (row, _relativeIndex, _parent) => {
        return row.id.toString();
      },
    },
    useSortBy
  );

  return (
    <Container>
      <ChakraProvider>
        <Table size="sm" variant="striped" data-test-id="weekly-summary-table">
          <Thead className={styles.tableHeader}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    userSelect="none"
                    {...column.getHeaderProps(
                      Object.assign(column.getSortByToggleProps(), {
                        style: { minWidth: column.width },
                      })
                    )}
                  >
                    <Flex alignItems="center">
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsChevronCompactDown />
                        ) : (
                          <BsChevronCompactUp />
                        )
                      ) : (
                        ""
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {loading ? (
            <Tbody>
              <Tr>
                <Td colSpan={10000}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody {...getTableBodyProps()} className={styles.tableBody}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} data-test-location-id={row.id}>
                    {row.cells.map((cell) => {
                      if (cell.column.id === "EditableCell") {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            width={cell.column.width}
                            data-test-column={cell.column.testId}
                          >
                            {cell.render("Cell", {
                              handleEnterWeeklyAdjustment,
                              locationId: tableData[cell.row.index].id ?? 0,
                              operationalWeekStartDate,
                              upsertSeasonalAdjustmentLoading,
                            })}
                          </Td>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          width={cell.column.width}
                          data-test-column={cell.column.testId}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </ChakraProvider>
    </Container>
  );
};
