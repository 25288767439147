import { Box, Container, Flex } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { DropdownSelect } from "../../components/DropdownSelect/DropdownSelect";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { WeekSelect } from "../../components/WeekSelect/WeekSelect";
import {
  useDeleteEventAdjustmentLayerMutation,
  useGetEventAdjustmentsForTableLazyQuery,
  useGetEventAdjustmentsForTableQuery,
  useGetLayerNamesQuery,
  useGetLocationIdsInLayerLazyQuery,
  useGetLocationIdsInTagLazyQuery,
} from "../../generated/graphql";
import { LAYER_TYPES } from "../../helpers/constants/LAYER_TYPES";
import {
  getISODateOfCurrentOperationalMonday,
  getISODateOfNextOperationalMonday,
} from "../../helpers/general/date";
import {
  EventAdjustmentTable,
  EventAdjustmentTableRow,
} from "./EventAdjustmentTable/EventAdjustmentTable";
import { UploadEventAdjustmentModal } from "./UploadEventAdjustmentModal/UploadEventAdjustmentModal";
import { exportEventAdjustmentsCsv } from "./helpers/exportEventAdjustmentsCsv";
import { getEventAdjustmentLayersDataRows } from "./helpers/getEventAdjustmentLayersDataRows";

export const EventAdjustmentUpload = () => {
  const [selectedWeek, setSelectedWeek] = useState<string>(
    getISODateOfNextOperationalMonday()
  );

  const [selectedUploadLayerType, setSelectedUploadLayerType] =
    useState<string>();

  const [selectedDownloadLayerType, setSelectedDownloadLayerType] =
    useState<string>();

  const [csvDownloadIsInProgress, setCsvDownloadIsInProgress] =
    useState<boolean>(false);

  const [selectedLayer, setSelectedLayer] = useState<string>();

  const [selectedLocation, setSelectedLocation] = useState<string>();

  const [
    getLocationIdsInLayer,
    { data: locationIdsInLayerResult, loading: getLocationIdsInLayerIsLoading },
  ] = useGetLocationIdsInLayerLazyQuery({
    variables: {
      operationalWeekStartDate: selectedWeek ?? "",
      layerName: selectedLayer === "All" ? "" : selectedLayer,
    },
  });

  const locationIdsInLayer =
    locationIdsInLayerResult?.eventAdjustments &&
    locationIdsInLayerResult?.eventAdjustments[0]
      ? locationIdsInLayerResult?.eventAdjustments[0].locationIds?.map(
          (locationId) => locationId?.toString() ?? ""
        ) ?? []
      : [];

  const [
    getLocationIdsInTag,
    { data: locationIdsInTagResult, loading: getLocationIdsInTagIsLoading },
  ] = useGetLocationIdsInTagLazyQuery({
    variables: {
      operationalWeekStartDate: selectedWeek ?? "",
      tag: selectedDownloadLayerType,
    },
  });

  const locationIdsInTag =
    locationIdsInTagResult?.eventAdjustments &&
    locationIdsInTagResult?.eventAdjustments[0]
      ? locationIdsInTagResult?.eventAdjustments[0].locationIdsInTag?.map(
          (locationId) => locationId?.toString() ?? ""
        ) ?? []
      : [];

  const [
    getEventAdjustmentsForTable,
    { data: eventAdjustmentsQueryResult, loading: tableDataIsLoading },
  ] = useGetEventAdjustmentsForTableLazyQuery({
    variables: {
      operationalWeekStartDate: selectedWeek ?? "",
      tag:
        selectedLayer === "All"
          ? selectedDownloadLayerType ?? undefined
          : undefined,
      layerName:
        selectedLayer === "All" ? undefined : selectedLayer ?? undefined,
      locationId: selectedLocation ? parseInt(selectedLocation, 10) : null,
    },
  });

  const tableData: EventAdjustmentTableRow[] = useMemo(
    () => getEventAdjustmentLayersDataRows(eventAdjustmentsQueryResult),
    [eventAdjustmentsQueryResult]
  );

  const getEventAdjustmentsForDownloadQuery =
    useGetEventAdjustmentsForTableQuery({
      skip: true,
    });

  const [deleteLayer, { loading: deleteLayerIsInProgress }] =
    useDeleteEventAdjustmentLayerMutation({
      variables: {
        operationalWeekStartDate: selectedWeek,
        name: selectedLayer ?? "",
      },
      refetchQueries: ["GetLayerNames"],
    });

  const { data: layerNamesData, loading: getLayerNamesIsLoading } =
    useGetLayerNamesQuery({
      skip: !selectedDownloadLayerType,
      variables: {
        operationalWeekStartDate: selectedWeek ?? "",
        tag: selectedDownloadLayerType ?? undefined,
      },
    });

  const layerNames = layerNamesData?.eventAdjustments
    ? layerNamesData.eventAdjustments.reduce<string[]>(
        (layerNameList, layer) => {
          if (layer?.name) {
            layerNameList.push(layer?.name);
          }
          return layerNameList;
        },
        []
      )
    : [];

  function handleWeekSelect(week: string) {
    setSelectedWeek(week);
  }

  function handleUploadLayerTypeSelect(layerType: string) {
    setSelectedUploadLayerType(layerType);
  }

  function handleDownloadLayerTypeSelect(layerType: string) {
    setSelectedLayer(undefined);
    setSelectedDownloadLayerType(layerType);
    setSelectedLocation(undefined);
  }

  function handleLayerSelect(layerName: string) {
    setSelectedLayer(layerName);
    setSelectedLocation(undefined);
    if (layerName === "All") {
      void getLocationIdsInTag();
    } else {
      void getLocationIdsInLayer();
    }
  }

  function handleLocationSelect(locationId: string) {
    setSelectedLocation(locationId);
    void getEventAdjustmentsForTable();
  }

  async function handleDeleteLayer() {
    await deleteLayer();

    setSelectedLocation(undefined);
    setSelectedLayer(undefined);
  }

  async function handleDownloadButtonClick() {
    setCsvDownloadIsInProgress(true);
    const eventAdjustmentsForDownloadResult =
      await getEventAdjustmentsForDownloadQuery.refetch({
        operationalWeekStartDate: selectedWeek ?? "",
        tag: selectedLayer === "All" ? selectedDownloadLayerType : undefined,
        layerName:
          selectedLayer === "All" ? undefined : selectedLayer ?? undefined,
      });

    exportEventAdjustmentsCsv(
      eventAdjustmentsForDownloadResult.data,
      selectedWeek,
      selectedDownloadLayerType,
      selectedLayer
    );
    setCsvDownloadIsInProgress(false);
  }

  function isCurrentWeekSelected() {
    return selectedWeek === getISODateOfCurrentOperationalMonday();
  }

  function shouldDisableDeleteLayerButton() {
    return (
      deleteLayerIsInProgress ||
      (isCurrentWeekSelected() &&
        selectedDownloadLayerType !== LAYER_TYPES.Reforecast)
    );
  }

  function shouldDisableUploadEventAdjustmentButton() {
    return (
      !selectedUploadLayerType ||
      (isCurrentWeekSelected() &&
        selectedUploadLayerType !== LAYER_TYPES.Reforecast)
    );
  }

  return (
    <section>
      <Container>
        {(getLayerNamesIsLoading ||
          getLocationIdsInTagIsLoading ||
          getLocationIdsInLayerIsLoading ||
          tableDataIsLoading ||
          csvDownloadIsInProgress ||
          deleteLayerIsInProgress) && <PageSpinner />}
        <Box margin="25px" maxWidth="200px">
          <WeekSelect
            onChange={handleWeekSelect}
            selectedWeek={selectedWeek}
            key={selectedWeek}
          />
        </Box>
        <Flex>
          <Container id="create-layers-menu">
            <Box margin="10px">Create Layers</Box>
            <Box margin="10px">
              <DropdownSelect
                id="create-layers-select-layer-type"
                selectOptions={Object.keys(LAYER_TYPES)}
                placeholder="Select layer type"
                label="Layer Type:"
                onChange={handleUploadLayerTypeSelect}
                selection={selectedUploadLayerType}
                key={selectedUploadLayerType}
                disabled={!selectedWeek}
                testId="create-layers-select-layer-type"
              />
            </Box>
            <Box margin="10px">
              <UploadEventAdjustmentModal
                onSuccess={() => {
                  setSelectedLocation(undefined);
                  setSelectedLayer(undefined);
                  setSelectedDownloadLayerType(undefined);
                }}
                operationalWeekStartDate={selectedWeek ?? ""}
                layerType={selectedUploadLayerType ?? "Other"}
                disabled={shouldDisableUploadEventAdjustmentButton()}
              />
            </Box>
          </Container>

          <Container id="view-or-delete-layers-menu">
            <Box margin="10px">View/Delete Layers</Box>
            <Box margin="10px">
              <DropdownSelect
                id="view-or-delete-layers-select-layer-type"
                selectOptions={Object.keys(LAYER_TYPES)}
                placeholder="Select layer type"
                label="Layer Type:"
                onChange={handleDownloadLayerTypeSelect}
                selection={selectedDownloadLayerType}
                key={selectedDownloadLayerType}
                disabled={!selectedWeek}
                testId="view-or-delete-layers-select-layer-type"
              />
            </Box>
            <Box margin="10px">
              <DropdownSelect
                id="view-or-delete-layers-select-layer-name"
                selectOptions={["All", ...layerNames]}
                placeholder="Select layer"
                label="Layer:"
                onChange={handleLayerSelect}
                selection={selectedLayer}
                key={selectedLayer}
                disabled={!selectedDownloadLayerType}
                testId="view-or-delete-layers-select-layer-name"
              />
            </Box>
            <Box margin="10px">
              <DropdownSelect
                id="view-or-delete-layers-select-location"
                selectOptions={
                  !selectedLayer
                    ? []
                    : selectedLayer === "All"
                    ? locationIdsInTag
                    : locationIdsInLayer
                }
                placeholder="Select location"
                label="Location:"
                onChange={handleLocationSelect}
                selection={selectedLocation}
                key={selectedLocation}
                disabled={!selectedLayer}
                testId="view-or-delete-layers-select-location"
              />
            </Box>
            <Box margin="10px">
              {selectedLayer && (
                <Button
                  isLoading={tableDataIsLoading}
                  loadingText="..."
                  onClick={() => {
                    void handleDownloadButtonClick();
                  }}
                  isDisabled={tableDataIsLoading || csvDownloadIsInProgress}
                >
                  {selectedLayer === "All"
                    ? "Download all layers in tag"
                    : "Download layer"}
                </Button>
              )}
              {selectedLayer && selectedLayer !== "All" && (
                <Button
                  onClick={() => {
                    void handleDeleteLayer();
                  }}
                  isDisabled={shouldDisableDeleteLayerButton()}
                >
                  Delete layer
                </Button>
              )}
            </Box>
          </Container>
        </Flex>
      </Container>
      <Box
        margin="10px"
        maxWidth="1000px"
        id="event-adjustments-table-container"
      >
        {selectedWeek &&
        (selectedDownloadLayerType || selectedLayer) &&
        selectedLocation ? (
          <EventAdjustmentTable
            tableData={tableData}
            loading={tableDataIsLoading}
          />
        ) : (
          <br></br>
        )}
      </Box>{" "}
    </section>
  );
};
